import img01 from './news_image/news001.jpg'
import img02 from './news_image/news002.jpg'
import img03 from './news_image/news003.jpg'
import img04 from './news_image/news004.jpg'
import img05 from './news_image/news005.png'
import img06 from './news_image/news006.jpg'
import img07 from './news_image/news007.jpg'

export const  News_img =[
    {
        url:img01,
        alt:img01,
        id:1
    }, 
    {
        url:img02,
        alt:img02,
        id:2
    }, 
    {
        url:img03,
        alt:img03,
        id:3
    }, 
    {
        url:img04,
        alt:img04,
        id:4
    }, 
    {
        url:img05,
        alt:img05,
        id:5
    }, 
    {
        url:img06,
        alt:img06,
        id:6
    }, 
    {
        url:img07,
        alt:img07,
        id:7
    }, 
]