import React , {useState, useEffect} from 'react'
import { CSVLink, CSVDownload } from "react-csv";
import FileCopy from '@mui/icons-material/FileCopy';
import Moment from 'moment';
import './exportCSV.css';


function ExportCSV({column, csvData, fileName }) {

    const [dataExport, setDataExport] = useState([])


    const [colHeader, setColHeader] = useState([])
    const [colvalues, setColValues] = useState([])

    useEffect(() => {
        getColumnData()
    }, [])

    useEffect(() => {
        // if (colHeader.length > 0) {
        //     return
        // }
        exportToCSV()
    }, [csvData])

    const getColumnData = () => {
        if (column && csvData) {
            setColHeader([])
            column.map((item, idx) => {
                if (colHeader.length < column.length ) {
                    colHeader.push(item.header)
                }
            })
            setColHeader(colHeader)
        }
    }

  const exportToCSV = () => {
    
    const csvDatas = [
        colHeader
    ];
    
    if (csvData && colHeader) {
        csvData.map((item, idx) => {
            let datas = []
            column.map((its, idxs) => {
                datas.push(item[its.selector])
            })
            csvDatas.push(datas)
        })
        setDataExport(csvDatas)
    }
  };


  return (
    <>
    <CSVLink
        data={dataExport}
        filename={fileName}
        target="_blank"
        className='exportexcel'
        >Export Excel
        </CSVLink>
    </>
  )
}

export default ExportCSV