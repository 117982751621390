import React, {useEffect, useState} from "react";
import Modal from './contact_modal'
import ScrollToTop from '../../components/Scroll/ScrollToTop'


function Contact() {
  
  const [openModal, setOpenModal] = useState[false];
  
  
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  return (
    <div className="contact">
    <ScrollToTop/>
      <div className="contact_header">
      {!openModal && <Modal />}
      </div>
      <div className="contact_body">

      </div>
      <div className="contact_bottom">

      </div>
    </div>
  );
  }
  export default Contact;