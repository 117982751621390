import './Organization.css'
import Organi_img from './diagram.jpg';
import React, {useEffect} from "react";

import OurFounder from './OurFounder.js';
import ScrollToTop from '../../components/Scroll/ScrollToTop'


function Organization_manage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    return (
      <div className="Organization">
      <ScrollToTop/>
        <div className="Organization_bottom">
          <OurFounder/>
        </div>
        <div className="Organization_body">
          <img src={Organi_img}/>
        </div>
      </div>
    );
  }
  export default Organization_manage;