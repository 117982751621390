import React, {useEffect, useState} from 'react'
import './mrp.css'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom';
import ScrollToTop from '../../../../components/Scroll/ScrollToTop'


import img_mrp from './img_mrp/OHO-MRP-MODULE.jpg'

export default function Mrp(){
    const [scrollX, setScrollX] = useState(0);
    const [scrollY, setScrollY] = useState(0);

    const [openModal, setOpenModal] = useState(false);
    const [openButton, setOpenButton] = useState(false);


    function handleModal(){
        setOpenModal(true)
        setOpenButton(true)
      }

      function handleModalClose(){
        setOpenModal(false)
        setOpenButton(false)
      }


    useEffect(() => {
        window.scrollTo(scrollX, scrollY)
      }, [])
      
    return(
        <div className='mrp'>
        <ScrollToTop/>
            <div className='mrp-header'>
                <ul className="vision--head box"></ul>
                <ul>MRP (Manufacturing Resource Planning)</ul>
            </div>
            <br/>
            <div className='mrp-body'>
                <p>ซอฟท์แวร์สำหรับการวางแผนการผลิต  OHO MRP (Manufacturing Resource Planning) เป็น Software บริหารจัดการโรงงานอุตสาหกรรมที่ประกอบไปด้วยความสามารถในการวางแผนการผลิตสินค้าล่วงหน้า, วางแผนการจัดซื้อล่วงหน้า(ทราบว่าผลิตเมื่อไรก็ทราบว่าของควรเข้าเมื่อไร) และต้นทุนแท้จริงของผลิตภัณฑ์ ซึ่งพัฒนาขึ้นโดยทีมงานที่มีประสบการณ์ด้านการวิเคราะห์ และพัฒนาระบบบริหารการผลิตในภาคอุตสาหกรรมที่หลากหลาย ผนวกกับทีมงานที่มีประสบการณ์ในการพัฒนา Database Application (โปรแกรมที่มีระบบการจัดเก็บฐานข้อมูลจำนวนมาก)</p>
                <br/>
                <p>บริษัทให้บริการในการพัฒนาระบบงานด้วยทีมงานผู้มีประสบการณ์ ทั้งทางด้านเทคโนโลยีสารสนเทศ การจัดการและผู้เชี่ยวชาญ เพื่อแบ่งเบาภาระในการบริหารงาน การจัดการโครงงานและการพัฒนาระบบงานทางด้านเทคโนโลยีสารสนเทศของทางบริษัทผู้ใช้บริการ รวมถึงการดูแลรักษาระบบงานให้สามารถตอบสนองความต้องการของบริษัทผู้ใช้บริการ เพื่อเพิ่มศักยภาพในการแข่งขันทางธุรกิจ</p>
                    <br/>
                <p>บริษัทให้บริการในการพัฒนาระบบงานด้วยทีมงานผู้มีประสบการณ์ ทั้งทางด้านเทคโนโลยีสารสนเทศ การจัดการและผู้เชี่ยวชาญ เพื่อแบ่งเบาภาระในการบริหารงาน การจัดการโครงงานและการพัฒนาระบบงานทางด้านเทคโนโลยีสารสนเทศของทางบริษัทผู้ใช้บริการ รวมถึงการดูแลรักษาระบบงานให้สามารถตอบสนองความต้องการของบริษัทผู้ใช้บริการ เพื่อเพิ่มศักยภาพในการแข่งขันทางธุรกิจ</p>
                <ul>
                    <li>ระบบงานสามารถส่งมอบตรงตามเวลาตามที่ทางบริษัทผู้ใช้บริการ ต้องการด้วยทีมงานคุณภาพของบริษัทที่พร้อมทุ่มเทให้บริการ <br/>เพื่อพัฒนาระบบงานให้สอดคล้องกับความต้องการในเวลาที่กำหนด และงบประมาณที่เหมาะสม</li>
                    <li>ระบบงานสามารถส่งมอบตรงตามเวลาตามที่ทางบริษัทผู้ใช้บริการ ต้องการด้วยทีมงานคุณภาพของบริษัทที่พร้อมทุ่มเทให้บริการ <br/>เพื่อพัฒนาระบบงานให้สอดคล้องกับความต้องการในเวลาที่กำหนด และงบประมาณที่เหมาะสม</li>
                </ul>
            </div>
            <br/>
            <br/>
            <br/>
            <div className='mrp-header'>
                <ul className="vision--head box"></ul>
                <ul>เหตุผลที่ควรเลือกใช้ OHO MRP SYSTEM</ul>
            </div>
            <br/>
            <div className='mrp-body'>
                <ul>
                    <li>พลิกโฉมการผลิตโซลูชันสมัยใหม่เพื่อแก้ปัญหาเดิม ๆ</li>
                    <li>ไม่ต้องมีนาฬิกาบันทึกเวลาอีกต่อไปวางแผนอย่างแม่นยำจากการประเมินการผลิตในแบบเรียลไทม์</li>
                    <li>ครบวงจร MRP คุณภาพ การบำรุงรักษา และการผสานการใช้งาน PLM เต็มรูปแบบ</li>
                    <li>การสื่อสารในแบบเรียลไทม์ แสดงแผ่นงานและการแจ้งเตือนด้านคุณภาพแก่ผู้ปฏิบัติงานในระหว่างที่ทำง</li>
                    <li>ระบบอัตโนมัติสำหรับพื้นที่การผลิต เก็บข้อมูลในแบบเรียลไทม์จากอุปกรณ์ของคุณโดยใช้ API</li>
                </ul>
            </div>
            <br/>
            <br/>
            <br/>
            <div className='mrp-header'>
                <ul className="vision--head box"></ul>
                <ul>จุดเด่นของ OHO MRP SYSTEM</ul>
            </div>
            <br/>
            <div className='mrp-body'>
                <ul>
                    <li>สามารถเพิ่มเครื่องจักร BOM และแผนการผลิตได้อย่างไม่จำกัด</li>
                    <li>รองรับสูตรวัตถุดิบจำนวนมากแต่ละขั้นตอน</li>
                    <li>การจัดสินค้าขายเป็นชุดได้อย่างอิสระ</li>
                    <li>มีหน่วยนับได้ไม่จำกัดที่ใช้ในการผลิต แยกจากการขายได้</li>
                    <li>ตั้งค่าและแจ้งเตือนเมื่อปริมาณแตะจุดต่ำสุดและมีระบบเปิดใบขอสั่งซื้ออัตโนมัติ</li>
                    <li>มีขั้นตอนผลิตได้ ทั้งแบบใช้สูตร BOM โอนไปเป็นต้นทุนWIP และแบบมีขั้นตอนแปรสภาพสินค้าไปเรื่อย ๆ จนจบขั้นตอนผลิต</li>
                    <li>ตรวจสอบได้ทันที่จากใบสั่งขาย ว่ามีสินค้าเพียงพอแค่ไหน และสามารถสั่งไปสร้างใบขอซื้อได้ทันที</li>
                    <li>มีระบบวางแผนวัตถุดิบในการผลิตได้ เพื่อสั่งกันจองสินค้า และสร้างเอกสารขอซื้อวัตถุดิบให้เพียงพอต่อการผลิต</li>
                    <li>มีขั้นตอนรับเข้าแล้วโยกย้ายสินค้าไปตามขั้นตอน QC และสามารถตรวจรับสินค้าเป็นกิโลผ่านเครื่องชั่งน้ำหนักอัตโนมัติ</li>
                    <li>ของเสียระหว่างการผลิต เอาไป re-cycle หรือไปผลิตใหม่ได้</li>
                    <li>ทุกระบบการผลิตเชื่อมต่อกับระบบ ERP แบบ Realtime</li>
                    <li>สามารถตรวจสอบสถานะการทำงานของเครื่องจักรได้</li>
                    <li>มีระบบควบคุม Lot สินค้า และตรวจสอบวันหมดอายุของสินค้าได้ทุกตัวที่อยู่ในสต็อก</li>
                    <li>ทุกกระบวนการผลิตรองรับระบบ QRCode เพื่อความแม่นยำของข้อมูล</li>
                    <li>มีรายงาน Dashbroad ที่สามารถมองเห็นภาพรวมของธุรกิจได้อย่างไม่จำกัด</li>
                </ul>
            </div>
            <br/>
            <br/>
            <br/>
            <div className='mrp-header'>
                <ul className="vision--head box"></ul>
                <ul>ประโยชน์ที่จะได้จากการใช้ OHO MRP SYSTEM</ul>
            </div>
            <br/>
            <div className='mrp-body'>
                <ul>
                    <li>สามารถวางแผนแบบ Advance Scheduling (Forward Scheduling + Backward Scheduling)</li>
                    <li>สามารถวางแผนการผลิตได้ทั้งแบบที่ละขั้นตอน (แยกส่วนการวางแผน) หรือวางแผนการผลิตทั้งสายการผลิตผลิตภัณฑ์ หรือผสมรูปแบบกันก็ได้</li>
                    <li>สามารถแทรกงานด่วน เปลี่ยนแปลงแผนการผลิตที่วางแผนไปแล้ว หรือแม้กระทั่งแผนที่ออก ใบสั่งผลิตไปแล้วก็ตาม โดยมีรูปแบบสำหรับการแทรก หรือเปลี่ยนแปลงงานที่หลากหลาย ทั้งนี้ขึ้นอยู่กับสิทธิการใช้งานโปรแกรมที่ถูกกำหนดขึ้น</li>
                    <li>ผู้บริหารสามารถทราบต้นทุนก่อนการผลิต และทราบต้นทุนที่แท้จริงหลังการผลิตแล้ว ทำให้ง่าย ต่อการตัดสินใจ การบริหารการผลิต และสะดวกในการช่วยจัดทำบัญชีต้นทุน</li>
                    <li>รองรับการวางแผนการผลิตแบบ Re-Process, Re-Work, Re-Pack และคำนวณต้นทุนสินค้าเฉพาะกรณีนั้นๆได้อย่างถูกต้อง</li>
                    <li>รองรับ QC Process</li>
                    <li>รองรับการวางแผนการผลิตของจุดสนใจ (Work Center) แม้การผลิตจะขึ้นอยู่กับกำลังคนก็ตาม (มีการสับเปลี่ยนกำลังคนตลอดเวลา)</li>
                    <li>ลดความซ้ำซ้อน เพิ่มความรวดเร็วในการทำงาน ทั้งงานจัดซื้อ งานคลังสินค้า งานวางแผนการผลิตงานส่งสินค้า และทำให้ง่ายต่อการสืบค้น หรือติดตามข้อมูล พร้อมทั้งนำข้อมูลไปประมวลผลต่อ</li>
                    <li>สามารถนำไปใช้ในการบริหารคลังสินค้า ให้มีปริมาณที่เหมาะสม และลดต้นทุนการจัดเก็บพร้อมทั้ง ทราบความเคลื่อนไหวต่างๆ ของวัตถุดิบ สินค้ากึ่งสำเร็จรูป สินค้าสำเร็จรูป ในคลังสินค้า</li>
                    <li>สามารถตรวจสอบต้นทุนสินค้าได้ทั้งแบบ FIFO, Average และ Lot Specific</li>
                    <li>เพิ่มความแม่นยําของข้อมูล และลดความผิดพลาดในการทำงานของพนักงาน</li>
                    <li>เป็นเครื่องมือช่วยสนับสนุนข้อมูลให้หน่วยวางแผนการผลิต, จัดซื้อ, คลังสินค้า ใช้เพื่อตอบสนอง นโยบายขององค์กร</li>
                    <li>สามารถกำหนดสิทธิการใช้งานได้อย่างอิสระทุกเมนู</li>
                    <li>สามารถสร้างแบบฟอร์มการทำงานได้เอง โดยดึงข้อมูลจากโครงสร้างของระบบไปใช้งานได้ทันที</li>
                    <li>สามารถ Export ข้อมูลเป็นไฟล์ MS Office (.doc, .docx, .xls, .xlsx, .ppt, .pptx, .vsd, .pub, .mpp, .mppx, mdb, accdb),.rtf, .txt, .pdf, .xml ได้</li>
                    <li>สามารถตรวจสอบการทำงานของแต่ละผู้ใช้งานว่าอะไรไปบ้าง วันไหนและเวลาใด</li>
                    <li>รองรับการทำงานแบบ Multi Company, Multi Branch, Multi Factory</li>
                    <li>สามารถกำหนดสูตรการผลิตได้( Bill Of Material) ในลักษณะหลายชั้น และซับซ้อน</li>
                    <li>สามารถบันทึกกระบวนการการทำงาน, รายการเครื่องจักร, รายการสายการผลิต (กลุ่มเครื่องจักร)</li>
                    <li>รองรับการทำงานได้ทั้งภาษาไทย และอังกฤษ</li>
                    <li>รายงานสรุป Master Plan Scheduling Report</li>
                    <li>รายงานสรุป Master Productivity Report</li>
                    <li>ทราบประสิทธิภาพของเครื่องจักรแต่ละเครื่อง เปรียบเทียบผลงานที่ทำได้</li>
                    <li>มีระบบบริหารสินค้าคงคลัง (เบิก-รับ-โอน-คืน)</li>
                    <li>โปรแกรมทำ Lot Traceability ได้</li>
                    <li>โปรแกรมทำให้ทราบต้นทุนสินค้าก่อนการผลิตได้</li>
                    <li>สามารถ Allocate Cost เพื่อดูต้นทุนที่แท้จริงของสินค้าได้</li>
                    <li>สามารถดูปริมาณของเสีย, สาเหตุการเสียได้ (Scrap, Waste & Defection)</li>
                </ul>
            </div>
            <br/>
            <br/>
            <br/>
            <div className='mrp-bottom'>
                <Link to='/products/mrp/buy'>
                <button className="btn--package">คลิ๊ก! สั่งซื้อผลิตภัณฑ์</button>
                </Link>
                {!openButton ? 
                <button variant="contained" className='btn--erp' onClick={()=>{handleModal()}}>
                    Click MRP Modul 2022
                </button> : 
                <button variant="contained" className='btn--erp'  onClick={()=>{handleModalClose()}}>
                    hide
                </button>
                }
                {openModal && 
                    <div className='mrp-module'>
                        <ul>
                        <img src={img_mrp} alt='MRP-MODULE'/>
                        </ul>
                    </div>
                }
            </div>
        </div>
    )
}