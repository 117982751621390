import React from "react";
import './footer.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import Qrcode from './qrcode.jpg';
import { Link } from "react-router-dom";

const Footer = () => {
    
    return (
        <div className="Footer">
            <div className="Footer-1">
                <div className="Footer-1--text">
                    <p>Copyright © 2021 <b>Tech Care Solution  Co., Ltd.</b> All right reserved.</p>
                </div>
                <div className="Footer-1--text">
                    <p>Designed by <b style={{color: "var(--color--blue)"}}>OHO Website Design</b></p>
                </div>
            </div>
            <div className="Footer-2">
                <Link to='/services'>
                <ul className="Footer-2-text">Products</ul>
                </Link>
                    
                <ul className="Footer-2-text"><Link to='/promotion'>Promotions</Link></ul>
                <ul className="Footer-2-text">FAQ</ul>

            </div>
            <div className="Footer-3">
                <ul className="Footer-3-text">
                    <ul style={{fontSize: '3rem'}}>Contact</ul>
                </ul>
                <ul className="Footer-3-text"><b>ที่อยู่:</b> 88/88 หมู่ 20 ต.บ้านเป็ด อ.เมืองขอนแก่น จ.ขอนแก่น 40000</ul>
                <ul className="Footer-3-text"><b>โทร:</b> 06-5464-5952</ul>
                <ul className="Footer-3-text"><b>อีเมล:</b> techcaresolution106@gmail.com</ul>

            </div>
            <div className="Footer-4">
                <div className="Footer-4--icon">
                    <a href="https://www.facebook.com/techcaresolutionofficial" target="_blank" rel="noopener noreferrer">
                        <FacebookIcon className="Footer-4--icon--item" fontSize="large" color="inherit"  />
                    </a>
                </div>
                <div className="Footer-4--icon">
                    <a href="https://instagram.com/oho.techcaresolution?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
                        <InstagramIcon className="Footer-4--icon--item" fontSize="large" color="inherit" />
                    </a>
                </div>
                <div className="Footer-4--icon">
                    <a href="https://mail.google.com/mail/u/0/#inbox?compose=DmwnWsdKPBksXpjgcwJBKnNqQmjWQmJCktKZNxGtlJjHFtDbhvtvnMlRMbRVsqMQcrhGmwjwkJLv" target="_blank" rel="noopener noreferrer">
                        <MailOutlineIcon className="Footer-4--icon--item" fontSize="large" color="inherit" />
                    </a>
                </div>
            </div>
            <div className="line">
            </div>
            <div className="Footer-5">
               <div className="Footer-5--QR">
                    <img src={Qrcode} className="qrcode" />
               </div>
            </div>
        </div>
    )
}

export default Footer;