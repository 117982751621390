import  Button  from '../../../components/Button/Button';
import React, {useEffect, useState} from 'react'

import {urlApi} from "../../../url_api/url_api";

import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper } from '@material-ui/core';
import SearchBar from "material-ui-search-bar";

import mss from '../../../components/message/messagebox'

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Backdrop from '@mui/material/Backdrop';




function createData(master_package_type_id, master_package_type_name, 
  master_package_type_status, master_package_hd_id, master_package_hd_code ,
  master_package_hd_name, master_program_id, master_program_code,
  master_program_prefix) {
  return {master_package_type_id, master_package_type_name, 
    master_package_type_status, master_package_hd_id, master_package_hd_code ,
    master_package_hd_name, master_program_id, master_program_code,
    master_program_prefix};
}

function AddPackageType({openPackageType, setOpenPackageType}) {

  const [programData, setProgramData] = useState([]);
  const [packageHdData, setPackageHdData] = useState([]);
  const [packageTypeData, setPackageTypeData] = useState([]);

  const [programId, setProgramId] = useState(0);
  const [packageId, setPackageId] = useState(0);
  const [typeId, setTypeId] = useState(0);

  const [typeName, setTypeName] = useState("");
  const [checked, setChecked] = useState(true);


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchVal, setSearchVal] = useState(null);
  const [nameButton, setNameButton] = useState("Save");
  const [saveStatus, setSaveStatus] = useState(false);




  //#region  useEffect
    useEffect(() => {
        getProgramData();
        getPackageHDData();
        getTypeData();
    }, [])

    useEffect(() => {
      getPackageHDData();
    }, [programId])

    useEffect(() => {
      if (saveStatus) {
        getTypeData();
        setSaveStatus(false);
      }
    }, [saveStatus])



  //#endregion

  //#region Function
  function clearData() {
    setProgramId(0);
    setPackageId(0);
    setTypeId(0);
    setTypeName("");
    setChecked(true);
    setNameButton("Save");
    // setSaveStatus(false);
  }
  
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeProgram = (event) => {
    setProgramId(event.target.value);
    console.log("PROGRAM ID : " + event.target.value);
  };

  const handleChangePackage = (event) => {
    setPackageId(event.target.value);
    console.log("PACKAGE ID : " + event.target.value);
  };

  const handleRowclick = async (text, pID, _programID) => {
    console.log("text : " +text);
    console.log("ROW : " +pID);
    if(text != 'แก้ไข')
    {
      return
    } 
    setNameButton("Update");
    try {
      var axios = require('axios');
      var data = JSON.stringify({
        "master_program_id": _programID,
        "master_package_hd_id": "0",
        "master_package_type_id": pID,
        "master_package_status": "0"
      });

      var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: urlApi + '/packtype/get_data_fn',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
     await axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data.length > 0) {
          setProgramId(response.data[0].master_program_id);
          setPackageId(response.data[0].master_package_hd_id);
          setTypeId(response.data[0].master_package_type_id);
          setTypeName(response.data[0].master_package_type_name);
          setChecked(response.data[0].master_package_type_status);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    } catch (error) {
      console.log(error);
      
    }
  }

  function SwitchStatus(params) {
    return(
      <div style={{display: 'flex'}}>
      <Typography style={{marginRight: "5%",fontFamily: "var(----Font-family)", fontSize: '1.3rem' , color: "red"}}>ปิด</Typography>
      <Switch
        checked={params}
        inputProps={{ 'aria-label': 'controlled' }}
        disabled
        style={{background: !params ? "red" : "green"}}
        />
      <Typography style={{marginLeft: "5%",fontFamily: "var(----Font-family)", fontSize: '1.3rem', color: "green"}}>เปิด</Typography>
      </div>
    )
  }
  //#endregion

  //#region Get Data
  async function getProgramData() {
    try {
      var axios = require('axios');
      var data = JSON.stringify({});

      var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: urlApi +'/masterProgram/all_status',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

     await axios(config)
      .then(function (response) {
        setProgramData(response.data);
      })
      .catch(function (error) {
        console.log(error);
        return
      });
      
    } catch (error) {
      console.log(error);
      return
    }
  }

  async function getPackageHDData() {
    var axios = require('axios');
    var data = JSON.stringify({
      "master_program_id": programId,
      "master_package_hd_id": "0"
    });

    var config = {
      method: 'post',
    maxBodyLength: Infinity,
      url: urlApi + '/packhd/getData',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };    

    await axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      setPackageHdData(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  async function getTypeData() {
    try {
      var axios = require('axios');
      var data = JSON.stringify({
        "master_program_id": "0",
        "master_package_hd_id": "0",
        "master_package_type_id": "0",
        "master_package_status": "0"
      });

      var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: urlApi + '/packtype/get_data_fn',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setPackageTypeData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    } catch (error) {
      console.log(error);
    }
  }

  //#endregion


  //#region Save Update Data

  async function updateProgrmaData() {
    console.log("typeId : " + typeId);
    if (typeId == 0) {
      try {
        var axios = require('axios');
        var data = JSON.stringify({
          "master_package_type_name": typeName,
          "master_package_type_status": !checked ? false : true,
          "master_package_hd_id": packageId
        });

        var config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: urlApi +'/packtype/insert',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };

        await axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          if (response.data == 1) {
            mss("success", "บันทึกสำเร็จ")
            setSaveStatus(true);
            clearData();
          }else{
            mss("error", "บันทึกไม่สำเร็จ")
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      } catch (error) {
        console.log(error);
      }
      
    }else{
      try {
        var axios2 = require('axios');
        var data2 = JSON.stringify({
          "master_package_type_name": typeName,
          "master_package_type_status": !checked ? false : true,
          "master_package_hd_id": packageId,
          "master_package_type_id": typeId
        });

        var config2 = {
          method: 'post',
          maxBodyLength: Infinity,
          url: urlApi +'/packtype/update',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data2
        };

        await axios2(config2)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          if (response.data == 1) {
            mss("success", "อัพเดทสำเร็จ")
            setSaveStatus(true);
            clearData();
          }else{
            mss("error", "อัพเดทไม่สำเร็จ")
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  function checkData(params) {
    if (programId == 0) {
      mss("warning","กรุณาเลือก โปรแกรม")
      return false
    }
    else return true
  }

  function handleSave() {
    if (checkData()) {
      updateProgrmaData()
    }else{
      return
    }
  }

  //#endregion
  const [open, setOpen] = React.useState(true);
  
  return (
    <>
      {openPackageType &&
        <Body>
        <Backdrop
          sx={{ color: '#8c9197', zIndex: 990 }}
          open={open}
          />
          <ContenedorModal>
          <TopModal>
              <h3>ตั้งค่าประเภทแพ็คเกจ</h3>
                <div onClick={() => {setOpenPackageType(false);}} style={{
                  display: 'flex',
                  position: 'absolute', 
                  right: '3%',
                  fontSize: '2rem',
                  cursor: 'pointer'
                }}>
                X</div>
            </TopModal>
            <Cardbody>
            <div style={{width: '100%', height: '5rem', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              <label>โปรแกรม : </label>
              <Select
                value={programId}
                key={programId}
                onChange={handleChangeProgram}
                displayEmpty
                style={{width: '80%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}} 
                inputProps={{ 'aria-label': 'Without label' }}
              >
              {programData.map(({master_program_id,master_program_code}) => {
                return (<MenuItem style={{fontFamily: "var(----Font-family)", fontSize: '1.5rem'}} key={master_program_id} value={master_program_id}>{master_program_code}</MenuItem>)
              })}
              </Select>
            </div>
            <div style={{width: '100%', height: '5rem', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              <label>แพ็คเกจ : </label>
              <Select
                value={packageId}
                key={packageId}
                onChange={handleChangePackage}
                displayEmpty
                style={{width: '80%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}} 
                inputProps={{ 'aria-label': 'Without label' }}
              >
              {packageHdData.map(({master_package_hd_id,master_package_hd_code}) => {
                return (<MenuItem style={{fontFamily: "var(----Font-family)", fontSize: '1.5rem'}} key={master_package_hd_id} value={master_package_hd_id}>{master_package_hd_code}</MenuItem>)
              })}
              </Select>
            </div>
            <div style={{width: '100%', height: '5rem',display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              <label>ชื่อประเภท : </label>
              <TextField 
                size="small" 
                id="outlined-basic" 
                label="" 
                variant="outlined" 
                style={{width: '80%'}} 
                value={typeName} 
                onChange={(e) => setTypeName(e.target.value) }
                />
            </div>
            {/* <div style={{width: '100%', height: '5rem',display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              <label>ตัวย่อ : </label>
              <TextField 
                size="small" 
                id="outlined-basic" 
                label="" 
                variant="outlined" 
                style={{width: '80%'}} 
                value={programPrefix} 
                onChange={(e) => setProgramPrefix(e.target.value)}
                autoFocus={programPrefixAutoFocus}
                />
            </div> */}
            <div style={{width: '100%', height: '5rem',display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              <label>สถานะ : </label>
              <Typography style={{fontFamily: "var(----Font-family)", fontSize: '1.5rem'}}>ปิด</Typography>
              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography style={{fontFamily: "var(----Font-family)", fontSize: "1.5rem"}}>เปิด</Typography>
            </div>
            <div style={{width: '100%', display: 'flex', position: 'absolute', bottom: 5, right: 5}}>
              <Button color="success" name="new" onClick={() => clearData()}/>
              <Button color="primary" name={nameButton} onClick={() => handleSave()}/>
              <Button color='warning' name="cancel" onClick={() => {setOpenPackageType(false);}} />
            </div>
            </Cardbody>
            <CardTabel>
            <TableContainer component={Paper}>
            <Table style={{width: '100%'}} className='' aria-label="simple table">
              <TableHead style={{backgroundColor: '#0087E7', width: '100%'}}>
                <TableRow style={{maxHeight: '2rem'}}>
                  <TableCell style={{ color: 'antiquewhite' , fontFamily: "var(----Font-family)", fontSize: '1.5rem'}}>โปรแกรม</TableCell>
                  <TableCell style={{ color: 'antiquewhite' , fontFamily: "var(----Font-family)", fontSize: '1.5rem'}}>แพ็คเกจ</TableCell>
                  <TableCell style={{ color: 'antiquewhite' , fontFamily: "var(----Font-family)", fontSize: '1.5rem'}}>ชื่อประเภท</TableCell>
                  <TableCell style={{ color: 'antiquewhite' , fontFamily: "var(----Font-family)", fontSize: '1.5rem'}}>สถานะ </TableCell>
                  <TableCell align="center" style={{ color: 'antiquewhite' , fontFamily: "var(----Font-family)", fontSize: '1.5rem'}}>จัดการ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {packageTypeData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                  <TableRow key={item.master_package_type_id} onClick={(e) => {handleRowclick(e.target.innerText, item.master_package_type_id, item.master_program_id)}}>
                    <TableCell style={{width: '20%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}} component="th" scope="row">{item.master_program_code}</TableCell>
                    <TableCell style={{width: '25%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}}>{item.master_package_hd_code}</TableCell>
                    <TableCell style={{width: '25%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}}>{item.master_package_type_name}</TableCell>
                    <TableCell style={{width: '20%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}}>{SwitchStatus(item.master_package_type_status)}</TableCell>
                    {/* <TableCell style={{width: '20%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}}>{SwitchStatus(packageData.master_program_status)}</TableCell> */}
                    <TableCell align="center" style={{width: '30%', color: 'antiquewhite'}}>
                      <Button style={{ height: "45px",fontWeight: "600", color: "white", fontSize: '1.3rem'}} color='secondary' name='แก้ไข'  size="medium" variant="contained" ></Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardTabel>
        </ContenedorModal>
      </Body>
      }
    </>
  )
}

export default AddPackageType



// const useStyles = makeStyles({
//   table: {
//     width: '100%',
//     fontSize: '1.5rem',
//     fontFamily: 'var(----Font-family)'
//   },
// });

const Body = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background-color: rgb(125, 125, 125);
`;

const TopModal = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.625rem;
    padding-bottom: 1.5rem;
    padding-top: 0.625rem;
    border-bottom: 1px solid #E8E8E8;
    h3 {
      font-weight: 700;
      font-size: 2rem;
    }
`;


const ContenedorModal = styled.div`
    width: 90vw;
    min-height: 50rem;

    background: #fff;
    position: absolute;
    top: 0;
    left:5%;
    
    margin-top: 2%;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    z-index: 998;

`;
const Cardbody = styled.div`
    width: 30rem;
    height: 44rem;
    display: flex;
    flex-direction: column;

    padding: 0.625rem;

    font-family: var(--font-family);
    font-size: 1.5rem;

    position: absolute;
    margin-top: 5%;

    ${'' /* background-color: rgb(167, 167, 167); */}
    label {
      margin-right: 5%;
      width: 8rem;
    }
`;

const CardTabel = styled.div`
    width: 75rem;
    height: 35rem;
    display: flex;

    padding: 0.625rem;
    

    font-family: var(--font-family);
    font-size: 1.5rem;


    position: absolute;
    margin-top: 5%;
    margin-left: 33rem;
`;