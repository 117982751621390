import React, {useEffect,useState} from 'react';
import { Link, useParams  } from 'react-router-dom';
import './pos.css';
import Button from '@mui/material/Button';
import ScrollToTop from '../../../../components/Scroll/ScrollToTop'


import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

//#region  Image

import pos_concept from '../../service_img/pos-concept.png';
import who_OHO_POS from '../../service_img/who-OHO-POS.png';
import POS_standard from '../../service_img/standard1.png';
import POS_standardbuffet from '../../service_img/standardbuffet.png';
import POS_pro from '../../service_img/pro.png';
import POS_express from '../../service_img/express.png';

//#endregion

export default function Pos() {

    const [slides, setSlides] = useState(4);
    const [sizeDisplay, setSizeDisplay] =  useState();


    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("resize", handleResize)
        setSizeDisplay(window.innerWidth);
      }, [])

      const handleResize = () => {
        // console.log("innerWidth : " + window.innerWidth);
        // console.log("width : " + window.screen.width);
        if (window.innerWidth > 960) {
            // setIsMobile(true)
            setSlides(4)
        } else {
            // setIsMobile(false)
            setSlides(1)
        }
    }

    useEffect(() => {
        handleResize();
      }, [sizeDisplay]
    )

    return (
        <div className='pos'>
        <ScrollToTop/>
            <div className='pos__header'>
                <ul className="vision--head box"></ul>
                <ul>POS (Point Of Sale)</ul>
            </div>
            <div className='pos__body'>
                <img src={pos_concept}/>
                <img src={who_OHO_POS}/>
            </div>
            <br/>
            <div className='pos__bottom'>
                <div className='pos__header'>
                    <ul className="vision--head box"></ul>
                    <ul>OHO POS PACKAGE</ul>
                </div>
                <div className='pos__bottom__body'>
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={slides}
                        centeredSlides={false}
                        // autoplay={{
                        //   delay: 6000,
                        //   disableOnInteraction: false,
                        // }}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiperPos"
                    >
                        <SwiperSlide>
                            <div className="pos--item">
                                <Link to='/products/pos/buy/1'>
                                    <img src={POS_express} />
                                </Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="pos--item">
                                <Link to='/products/pos/buy/2'>
                                    <img src={POS_standard} />
                                </Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="pos--item">
                                <Link to='/products/pos/buy/2'>
                                    <img src={POS_standardbuffet} />
                                </Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="pos--item">
                                <Link to='/products/pos/buy/3'>
                                    <img src={POS_pro} />
                                </Link>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className='pos__bottom__bottom'>
                    <ul className='pos__bottom__bottom text_package'>
                        {/* <Link to='/services/pos/buy2/1'>
                            <button variant="contained" className="btn--package" color='info' >
                                ซื้อแพ็กเกจ
                            </button>
                        </Link> */}
                        <Link to="/products/pos/packagecompare">
                            <button variant="contained" className="btn--package" color='info' >
                                คลิ๊ก! ดูรายละเอียดแพ็กเกจ</button>
                        </Link>
                    </ul>
                </div>
            </div>
        </div>
    );
}