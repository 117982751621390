import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import './pos_buy.css'
import { urlApi } from "../../../../url_api/url_api";
import axios from 'axios';


import MessageBox from '../../../../components/message/messagebox'

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Iframe from 'react-iframe';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';


import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

import checkNumber from '../../../../components/checkInput/checkNumber';


export default function Pos_buy() {
  let { packageId } = useParams();
  console.log('packageId : ' + packageId);
  const CHARACTER_LIMIT = 10;
  const [fullName, setFullName] = useState('');
  const [tel, setTel] = useState('');
  const [storeType, setStoreType] = useState('');
  const [eMail, setEmail] = useState('');
  const [remark, setRemark] = useState('');

  const [storeName, setStoreName] = useState('');
  const [storeCount, setStoreCount] = useState('');

  const [saveStatus, setSaveStatus] = useState();

  // const [masterProgramID, setMasterProgramID] = useState('1');
  const [masterPackageHdID, setMasterPackageID] = useState('');
  const [masterPackageTypeID, setMasterPackageTypeID] = useState('');
  const [masterPackageDtID, setMasterPackageDtID] = useState('');

  // const [masterProgramData, setMasterProgramData] = useState([]);
  const [masterPackageHdData, setMasterPackageData] = useState([]);
  const [masterPackageTypeData, setMasterPackageTypeData] = useState([]);
  const [masterPackageDtData, setMasterPackageDtData] = useState([]);

  const [textcheckName , setTextcheckName] = useState(false);
  const [textcheckType , setTextcheckType] = useState(false);
  const [textcheckCount , setTextcheckCount] = useState(false);
  const [textcheckfullName , setTextcheckfullName] = useState(false);
  const [textcheckTel , setTextcheckTel] = useState(false);
  const [textcheckEmail , setTextcheckEmail] = useState(false);

//#region *************************** useEffect  ***************************
  useEffect( () => {
    if(packageId === '')
      return;
    getDataPackageHD();

  }, [packageId])

  useEffect( () => {
    if(masterPackageHdID === '')
      return;
    getDataPackageTYPE();
  }, [masterPackageHdID])

  useEffect( () => {
    if(masterPackageTypeID === '')
      return;
      getDataPackageDT();
  }, [masterPackageTypeID])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  useEffect(() => {
    if(saveStatus){
      const swalWithBootstrapButtons = Swal.mixin({
      
        buttonsStyling: false,
      });
      if(saveStatus === true){
        swalWithBootstrapButtons.fire(
          "SUCCESS",
          "บันทึกสำเร็จ!"
        );
        handleClearData();
      }else{
        swalWithBootstrapButtons.fire(
          "ERROR",
          "บันทึกไม่สำเร็จ!"
        );
      }
    }
  }, [saveStatus])
//#endregion

//#region *************************** Get Data ***************************
  async function  getDataPackageHD() {
    try {
      const data = {
        "master_program_id": "1"
      }

      let url = urlApi + "/packhd";
  
      await axios.post(url, data)
      .then((res) => {
        // handle success
        console.log(res.data);

        if (res.data.length > 0) {
          setMasterPackageData(res.data);
          setMasterPackageID(packageId);
        } else {
          setMasterPackageData([]);
        }
      })
      .catch((err) => {
         // handle errors
         console.log(err);
      })
      
    } catch (error) {
      console.log(error);
      return;
    }
  }

  async function  getDataPackageTYPE() {
    try {
      const data = {
        "master_package_hd_id": masterPackageHdID
      }

      let url = urlApi + "/packtype";
  
      await axios.post(url, data)
      .then((res) => {
        // handle success
        console.log(res.data);

        if (res.data.length > 0) {
          setMasterPackageTypeData(res.data);
        } else {
          setMasterPackageTypeData([]);
        }
      })
      .catch((err) => {
         // handle errors
         console.log(err);
      })
      
    } catch (error) {
      console.log(error);
      return;
    }
  }

  async function  getDataPackageDT() {
    try {
      const data = {
        "master_package_type_id": masterPackageTypeID
      }

      let url = urlApi + "/packdt";
  
      await axios.post(url, data)
      .then((res) => {
        // handle success
        console.log(res.data);

        if (res.data.length > 0) {
          setMasterPackageDtData(res.data);
        } else {
          setMasterPackageDtData([]);
        }
      })
      .catch((err) => {
         // handle errors
         console.log(err);
      })
      
    } catch (error) {
      console.log(error);
      return;
    }
  }

  //#endregion

//#region *************************** Evnt ***************************
  
  const handleChangeStoreType = (event) => {
    setStoreType(event.target.value);
  };

  const handleChangePackageHdID = (event) => {
    setMasterPackageID(event.target.value);
    setMasterPackageTypeID('');
    setMasterPackageDtID('');
  };

  const handleChangePackageTypeID = (event) => {
    setMasterPackageTypeID(event.target.value);
  };

  const handleChangePackageDtID = (event) => {
    setMasterPackageDtID(event.target.value);
  };
  //#endregion

//#region *************************** Function ***************************
  function handleClearData(){
    setStoreName('');
    setStoreCount('');
    setFullName('');
    setTel('');
    setStoreType('');
    setEmail('');
    setRemark('');

    setMasterPackageID('');
    setMasterPackageTypeID('');
    setMasterPackageDtID('');

    setSaveStatus(false);
  }

  function handleCheckData(){

    if (storeName == '') {
      MessageBox('warning','กรุณากรอก ชื่อร้าน');
      setTextcheckName(true);
      return;
    }else{
      setTextcheckName(false);
    }

    if(storeType == '')
    {
      MessageBox('warning','กรุณาเลือก ประเภทร้าน');
      setTextcheckType(true);
      return;
    }else{
      setTextcheckType(false);
    }

    if (storeCount == '') {
      MessageBox('warning','กรุณากรอก จำนวนสาขา');
      setTextcheckCount(true);
      return;
    }else{
      setTextcheckCount(false);

    }

    if(fullName == ''){
      MessageBox('warning','กรุณากรอก ชื่อ - นามสกุล');
      setTextcheckfullName(true);
      return;
    }else{
      setTextcheckfullName(false);
    }

    if(tel == '')
    {
      MessageBox('warning','กรุณากรอก เบอร์โทรศัพท์');
      setTextcheckTel(true);
      return;
    }else{
      setTextcheckTel(false);
    }
    
    if(eMail == '')
    {
      MessageBox('warning','กรุณากรอก อีเมล');
      setTextcheckEmail(true);
      return;
    }else{
      setTextcheckEmail(false);
    }
    return true;
  }

  function handleSubmit(){
    const swalWithBootstrapButtons = Swal.mixin({
      
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
      .fire({
        title: "คุณต้องการบันทึก ใช่ หรือ ไม่ ?",
        showCancelButton: true,
        icon: "question",
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
        reverseButtons: false,
        customClass: {
          icon: "no-before-icon",
          Button: "swal-btn",
          confirmButton: "confirmButton",
          cancelButton: "cancelButton",
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
            
          if (handleCheckData()) {
            handleInsert();
          }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) 
        {
        //   swalWithBootstrapButtons.fire(
        //     ""
        //   );
        }
      });
  }

  async function handleInsert() {
    var axios = require('axios');
    var data = JSON.stringify({
      "res_name": storeName,
      "res_type": storeType,
      "res_count": storeCount,
      "cus_name": fullName,
      "cus_phone": tel,
      "cus_email": eMail,
      "master_package_hd_id": masterPackageHdID ?? "0",
      "master_package_type_id": masterPackageTypeID ?? "0",
      "master_package_dt_id": masterPackageDtID ?? "0",
      "master_program_id": "1",
      "remark": remark
    });
    
    var config = {
      method: 'post',
      url: urlApi + '/contact',
      // url:'http://localhost:5000/pos_buy2',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    await axios(config)
    .then(function (response) {
      if(response.data == 1){
        setSaveStatus(true);
      }else {
        setSaveStatus(false);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  //#endregion
    
    return (
        <div className='pos_buy2'>
            <div className='pos_buy2_header'>
            </div>
            <div className='pos_buy2_body'>
                <div className='pos_buy2_body__left'>
                    <div className='textHeader2'>
                        ข้อมูลผู้ติดต่อ
                    </div>
                    <div className='text-input2'>
                      <TextField 
                        style={
                          {
                            marginTop: '5%',
                            fontFamily: 'var(--Font-family)'
                          }
                        } 
                        inputProps={{
                          style: {
                            fontFamily: 'var(--Font-family)',
                            fontSize: '1.6rem'
                          } 
                        }}
                        className='TextField2' 
                        id="outlined-basic" 
                        label="ชื่อร้าน" 
                        placeholder='Store Name' 
                        variant="outlined" 
                        inputMode='text'
                        value={storeName}
                        onChange={(e) => {setStoreName(e.target.value);}}
                      />
                      <ul className={textcheckName ? 'textCheck active' : 'textCheck'}>
                        * กรุณากรอกชื่อร้าน
                      </ul>
                      <FormControl fullWidth 
                        style={
                          {
                            marginTop: '5%',
                            fontFamily: 'var(--Font-family)'
                          }
                        }
                      >
                        <InputLabel id="demo-simple-select-label">ประเภทร้าน</InputLabel>
                          <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={storeType}
                              label="ประเภทร้าน"
                              onChange={handleChangeStoreType}
                          >
                            <MenuItem id='MenuItem' value={'ร้านอาหาร'}>ร้านอาหาร</MenuItem>
                            <MenuItem id='MenuItem' value={'ร้านกาแฟ'}>ร้านกาแฟ</MenuItem>
                            <MenuItem id='MenuItem' value={'ร้านคาเฟ่'}>ร้านคาเฟ่</MenuItem>
                            <MenuItem id='MenuItem' value={'ร้านบุฟเฟ่ต์'}>ร้านบุฟเฟ่ต์</MenuItem>
                            <MenuItem id='MenuItem' value={'อื่นๆ'}>อื่นๆ</MenuItem>
                          </Select>
                      </FormControl>
                      <ul className={textcheckType ? 'textCheck active' : 'textCheck'}>
                        * กรุณาเลือกประเภทร้าน
                      </ul>
                      <TextField 
                        style={{marginTop: '5%'}} 
                        className='TextField2' 
                        id="outlined-basic" 
                        label="จำนวนสาขา" 
                        placeholder='Store Count' 
                        variant="outlined" 
                        inputMode='numeric'
                        value={storeCount}
                        onChange={(e) => {setStoreCount(checkNumber(e.target.value))}}
                      />
                      <ul className={textcheckCount ? 'textCheck active' : 'textCheck'}>
                        * กรุณากรอกจำนวนสาขา
                      </ul>
                      <TextField 
                          style={{marginTop: '5%'}} 
                          className='TextField2' 
                          id="outlined-basic" 
                          label="ชื่อ - นามสกุล" 
                          placeholder='Full Name' 
                          variant="outlined" 
                          inputMode='text'
                          value={fullName}
                          onChange={(e) => {setFullName(e.target.value);}}
                      />
                      <ul className={textcheckfullName ? 'textCheck active' : 'textCheck'}>
                        * กรุณากรอกชื่อ - นามสกุล
                      </ul>
                      <TextField 
                          style={{marginTop: '5%'}} 
                          className='TextField2' 
                          id="outlined-basic" 
                          label="เบอร์โทรศัพท์" 
                          placeholder='Telephone' 
                          variant="outlined" 
                          inputProps={{
                              maxlength: CHARACTER_LIMIT,
                          }}
                          inputMode= "numeric"
                          type={'tel'}
                          value={tel}
                          onChange={(e) => {setTel(checkNumber(e.target.value));}}
                      />
                      <ul className={textcheckTel ? 'textCheck active' : 'textCheck'}>
                        * กรุณากรอกเบอร์โทรศัพท์
                      </ul>
                      <TextField 
                          style={{marginTop: '5%'}} 
                          className='TextField2' 
                          id="outlined-basic" 
                          label="อีเมล" 
                          placeholder='E-Mail' 
                          variant="outlined" 
                          inputMode= "email"
                          value={eMail}
                          onChange={(e) => {setEmail(e.target.value);}}
                      />
                      <ul className={textcheckEmail ? 'textCheck active' : 'textCheck'}>
                        * กรุณากรอกอีเมล
                      </ul>
                      <TextField
                          style={{marginTop: '5%'}}
                          className='TextField2'
                          id="outlined-textarea"
                          label="หมายเหตุ"
                          placeholder="Massage"
                          multiline
                          value={remark}
                          onChange={(e) => {setRemark(e.target.value);}}
                      />
                    </div>
                </div>
                <div className='pos_buy2_body__center'>
                  <div className='textHeader2'>
                        ข้อมูลแพ็คเกจ
                  </div>
                  <div className='text-input2'>
                    <FormControl fullWidth style={{marginTop: '5%'}}>
                        <InputLabel id="demo-simple-select-label">ชื่อแพ็คเกจ</InputLabel>
                          <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={masterPackageHdID}
                              label="ชื่อแพ็คเกจ"
                              onChange={handleChangePackageHdID}
                          > 
                              {masterPackageHdData.length > 0 ? masterPackageHdData.map((
                                {master_package_hd_id, master_package_hd_name}
                              ) => {
                                return(
                                  <MenuItem id='MenuItem' value={master_package_hd_id}>
                                    {master_package_hd_name}
                                  </MenuItem>
                                );
                                  }
                              ) : <MenuItem id='MenuItem' value="">
                                    ไม่มีข้อมูล
                                  </MenuItem>
                              }
                          </Select>
                    </FormControl>
                    <FormControl fullWidth style={{marginTop: '5%'}}>
                        <InputLabel id="demo-simple-select-label">ประเภทแพ็คเกจ</InputLabel>
                          <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={masterPackageTypeID}
                              label="ประเภทแพ็คเกจ"
                              onChange={handleChangePackageTypeID}
                          >{masterPackageTypeData.length > 0 ? masterPackageTypeData.map(({master_package_type_id, master_package_type_name}) => {
                            return(
                              <MenuItem id='MenuItem' value={master_package_type_id}>
                                    {master_package_type_name}
                                  </MenuItem>
                            );
                          }) : <MenuItem id='MenuItem' value={''}>ไม่มีข้อมูล</MenuItem>}
                            
                          </Select>
                    </FormControl>
                    <FormControl fullWidth style={{marginTop: '5%'}}>
                        <InputLabel id="demo-simple-select-label">ระยะเวลาแพ็คเกจ</InputLabel>
                          <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={masterPackageDtID}
                              label="ระยะเวลาแพ็คเกจ"
                              onChange={handleChangePackageDtID}
                          >
                          {masterPackageDtData.length > 0 ? masterPackageDtData.map(({master_package_dt_id, master_package_dt_name}) => {
                            return(
                              <MenuItem id='MenuItem' value={master_package_dt_id}>{master_package_dt_name}</MenuItem>
                            );
                          })
                          : <MenuItem id='MenuItem' value={''}>ไม่มีข้อมูล</MenuItem>}
                          </Select>
                    </FormControl>
                  </div>
                  <div>
                      <Button id='Button' onClick={() => {
                          handleSubmit()
                      }}
                          variant="contained" >
                          ลงทะเบียน
                      </Button>
                  </div>
                </div>
                <div className='pos_buy2_body__right'>
                
                    <div className='textHeader2'>
                        ที่อยู่สำนักงาน
                    </div>
                    <text className='textAddress' style={{marginTop: '2%', marginBottom: '2%'}}>
                        ที่อยู่: 88/88 หมู่ 20 ต.บ้านเป็ด อ.เมืองขอนแก่น จ.ขอนแก่น 40000
                        <br/>
                        <br/>
                        จันทร์-ศุกร์: 9.00 - 17.00 น.
                    </text>
                      <Iframe className='Iframe'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d239.1546776180886!2d102.78492304269855!3d16.451476788961795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x312261dbab44e41f%3A0x1f75c7ad939c22a8!2sTCS%20Tech%20care%20solution%20Co.%2CLtd!5e0!3m2!1sth!2sth!4v1667197494637!5m2!1sth!2sth" 
                        width='100%'
                        height='100%'
                        allowfullscreen="true" 
                        frameBorder='0'
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade">
                      </Iframe>
                    <div className='textHeader' style={{marginTop: '5%'}}>
                        ติดต่อฝ่ายขาย
                    </div>
                    <text className='textAddressPhone' style={{marginTop: '2%', marginBottom: '2%'}}>
                        โทรศัพท์: 06-5464-5952
                    </text>
                </div>
            </div>
            <div className='pos_buy2_bottom'>
              {/* <div>
                      <Button onClick={() => {
                          handleSubmit()
                      }}
                          style={{
                              width: "100%", 
                              height: "100%", 
                              fontSize: 20, 
                              color: '#fff',
                          }} 
                          variant="contained"
                      >
                          ลงทะเบียน
                      </Button>
              </div> */}
            </div>
        </div>
    )
}