import React, {useState, useEffect} from 'react'
import './HomeAdmin.css'
import {urlApi} from "../../url_api/url_api";
// import { useTableSearch } from "../../components/Table/useTableSearch";
import Buttons from '../../components/Button/Button';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, Button } from '@material-ui/core';
import SearchBar from "material-ui-search-bar";
import TablePagination from '@mui/material/TablePagination';

import MessageBox from '../../components/message/messagebox';
import Moment from 'moment';
import Swal from 'sweetalert2'

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import HomeManage from './HomeManage';
import { Link } from 'react-router-dom';

import axios from 'axios'; 


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


function createData(master_contact_customer_id, res_name, res_type, res_count,cus_name,cus_phone, cus_email,master_package_hd_id,master_package_hd_code,
  master_package_type_id,master_package_type_name,master_package_dt_id,
  master_package_dt_name,master_program_id,master_program_code,remark,savetime) {
  return {master_contact_customer_id, res_name, res_type, res_count,cus_name,cus_phone, cus_email,master_package_hd_id,master_package_hd_code,
    master_package_type_id,master_package_type_name,master_package_dt_id,
    master_package_dt_name,master_program_id,master_program_code,remark,savetime};
}
function HomeAdmin() {
  // const [searched, setSearched] = useState("");
  const [searchVal, setSearchVal] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [masterProgramID, setMasterProgramID] = useState(0);
  const [programName, setProgramName] = useState("ทั้งหมด");
  const [masterProgram, setMasterProgram] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [statusID, setStatusID] = useState(0);
  const [statusName, setStatusName] = useState("ทั้งหมด");
  const [statusData, setStatusData] = useState([]);

  Moment.locale('th');
  
  const classes = useStyles();
  
  useEffect(() => {
    localStorage.getItem('userLogin')
    getStatusBtn()
    getMasterProgram()
    getDataContact()

  }, [])

  useEffect(() => {
    console.log(searchVal);
    if (searchVal != null && searchVal != "") {
      requestSearch(searchVal);
    }else{
      getDataContact()
    }
  }, [searchVal])

  useEffect(() => {
    
  }, [contactData])

  useEffect(() => {
    console.log("status id : " + statusID);
    console.log("program id :" + masterProgramID);
    getDataContact()
  }, [masterProgramID, statusID])


  const requestSearch = (searchedVal) => {
    const filteredRows = contactData.filter((row) => {
      return row.res_name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    // if(filteredRows.length > 0)
      setContactData(filteredRows);
  };

  const cancelSearch = () => {
    setSearchVal("");
    getDataContact();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const buttonHandle = (even) => {
    console.log(even);
  }

  const handleRowclick = (even, cusID) => {
    if (even === 'จัดการ') {
      //MessageBox("error", "dataText")
      window.location.href = '/homeadmin/manage/' + cusID;
    }
  }

 const getDataContact = async () => {
    var axios = require('axios');
    var data = JSON.stringify({
      "program_id": masterProgramID,
      "_customer_id": "0",
      "_status_id": statusID
    });
    
    var config = {
      method: 'post',
      url: urlApi + '/contact/search_cus',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    await axios(config)
    .then(function (response) {
      if (response.data.length > 0) {
        setContactData(response.data)
      }else{
        setContactData([])
      }
    })
    .catch(function (error) {
      console.log(error);
    });

  }

  function handleStatus(params) {
    console.log(params);
    
    if (params == 0) {
      setStatusID(0)
    }else{
      setStatusID(params)
    }
  }

  function handleProgram(params) {
    console.log(params);
    
    if (params == 0) {
      setMasterProgramID(0)
    }else{
      setMasterProgramID(params)
    }
  }

  async function getStatusBtn() {
    var axios = require('axios');
    var data = JSON.stringify({
      "status": "1"
    });

  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: urlApi + '/contact/get_cus_status',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  await axios(config).then(function (response) {
    if (response.data.length > 0) {
      setStatusData(response.data);
    }
  })
  .catch(function (error) {
    console.log(error);
  });
  }

  async function getMasterProgram() {
    var axios = require('axios');
    var data = '';

  var config = {
    method: 'post',
    url: urlApi + '/masterProgram/all',
    headers: { },
    data : data
  };

  await axios(config)
  .then(function (response) {
    if (response.data.length > 0) {
      setMasterProgram(response.data);
    }
  })
  .catch(function (error) {
    console.log(error);
  });
  }

  function handlerefrash() {
    setMasterProgramID(0);
    setProgramName("ทั้งหมด");
    setStatusID(0);
    setStatusName("ทั้งหมด");
    getDataContact();
  }


  return (
    <div className='homeAdmin'>
      <div className='homeAdmin_header'>
        <div className='homeAdmin_header_I'>
        โปรแกรม :
        <Button id='btn_status' variant="text" 
          onClick={ () => {handleProgram(0); setProgramName("ทั้งหมด") }} 
          className="btn_status">
          ทั้งหมด
        </Button>
        {masterProgram.length > 0 ? masterProgram.map(({master_program_id, master_program_code, master_program_name, master_program_prefix }) => {
          return(
            <Button key={master_program_id} id='btn_status' variant="text" 
              onClick={ () => {handleProgram(master_program_id); setProgramName(master_program_prefix)}} 
              className="btn_status">
              {master_program_prefix}
            </Button>
          )
        }) : <></>
        }
        </div>
        <div className='homeAdmin_header_II'>
        สถานะเอกสาร :
          <Button id='btn_status' variant="text" onClick={ () => {handleStatus(0); setStatusName("ทั้งหมด")}} className="btn_status">ทั้งหมด</Button>
          {statusData.length > 0 ? statusData.map(({master_contact_status_id, master_contact_status_name}) => {
            return(
              <Button id='btn_status' variant="text"  key={master_contact_status_id}
              onClick={() => {handleStatus(master_contact_status_id); setStatusName(master_contact_status_name)}} 
              className="btn_status">
              {master_contact_status_name}
              </Button>
            )
          }) : <></>}
          
        </div>
        <div className='homeAdmin_header_III'>
          <div style={{width: "70%", height: '3.5rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <text style={{color: "red", marginRight: "5%"}}>เงื่อนไขในการค้นหา  โปรแกรม : {programName}</text><text style={{color: "red", marginRight: "5%"}}>สถานะเอกสาร : {statusName} </text>
          </div>
          <div style={{width: "13%", marginRight: "3%"}}>
            <Buttons style={{borderRadius: '40px', height: '3.5rem'}}  name="รีเฟรช" onClick={() => {handlerefrash()}}></Buttons>
          </div>
          <div style={{width: "20%" }}>
            <SearchBar className='searchData' style={{backgroundColor: 'rgba(194, 194, 194, 0.5)', borderRadius: '40px'}}
              value={searchVal}
              onChange={(e) => setSearchVal(e)}
              onCancelSearch={() => cancelSearch()}
              enterButton
            />
          </div>
        </div>
      </div>
      <div className='homeAdmin_body'>
      <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead style={{backgroundColor: '#0087E7', width: 'auto'}}>
          <TableRow style={{maxHeight: '2rem'}}>
            <TableCell style={{width: 'auto', color: 'antiquewhite'}} align="center">ชื่อร้าน</TableCell>
            <TableCell style={{width: 'auto', color: 'antiquewhite'}} align="center">ประเทภร้าน</TableCell>
            <TableCell style={{width: 'auto', color: 'antiquewhite'}} align="right">จำนวนสาขา</TableCell>
            <TableCell style={{width: 'auto', color: 'antiquewhite'}} align="right">ชื่อลูกค้า</TableCell>
            <TableCell style={{width: 'auto', color: 'antiquewhite'}} align="right">เบอร์โทรศัพท์</TableCell>
            <TableCell style={{width: 'auto', color: 'antiquewhite'}} align="center">อีเมล</TableCell>
            <TableCell style={{width: 'auto', color: 'antiquewhite'}} align="right">โปรแกรม</TableCell>
            <TableCell style={{width: 'auto', color: 'antiquewhite'}} align="right">ชื่อแพ็คเกจ</TableCell>
            <TableCell style={{width: 'auto', color: 'antiquewhite'}} align="right">ประเภทแพ็คเกจ</TableCell>
            <TableCell style={{width: 'auto', color: 'antiquewhite'}} align="right">ระยะเวลาแพ็คเกจ</TableCell>
            <TableCell style={{width: 'auto', color: 'antiquewhite'}} align="right">วันที่บันทึก</TableCell>
            <TableCell style={{width: 'auto', color: 'antiquewhite'}} align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contactData.length > 0 ? contactData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((contactData) => (
            <TableRow key={contactData.master_contact_customer_id} onClick={(e) => {handleRowclick(e.target.innerText, contactData.master_contact_customer_id)}}>
              <TableCell component="th" scope="row">{contactData.res_name}</TableCell>
              <TableCell align="center">{contactData.res_type}</TableCell>
              <TableCell align="right">{contactData.res_count}</TableCell>
              <TableCell align="center">{contactData.cus_name}</TableCell>
              <TableCell align="center">{contactData.cus_phone}</TableCell>
              <TableCell align="center">{contactData.cus_email}</TableCell>
              <TableCell align="center">{contactData.master_program_code}</TableCell>
              <TableCell align="center">{contactData.master_package_hd_code}</TableCell>
              <TableCell align="center">{contactData.master_package_type_name}</TableCell>
              <TableCell align="center">{contactData.master_package_dt_name}</TableCell>
              <TableCell align="center">{ Moment(contactData.savetime).format('d/MM/') + (parseInt(Moment(contactData.savetime).format('yyyy'))+543)}</TableCell>
              <TableCell align="center" style={{color: 'antiquewhite'}}>
                <Button style={{width: "100px", height: "45px",fontWeight: "600", color: "white", fontSize: '1.3rem', borderRadius: '45px'}}variant="contained" >จัดการ</Button>
              </TableCell>
            </TableRow>
          )) : <TableCell colSpan={12} style={{color: 'red', fontSize: '2rem', fontFamily: 'var(--font-family)'}}  align="center">ไม่มีข้อมูล</TableCell>}
        </TableBody>
      </Table>
      </TableContainer>
      </div>
      <div className='homeAdmin_bottom'>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={contactData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  )
}

export default HomeAdmin