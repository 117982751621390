import React, { useState } from "react";
import { BrowserRouter as Router,  Routes, Route, Link } from "react-router-dom";
import './App.css';

import Main from "./pages/main";
import Home from "./pages/home/home";
import HomeAdmin from "./pages/home/HomeAdmin";
import HomeManage from "./pages/home/HomeManage";
import PackageReport from "./pages/admin/report/package_report";
import User from "./pages/admin/setting/user";
import SaleSetting from "./pages/admin/setting/SaleSetting";
import Setting from "./pages/admin/setting/Setting";

import About from "./pages/about/about";
import Resume from "./pages/about/resume";
import Vision from "./pages/about/vision";
import Symbol from "./pages/about/symbol";
import Organization from "./pages/about/organization_manage";

import Contact from "./pages/contact/contact";
import Services from "./pages/Product_Service/services";
import Promotion from "./pages/promotion/promotion";
import Distribution from "./pages/distribution&client/distribution";
import News from "./pages/news/news_activity";
import Manual from "./pages/manual/manual";
import Career from "./pages/career/career";
import error_page from "./pages/404error.png"
import Login from "./pages/Login/Login"

import Pos from "./pages/Product_Service/pruduct_page/pos/pos";
import Pos_buy from "./pages/Product_Service/pruduct_page/pos/pos_buy";
import PackageCompare from './pages/Product_Service/pruduct_page/pos/pos_package_compare';

import Erp from "./pages/Product_Service/pruduct_page/erp/erp";
import Erp_Buy from "./pages/Product_Service/pruduct_page/erp/erp_buy";

import Hrm from "./pages/Product_Service/pruduct_page/hrm/hrm";

import Mrp from "./pages/Product_Service/pruduct_page/mrp/mrp";
import Mrp_Buy from "./pages/Product_Service/pruduct_page/mrp/mrp_buy";

import Pms from "./pages/Product_Service/pruduct_page/pms/pms";
import Pms_Buy from "./pages/Product_Service/pruduct_page/pms/pms_buy";

import Xcom from "./pages/Product_Service/pruduct_page/xcom/xcom";
import Xcom_Buy from "./pages/Product_Service/pruduct_page/xcom/xcom_buy";
import User_Setting from "./pages/admin/setting/user_setting";
import User_Register from "./pages/admin/setting/register";
import AddProgram from "./pages/admin/setting/AddProgramModel";


function NoMatch() {
  return (
    <div className="App">
      <ul>
        <Link to={localStorage.getItem('userLogin') ? '/homeadmin' : '/'}>
          <img src={error_page} alt="errorpage" className="error_page"/>
        </Link>
      </ul>
    </div>
  
  );
}


function App() {
  return (
    <Router basename="/">
      <Routes>
        <Route path="/" element={<Main/>}> 
          <Route path="" element={<Home/>} />
          <Route path="/homeadmin" element={<HomeAdmin/>} />
          <Route path="/homeadmin/packagereport" element={<PackageReport/>} />
          <Route path="/homeadmin/user" element={<User/>} />
          <Route path="/homeadmin/sale" element={<SaleSetting/>} />
          <Route path="/homeadmin/sale/:packageID" element={<SaleSetting/>} />
          <Route path="/homeadmin/setting/addprogram" element={<AddProgram/>} />
          <Route path="/homeadmin/user/setting" element={<User_Setting/>} />
          <Route path="/homeadmin/user/register" element={<User_Register/>} />
          <Route path="/homeadmin/manage/:cusID" element={<HomeManage/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/about/history" element={<Resume/>} />
          <Route path="/about/vision" element={<Vision/>} />
          <Route path="/about/symbol" element={<Symbol/>} />
          <Route path="/about/Organization_manage" element={<Organization/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/products" element={<Services/>} />
          <Route path="/promotion" element={<Promotion/>} />
          <Route path="/distribution" element={<Distribution/>} />
          <Route path="/news" element={<News/>} />
          <Route path="/manual" element={<Manual/>} />
          <Route path="/career" element={<Career/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/products/pos" element={<Pos/>} />
          <Route path="/products/pos/buy/:packageId" element={<Pos_buy/>} />
          <Route path="/products/pos/packagecompare" element={<PackageCompare/>} />
          <Route path="/products/erp" element={<Erp/>} />
          <Route path="/products/erp/buy" element={<Erp_Buy/>} />
          <Route path="/products/hrm" element={<Hrm/>} />
          <Route path="/products/mrp" element={<Mrp/>} />
          <Route path="/products/mrp/buy" element={<Mrp_Buy/>} />
          <Route path="/products/pms" element={<Pms/>} />
          <Route path="/products/pms/buy" element={<Pms_Buy/>} />
          <Route path="/products/xcom" element={<Xcom/>} />
          <Route path="/products/xcom/buy" element={<Xcom_Buy/>} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;