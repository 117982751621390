import React, {useEffect} from "react";
import './promotion.css'
import { Link  } from 'react-router-dom';
import ScrollToTop from '../../components/Scroll/ScrollToTop'

// import img001 from '../image/S__563669.jpg';
import img001 from './img_promotion/S__934015.jpg';




function Promotion() {
  // const [OpenModal, setOpenModal] =  useState(true)


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
    return (
      <div className="promotion">
          <ScrollToTop/>
        <div className="promotion__header">
          <ul className="vision--head box"></ul>
          <div className="promotion__header--h1">Promotion</div>
        </div>
        <div className="promotion__header--p">OHO POS โปรแกรมจัดการร้านอาหาร ร้านค้า ผู้ช่วยให้คุณจัดการธุรกิจอย่างมืออาชีพ ในราคาที่สบายกระเป๋า เริ่มต้นเพียง 1,500 บาท พบกับเงื่อนไขการชำระพิเศษที่คุณออกแบบได้</div>
        <div className="promotion--body">
          <div>
          <img src={img001} alt='posPromotion' />  
          </div>
          <div className="promotion--body--btn">
            <Link to='/services/pos/buy/1'>
              <button className="btn--buypos">คลิ๊กที่นี่! เพื่อรับสิทธิ์โปรโมชั่น</button>
            </Link>
          </div>
            
          
        </div>
      </div>
    );
  }
  export default Promotion;