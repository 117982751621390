import React, {useEffect} from 'react'
import './erp.css'
import { Link } from 'react-router-dom';


import productservice from './img_erp/product-service-.jpg'
import erpModul from './img_erp/OHO-ERP-MODULE.jpg'
import { Button } from '@mui/material'
import { useState } from 'react'
import ScrollToTop from '../../../../components/Scroll/ScrollToTop';

export default function Erp() {
    const [openModal, setOpenModal] = useState(false);
    const [openButton, setOpenButton] = useState(false);
    const [scrollX, setScrollX] = useState(0);
    const [scrollY, setScrollY] = useState(0);


    useEffect(() => {
        window.scrollTo(scrollX, scrollY)
        
      }, [])
      
      function handleModal(){
        setOpenModal(true)
        setOpenButton(true)
      }

      function handleModalClose(){
        setOpenModal(false)
        setOpenButton(false)
      }


    return(
        <div className='erp'>
        <ScrollToTop />
            <div className='erp__header'>
                <div className="vision--head box"></div>
                <text>ERP (ซอฟท์แวร์สำหรับการวางแผนการจัดการ)</text>
            </div>
            
            <div>
                <img src={productservice} alt="" />
            </div>
            <Link to='/products/erp/buy'>
            <button className="btn--package">คลิ๊ก! สั่งซื้อผลิตภัณฑ์</button>
            </Link>
            {!openButton ? 
            <button  className='btn--erp' onClick={()=>{handleModal()}}>
                CLICK ERP MODULE
            </button> : 
            <button variant="contained" className='btn--erp'  onClick={()=>{handleModalClose()}}>
                hide
            </button>
            }
            {openModal && 
                <div className='erp__body'>
                    <img src={erpModul} alt='erpModul' />
                </div>
            }
            <div className='erp__bottom'>

            </div>
        </div>
    )
}