import React from 'react'
import './user.css'
import mss from '../../../components/message/messagebox'
import {Link} from "react-router-dom";

import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';

function User() {

    function handleClick(text) {
        mss("info", text)
    }
  return (
    <div className='user'>
        <div className='user_body'>
            <Link className='user-link' to="/homeadmin/user/setting">
                <div className='user_body_card'>
                    <ManageAccountsOutlinedIcon 
                        sx={{
                            width: '4rem',
                            height: '4rem',
                            ":hover":{
                                color: '#fff',
                                backgroundColor: '#fff'
                            },
                            boxShadow: 1,
                            mb: 2,
                            borderRadius: 5
                        }} 
                        className='user_body_card_icon'
                    />Setting User
                </div>
            </Link>
            <Link className='user-link' to="/homeadmin/user/register">
                <div className='user_body_card'>
                    <AppRegistrationOutlinedIcon 
                        sx={{
                            width: '4rem',
                            height: '4rem',
                            ":hover":{
                                color: '#fff',
                                backgroundColor: '#fff'
                            },
                            boxShadow: 1,
                            mb: 2,
                            borderRadius: 5
                        }}
                    className='user_body_card_icon' 
                    />Register User
                </div>
            </Link>
        </div>
    </div>
  )
}

export default User