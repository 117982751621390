import React, {useEffect} from "react";
import './vision.css';


function Vision() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    return (
      <div className="vision">
        <div className="vision--head">
          <div className="vision--head box"></div>
          <div className="vision--head texthead">วิสัยทัศน์องค์กร</div>
        </div>
        <br/>
        <div className="vision--body">
          <ul className="vision--body--text"><p>
            “เราจะเป็นบริษัทซอฟ์แวร์ที่เติมเต็มและติดปีกธุรกิจคุณ”<br/>
            We are fulfillment solution for your backline with professional software</p>
          </ul>
        </div>
        <br/>
        <br/>
        <div className="vision--buttom">
          <div className="vision--head box"></div>
          <div className="--buttom--head">พันธกิจ</div>
        </div>
        <br/>
        <div>
        <ul className="--buttom--body">
          เราจะส่งมอบสุดยอดเครื่องมือในการดำเนินธุรกิจ ซึ่งพัฒนาโดยทีมงานที่มีความสุข สนุก ท้าทายกับงานที่ทำ ผ่านการเชื่อมโยงประสบการณ์จริงและเทคโนโลยีอันทันสมัย เพื่อสนองตอบต่อความพึงพอใจสูงสุดของผู้ประกอบการ 
          </ul>
        </div>
      </div>
    );
  }
  export default Vision;