import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import './home.css';
import ScrollToTop from '../../components/Scroll/ScrollToTop'

//#region Import Pages
import Services from "../Product_Service/services";
import about_img from "./image/About01.jpg";
import Button from '@mui/material/Button';
//#endregion
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

import img001 from './image/S001.jpg';
import img002 from './image/S002.jpg';
import img003 from './image/S003.jpg';
import img004 from './image/S004.jpg';
import img005 from './image/S005.jpg';
import img006 from './image/S006.jpg';
import img007 from './image/S007.jpg';
import img008 from './image/S008.jpg';

import PromotionModal from "../promotion/promotionModal";

function Home() {
  const [slides, setSlides] = useState(3);
  const [OpenModal, setOpenModal] =  useState(true);
  const [sizeDisplay, setSizeDisplay] =  useState();
  

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth > 960) {
        // setIsMobile(true)
        setSlides(3)
    } else {
        // setIsMobile(false)
        setSlides(1)
    }
  }
  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    setSizeDisplay(window.innerWidth)
    window.scrollTo(0, 0);
    localStorage.removeItem("userLogin");
  }, [])

  useEffect(() => {
    handleResize();
  }, [sizeDisplay])

    return (
      
      <div className="home">
        <ScrollToTop />
        <div className="home-head">
        {/* {OpenModal && <PromotionModal OpenModal={OpenModal} setOpenModal={setOpenModal}  />} */}
          <ul className="text__headerI">บริษัท เทค แคร์ โซลูชั่น จำกัด</ul>
          <ul className="text__headerII">TECH CARE SOLUTION</ul>
        </div>
        <div className="home-body">
          <Swiper
            spaceBetween={30}
            slidesPerView={slides}
            centeredSlides={false}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
              pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper__home"
          >
            <SwiperSlide>
              <div className="card__home">
                <img  src={img002} alt=""/>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card__home">
                <img src={img003} alt=""/>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card__home">
                <img src={img007} alt=""/>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card__home">
                <img src={img005} alt=""/>
              </div>
            </SwiperSlide>
            
            <SwiperSlide>
              <div className="card__home">
                <img src={img008} alt=""/>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="home__services">
          <Services/>
        </div>
        <div className="home__about">
          <div className="about-image">
            <div className="about--image">
                <img src={about_img} alt="about"/>
            </div>
          </div>
          <div className="about--text">
            <ul className="about--text--h1">
              <text>About Us</text>
            </ul>
            <ul className="about--text--p">
              <p>บริษัท เทค แคร์ โซลูชั่น จำกัด ดำเนินธุรกิจด้านซอฟต์แวร์สำเร็จรูปและสารสนเทศ
                ตั้งแต่ปี 2565 ในชื่อผลิตภัณฑ์ OHO โดยเริ่มจากการพัฒนาซอฟต์แวร์ POS, ERP, MRP, HRM, Web Design 
                สำหรับทุกธุรกิจ ตั้งแต่ปี 2564 เราได้พัฒนาซอฟต์แวร์ให้ตอบโจทย์การใช้งาน 
                และ เหมาะสมกับธุรกิจ ผ่านการเชื่อมโยงประสบการณ์จริงและเทคโนโลยีอันทันสมัย 
                จนนำมาซึ่งความพึงพอใจของผู้ใช้งานและผู้บริหาร
              </p>
            </ul>
            <ul className="about--text--btn">
              <Link to="/about">
              <Button style={{
                width: '10rem',
                height: '4rem'
              }} variant="contained" className="btn">Learn More</Button>
              </Link>
            </ul>
          </div>
        </div>
        <div className="home__Promotion">
          {/* <Promotion/> */}
        </div>
        <div className="home__contact">
          <div className="_contact1">
              <div className="__text1">Let's Talk About</div>
              <div className="__text2">Tech Care Solution</div>
          </div>
          <div className="_contact2">
          <text><b>Tech Care Solution :</b> บริษัทซอฟต์แวร์ที่เติมเต็มและติดปีกธุรกิจของคุณ</text>
            <a href="https://www.facebook.com/messages/t/109118795120548" target="_blank" rel="noopener noreferrer">
              <Button style={{
                width: 'max-content',
                height: '4rem'
              }} variant="contained" className="btn">Contact Us</Button>
            </a>
          </div>
        </div>
      </div>
    );}
  export default Home;