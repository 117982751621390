import React from 'react'
import './user_setting.css'

function User_setting() {
  return (
    <div className='user_setting'>
      <div className='bar'>
        <text className='bar-link' onClick={()=> {window.location.href = "/homeadmin/user"}}>User Menu</text>
        <text className='bar-link'> / </text>
        <text className='bar-link' onClick={()=> {window.location.href = "/homeadmin/user/setting"}}>User Setting</text>
      </div>
      <div className='user_setting_body'>
        <div className='user_setting_body_list_user'>
          User1
        </div>
        <div className='user_setting_body_list_user'>
          User2
        </div>

      </div>
    </div>
  )
}

export default User_setting