import React,{useEffect} from "react";

function Manual() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    return (
      <div>
        <h2>Manual</h2>
      </div>
    );
  }
  export default Manual;