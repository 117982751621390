import React, {useState} from "react";
import {NavLink, Link} from "react-router-dom";
import './navbar.css';
import logo from "./logoTechCare2.png";
import DropdownAbout from "../about/DropdownAbout";
import DropdownProduct from "../Product_Service/DropdownProduct";

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Login from '@mui/icons-material/Login';





function Navbar() {
  const [clickMenuBar, setClickMenuBar] = useState(false);

  const [clickAbout, setClickAbout] = useState(false);
  const [clickProduct, setClickProduct] = useState(false);

  const [dropdownAbout, setDropdownAbout] = useState(false);
  const [dropdownProduct, setDropdownProduct] = useState(false);

  const [dropdown_about, setDropdown_about] = useState("dropdown__about__menu");
  const [dropdown_product, setDropdown_product] = useState("dropdown__product__menu");

  const [anchorEl, setAnchorEl] = useState(null);
  const openUser = Boolean(anchorEl);
  const handleClickUser = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUser = () => {
    setAnchorEl(null);
  };

  const handleClick = () => setClickMenuBar(!clickMenuBar);
  const closeMobileMenu = () => setClickMenuBar(false);


  const handleClickAbout = () => setClickAbout(!clickAbout);
  const closeMobileMenuAbout = () => {
    setClickAbout(false)
    closeMobileMenu()
  };

  const handleClickProduct = () => setClickProduct(!clickProduct);
  const closeMobileMenuProduct = () => {
    setClickProduct(false)
    closeMobileMenu()
  };

//#region About
  const onMouseEnterAbout = () => {
    if(window.innerWidth < 960)
    {
      setDropdownAbout(false)
    }else{
      setDropdownAbout(true)
      setDropdown_about('dropdown__about__menu_active')
    }
  };

  const onMouseLeaveAbout = () => {
    if(window.innerWidth < 960)
    {
      setDropdownAbout(false)
    }else{
      setDropdownAbout(false)
      setDropdown_about('dropdown__about__menu')
    }
  };
//#endregion

//#region Product
const onMouseEnterProduct = () => {
    if(window.innerWidth < 960)
    {
      setDropdownProduct(false)
    }else{
      setDropdownProduct(true)
      setDropdown_product("dropdown__product__menu_active");
    }
  };

  const onMouseLeaveProduct = () => {
    if(window.innerWidth < 960)
    {
      setDropdownProduct(false)
    }else{
      setDropdownProduct(false)
      setDropdown_product("dropdown__product__menu");

    }
  };
//#endregion

const userMenus = () => {
  return (
    <>
    <Tooltip title="User Menu" className="userMenus">
          <IconButton
            onClick={handleClickUser}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={openUser ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openUser ? 'true' : undefined}
          >
            <Avatar sx={{ width: 30, height: 30, padding: .7 }}>U</Avatar>
          </IconButton>
        </Tooltip>

        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openUser}
        onClose={handleCloseUser}
        onClick={handleCloseUser}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
      {/* <MenuItem>
          <Avatar /> Profile
      </MenuItem>
      <MenuItem>
        <Avatar /> My account
      </MenuItem>
      <Divider /> */}
      <MenuItem className="MenuItem">
        <ListItemIcon>
          <PersonAdd fontSize="large" />
        </ListItemIcon>
          Add another account
      </MenuItem>
      {/* <MenuItem className="MenuItem">
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
          Settings
      </MenuItem> */}
      <Link to="/Login" >
        <MenuItem className="MenuItem">
          <ListItemIcon>
            <Login fontSize="large" />
          </ListItemIcon>
          Login
        </MenuItem>
      </Link>
      
    </Menu>
    </>
  )
}

    return (
        <div className="navbar">
          <div className="navbar-logo">
              <Link 
                to="/"
              >
                {/* <img src={logo} alt="Logo" id="logo"/> */}
                เทค แคร์ โซลูชั่น
              </Link>
          </div>
          <div className="menu-icon" onClick={handleClick}>
            <i className={clickMenuBar ? 'fa-solid fa-times' :'fa-solid fa-bars'}></i>
          </div>
          <div className="navbar-menu" onClick={handleClick}>
            <ul className={clickMenuBar ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <NavLink 
                  to="/" 
                  className='nav-links'
                  onClick={closeMobileMenu}
                > 
                  HOME
                </NavLink>
              </li>
              <li className="nav-item" 
                  onMouseEnter={onMouseEnterAbout}
                  onMouseLeave={onMouseLeaveAbout}
                  onClick={closeMobileMenu}
              >
                <div className="dropdown__menu">
                  <NavLink to="/about" className='nav-links' onClick={closeMobileMenu}> 
                    ABOUT
                  </NavLink>
                  {/* <i className='fa-solid fa-circle-chevron-down'/> */}
                  {/* {dropdownAbout && <DropdownAbout />} */}
                  <ul className={dropdown_about}>
                    <Link to="/about">
                      <li className="dropdown__menu__item">เกี่ยวกับ เทคแคร์ โซลูชั่น</li>
                    </Link>
                    <Link to="/about/history">
                      <li className="dropdown__menu__item">ประวัติความเป็นมา</li>
                    </Link>
                    <Link to="/about/vision">
                      <li className="dropdown__menu__item">วิสัยทัศน์องค์กร</li>
                    </Link>
                    <Link to="/about/symbol">
                      <li className="dropdown__menu__item">ความหมายของสัญลักษณ์องค์กร</li>
                    </Link>
                    <Link to="/about/Organization_manage">
                      <li className="dropdown__menu__item">การจัดการองค์กร</li>
                    </Link>
                  </ul> 
                </div>
              </li>

              <li className="nav-item"
                  onMouseEnter={onMouseEnterProduct}
                  onMouseLeave={onMouseLeaveProduct}
                  onClick={closeMobileMenu}
              >
              <div className="dropdown__menu">
                <NavLink to="/products" className='nav-links' onClick={closeMobileMenu}>
                    PRODUCTS
                </NavLink>
                {/* <i className='fa-solid fa-circle-chevron-down'/> */}
                {/* {dropdownProduct && <DropdownProduct />} */}
                <ul className={dropdown_product}>
                  <Link to="/products/pos">
                      <li className="dropdown__menu__item">OHO POS (Point Of Sale)</li>
                  </Link>
                  <Link to="/products/erp">
                      <li className="dropdown__menu__item">OHO ERP (Enterprise Resource Planning)</li>
                  </Link>
                  <Link to="/products/mrp">
                      <li className="dropdown__menu__item">OHO MRP (Material Requirement Planning)</li>
                  </Link> 
                  {/* <Link to="/services/hrm">
                      <li className="dropdown__menu__item">HRM (Human Resource Management)</li>
                  </Link>  */}
                  <Link to="/products/pms">
                      <li className="dropdown__menu__item">OHO PMS (Property Management System)</li>
                  </Link>
                  <Link to="/products/xcom">
                      <li className="dropdown__menu__item">OHO X-Commerce</li>
                  </Link>
                </ul>
              </div>
              </li>
              <li className="nav-item">
                <NavLink to="/promotion"
                className='nav-links'
                onClick={closeMobileMenu}
                >
                  PROMOTION
                </NavLink>

              </li>
              <li className="nav-item">
                <NavLink 
                  to="/news" 
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  NEWS
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink 
                  to="/manual404" 
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Manual
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink 
                  to="/career" 
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  CAREER
                </NavLink>
              </li>
              <li className="nav-item">
              <NavLink 
                to="/login"
                className='nav-links'
              >
                LOGIN
              </NavLink>
              </li>
            </ul>
          </div>
        </div>
    )
}

export default Navbar;

