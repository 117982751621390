import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'


 const MessageBox = (event, text) =>  {

  const swalWithBootstrapButtons = Swal.mixin({
    buttonsStyling: false,
  });

  if(event === 'warning'){
    swalWithBootstrapButtons.fire({
      title: "แจ้งเตือน",
      text: text,
      icon: "warning",
      confirmButtonText: "ตกลง",
    })
  } 

  if(event === 'error'){
    swalWithBootstrapButtons.fire({
      title: "แจ้งเตือน",
      text: text,
      icon: "error",
      confirmButtonText: "ตกลง",
    })
  }
  
  if(event === 'success'){
    swalWithBootstrapButtons.fire({
      title: "แจ้งเตือน",
      text: text,
      icon: "success",
      confirmButtonText: "ตกลง",
    })
  }

  if(event === 'info'){
    swalWithBootstrapButtons.fire({
      title: "แจ้งเตือน",
      text: text,
      icon: "info",
      confirmButtonText: "ตกลง",
    })
  }

  if(event === 'question'){
    swalWithBootstrapButtons.fire({
      title: "แจ้งเตือน",
      text: text,
      icon: "question",
      confirmButtonText: "ตกลง",
    })
  }
}

export default MessageBox;