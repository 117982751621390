import React, {useState, useEffect} from "react";
import {NavLink, Link} from "react-router-dom";
import './navbarAdmin.css';

import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

import Icon from '@mdi/react';
import { mdiAccountCircleOutline } from '@mdi/js';
import { mdiMenuOpen } from '@mdi/js';
import { mdiMenu } from '@mdi/js';
import { mdiFileChartOutline } from '@mdi/js';
import { mdiCogOutline } from '@mdi/js';
import { mdiAccountCogOutline } from '@mdi/js';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

import { urlApi } from '../../url_api/url_api';


import Tooltip from '@mui/material/Tooltip';


 export default  function NavbarAdmin(){
  //#region parameter
  const [clickMenuBar, setClickMenuBar] = useState(false);
  const [clickMenuBarSetting, setClickMenuBarSetting] = useState(false);
  const [liClick1, setLiClick1] = useState(false);
  const [liClick2, setLiClick2] = useState(false);
  const [liClick3, setLiClick3] = useState(false);
  const [liClick4, setLiClick4] = useState(false);
  const [liClick5, setLiClick5] = useState(false);
  const [setting, setSetting] = useState(false);
  const [nameBar, setNameBar] = useState("Admin");
  const [classNameMenu, setClassNameMenu] = useState("navAdmin");
  const [clickMenu, setClickMenu] = useState(false);
  const handleClick = () => setClickMenuBar(!clickMenuBar);
  //#endregion
  //#region useEffect
  useEffect(() => {
    console.log(clickMenu);
    if (clickMenu) {
      setClassNameMenu("navAdmin_click")
      localStorage.setItem('w_nav', true);
     
    }else{
      setClassNameMenu("navAdmin")
      localStorage.setItem('w_nav', false);
    }
  },[clickMenu])

  useEffect(() => {
    if (localStorage.getItem('userName') != null || localStorage.getItem('userName') != undefined) {
      setNameBar(localStorage.getItem('userName'));
    }
    handleliClick(window.location.pathname);
  }, [])

  useEffect(() => {
    setNameBar(localStorage.getItem('userName'));
  }, [nameBar])

  useEffect(() => {
    handleliClick(window.location.pathname);
  }, [window.location.pathname])
  //#endregion
  //#region Function
  function handleliClick(params) {
    if (params == 1 || window.location.pathname == '/homeadmin') {
      setLiClick1(true)
      setLiClick2(false)
      setLiClick3(false)
      setLiClick4(false)
      setLiClick5(false)
      setSetting(false);
      setClickMenu(true)
    }

    if (params == 2 || window.location.pathname == '/homeadmin/packagereport') {
      setLiClick1(false)
      setLiClick2(true)
      setLiClick3(false)
      setLiClick4(false)
      setLiClick5(false)
      setSetting(false);
      setClickMenu(true)
    }

    if (params == 3 || window.location.pathname == '/homeadmin/user') {
      setLiClick1(false)
      setLiClick2(false)
      setLiClick3(true)
      setLiClick4(false)
      setLiClick5(false)
      setSetting(false);
      setClickMenu(true)
    }

    if (params == 4 || window.location.pathname == '/homeadmin/sale') {
      setLiClick1(false)
      setLiClick2(false)
      setLiClick3(false)
      setLiClick4(true)
      setLiClick5(false)
      setSetting(false);
      setClickMenu(true)
    }
    if (params == 5 || window.location.pathname == '/homeadmin/setting') {
      setLiClick1(false);
      setLiClick2(false);
      setLiClick3(false);
      setLiClick4(false);
      setLiClick5(true);
      setSetting(true);
      setClickMenu(false);
    }
  }

  async function updateUserlogin(params) {
    var axios = require('axios');
    var data = JSON.stringify({
      "user_id": params,
      "status": "false"
    });
    
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: urlApi + '/login/update_status',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    await axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  }

function handleLogout() {
    const swalWithBootstrapButtons = Swal.mixin({
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
      .fire({
        title: "คุณต้องการออกจากระบบ ใช่ หรือ ไม่ ?",
        showCancelButton: true,
        icon: "question",
        confirmButtonText: "ออกจากระบบ",
        cancelButtonText: "ยกเลิก",
        reverseButtons: false,
        customClass: {
          icon: "no-before-icon",
          Button: "swal-btn",
          confirmButton: "confirmButton",
          cancelButton: "cancelButton",
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
            localStorage.setItem('userLogin', false);
            updateUserlogin(localStorage.userLogin_id)
            window.location.href = "/";
        } else if (
          /* Read more about handling dismissals below */
          
          result.dismiss === Swal.DismissReason.cancel
        ) {
          localStorage.setItem('userLogin', false);
        }
      });
}
//#endregion

    return (
        <div className={classNameMenu}>
            <div className="navAdmin_body">
                <div className="navAdmin_menu_bar">
                {!clickMenu ? 
                
                  <Icon color='var(--color--menu--item--hover)' className="icon_menuBar" path={mdiMenuOpen} size={1.5} onClick={() => {setClickMenu(!clickMenu);}} />
                : 
                
                  <Icon color='var(--color--menu--item--hover)' className="icon_menuBar" path={mdiMenu} size={1.5} onClick={() => {setClickMenu(!clickMenu)}} />
                }
                </div>
                {clickMenu ? <>
                  <div className="iconMenu">
                    <ul className="iconMenu_ul">
                      <Tooltip style={{cursor: 'pointer'}} title="ข้อมูลลูกค้า" placement="right">
                      <li className="iconMenu_li" onClick={() => {setClickMenu(!clickMenu)}}>
                          <Icon path={mdiAccountCircleOutline}
                            title="User Profile"
                            size={1.5}
                            horizontal
                            vertical
                            rotate={180}
                            color="var(--color--blue)"
                            style={{marginBottom: '5%'}}
                          />
                      </li>
                      </Tooltip>
                      <Tooltip style={{cursor: 'pointer'}} title="รายงานขายแพ็กเกจ" placement="right">
                        <li className="iconMenu_li" onClick={() => {setClickMenu(!clickMenu)}}>
                            <Icon className="navAdmin__menu__item__icon"  
                            path={mdiFileChartOutline} 
                            size={1.5}
                            color={'var(--color--menu--item--hover)'} />
                        </li>
                      </Tooltip>
                      <Tooltip style={{cursor: 'pointer'}} title="ตั้งค่า" placement="right">
                      <li className="iconMenu_li" onClick={() => {setClickMenu(!clickMenu)}}>
                        <Icon color={'var(--color--menu--item--hover)'} 
                        className="iconMenu_icon"  
                        path={mdiCogOutline} size={1.5} />
                      </li>
                      </Tooltip>
                      <Tooltip style={{cursor: 'pointer'}} title="จัดการผู้ใช้งาน" placement="right">
                        <li className="iconMenu_li" onClick={() => {setClickMenu(!clickMenu)}}>
                            <Icon className="iconMenu_icon"  
                            path={mdiAccountCogOutline} size={1.5}
                            color={'var(--color--menu--item--hover)'} />
                        </li>
                      </Tooltip>
                    </ul>
                  </div>
                </> : 
                <>
                <div className="navAdmin_menu" onClick={handleClick}>
                    <ul style={{width: '100%'}}  
                      className={clickMenuBar ? 
                      'navAdmin__menu_ul active':
                      'navAdmin__menu_ul'}>
                      
                      <Tooltip title="ข้อมูลลูกค้า" placement="right">
                      <NavLink to="/homeadmin">
                        <li onClick={() => {handleliClick(1)}} 
                        className={liClick1 ? "navAdmin__menu__item active" : 
                        "navAdmin__menu__item"}>
                        <Icon path={mdiAccountCircleOutline}
                            className="navAdmin__menu__item__icon"
                            title="User Profile"
                            size={1.5}
                            horizontal
                            vertical
                            rotate={180}
                            color="var(--color--blue)"
                            
                          />
                          <text className="navAdmin__menu__item__name">ข้อมูลลูกค้า</text>
                        </li>
                      </NavLink>
                      </Tooltip>
                      <Tooltip title="รายงานขายแพ็กเกจ" placement="right">
                      <NavLink to="/homeadmin/packagereport">
                        <li onClick={() => {handleliClick(2)}} className={liClick2 ? "navAdmin__menu__item active" : "navAdmin__menu__item"}>
                        <Icon className="navAdmin__menu__item__icon"  
                          path={mdiFileChartOutline} 
                          size={1.5}
                          color={'var(--color--menu--item--hover)'} />
                          
                          <text className="navAdmin__menu__item__name">รายงานขายแพ็กเกจ</text>
                        </li>
                      </NavLink>
                      </Tooltip>
                      <Tooltip title="ตั้งค่า" placement="right">
                        <li onClick={() => {handleliClick(5)}} 
                        className={liClick5 ? "navAdmin__menu__item active" : "navAdmin__menu__item"}>
                          <Icon color={'var(--color--menu--item--hover)'} 
                        className="iconMenu_icon"  
                        path={mdiCogOutline} size={1.5} />
                          <text className="navAdmin__menu__item__name">ตั้งค่า</text>
                        </li>
                      </Tooltip>
                      <NavLink to="/homeadmin/setting/addprogram">
                      <li className={setting  ? 'setting active' : 'setting'}><text>ตั้งค่าโปรแกรม</text></li>
                      </NavLink>
                      <li className={setting  ? 'setting active' : 'setting'}><text>ตั้งค่าแพ็คเกจ</text></li>
                      <li className={setting  ? 'setting active' : 'setting'}><text>ตั้งค่าประเภท</text></li>
                      <li className={setting  ? 'setting active' : 'setting'}><text>ตั้งค่าระยะเวลา</text></li>
                      <Tooltip title="จัดการผู้ใช้งาน" placement="right">
                      <NavLink to="/homeadmin/user">
                        <li onClick={() => {handleliClick(3)}} className={liClick3 ? "navAdmin__menu__item active" : "navAdmin__menu__item"}>
                        <Icon className="iconMenu_icon"  
                          path={mdiAccountCogOutline} size={1.5}
                          color={'var(--color--menu--item--hover)'} />
                          <text className="navAdmin__menu__item__name">จัดการผู้ใช้งาน</text>
                        </li>
                        </NavLink>
                      </Tooltip>
                    </ul>
                </div>
                </>
                }
            </div>
        </div>
    )
}