import React,{useState} from 'react'
import Button from '@mui/material/Button';
import { color } from '@mui/system';


function Buttons(props) {
  return (

        <Button 
          id='Buttons'
          variant="contained"
          fullWidth='true'
          onClick={() => props.onClick()}
          sx={{
            m:'2%'
          }}
          style={props.style}
          color={props.color}
        >{props.name} </Button>
  )
}

export default Buttons