import React from 'react'
import './OurFounder.css'
import ScrollToTop from '../../components/Scroll/ScrollToTop'


import img001 from './img/1.jpg';
import img002 from './img/2.jpg';
import img003 from './img/3.jpg';

export default function OurFounder() {
    return(
        <div className='founder'>
        <ScrollToTop />
            {/* <div className='founder-body'>
                <div className='founder-card'>
                    <text className='founder-card--text1'>OUR FOUNDER</text>
                    <div className='founder-card--text--name'>
                        <ul className='founder-card--text--line'/>
                        <ul className='founder-card--text'>
                            <text className='founder-card--text2'>Jiradech  Netawong</text>
                            <br/>
                            <text className='founder-card--text2'>คุณ จิรเดช  เนตรวงค์</text>
                            <br/>
                            <text className='founder-card--text2'>Chairman</text>
                        </ul>
                    </div>
                </div>
                <div className='founder-card--img'>
                    <ul>
                        <img src={img001} alt='' style={{width: 500, height: 700}} />
                    </ul>
                </div>
            </div>

            <div className='founder-body'>
                <div className='founder-card'>
                    <text className='founder-card--text1'>OUR FOUNDER</text>
                    <div className='founder-card--text--name'>
                        <ul className='founder-card--text--line'/>
                        <ul className='founder-card--text'>
                            <text className='founder-card--text2'>Vijitra  Palakavong Na Ayudhya</text>
                            <br/>
                            <text className='founder-card--text2'>คุณวิจิตรา  ปาลกะวงศ์ ณ อยุธยา</text>
                            <br/>
                            <text className='founder-card--text2'>Chief Executive Officer</text>
                        </ul>
                    </div>
                </div>
                <div className='founder-card--img'>
                    <ul>
                        <img src={img002} alt='' style={{width: 500, height: 700}} />
                    </ul>
                </div>
            </div>

            <div className='founder-body'>
                <div className='founder-card'>
                    <text className='founder-card--text1'>OUR FOUNDER</text>
                    <div className='founder-card--text--name'>
                        <ul className='founder-card--text--line'/>
                        <ul className='founder-card--text'>
                            <text className='founder-card--text2'>Aisara Weluwanarak</text>
                            <br/>
                            <text className='founder-card--text2'>คุณอิสระ เวฬุวนารักษ์</text>
                            <br/>
                            <text className='founder-card--text2'>Chief Information Officer</text>
                        </ul>
                    </div>
                </div>
                <div className='founder-card--img'>
                    <ul>
                        <img src={img003} alt='' style={{width: 500, height: 700}} />
                    </ul>
                </div>
            </div> */}
            <div className='founder-img'>
            {/* style={{width: '30%', height: '40%', marginRight: '5%' , marginTop: '5%'}} */}
                <img src={img001} alt="img01" />
                <img src={img002} alt="img02" />
                <img src={img003} alt="img03" />
            </div>
        </div>
    );
    
}