import React, {useEffect, useState} from 'react';
import './hrm.css';
import ScrollToTop from '../../../../components/Scroll/ScrollToTop'

export default function Hrm(){
    const [scrollX, setScrollX] = useState(0);
    const [scrollY, setScrollY] = useState(0);
    
    useEffect(() => {
        window.scrollTo(scrollX, scrollY)
      }, [])
    return(
        <div className='hrm'>
        <ScrollToTop/>
            <div className='hrm-header'>
                <ul className="vision--head box"></ul>
                <ul>OHO HRM (โปรแกรมบริหารงานบุคคล)</ul>
            </div>
            <div className='hrm-body'>
                <ul>
                    <p>ซอฟต์แวร์ OHO HRM โปรแกรมบริหารงานบุคคล เพื่อเพิ่มประสิทธิภาพในการนบริหารงานบุคคลให้รวดเร็ว ข้อมูลมีความกระชับและถูกต้องมากขึ้น กระบวนการทำงานต่างๆ จะถูกปรับเปลี่ยนโดยใช้ Process Automation ซึ่งเป็นความสามารถในการประมวลผลของคอมพิวเตอร์ ทำให้เราสามารถตรวจสอบกระบวนการทำงานต่าง ๆ ได้แบบ Real - Time และใช้ฐานข้อมูลร่วมกันได้</p>
                </ul>
                <br/>
                <br/>
                <ul>
                    <p>การสร้างระบบ มุ่งเน้น การนำระบบการแก้ไขปัญหาในการจัดการงานบุคลากรใน องค์กรขนาดใหญ่ โดยอาศัย ประสบการณ์ของผู้บริหาร การวางแผนในการสร้างระบบ เพื่อสร้างระบบคลังข้อมูลที่มีประสิทธิภาพ ในการค้นหาคนเก่งคนมีฝีมือมาร่วมงาน เพื่อแก้ไขจัดระบบระเบียบ สร้างความยุติธรรมในองค์กรในการการทำงานของพนักงาน สร้างแรงจูงใจในการทำงาน สร้างการพัฒนาทักษะในวิชาชีพของพนักงาน สร้างการทำงานเป็นทีม เพื่อพัฒนาองค์กรให้มีผลกำไรเพิ่มขึ้นตามเป้าหมายที่กำหนด</p>
                </ul>
                <br/>
                <br/>
                <ul>
                    <p>บริษัทให้บริการในการพัฒนาระบบงานด้วยทีมงานผู้มีประสบการณ์ ทั้งทางด้านเทคโนโลยีสารสนเทศ การจัดการและผู้เชี่ยวชาญ เพื่อแบ่งเบาภาระในการบริหารงาน การจัดการโครงงานและการพัฒนาระบบงานทางด้านเทคโนโลยีสารสนเทศของทางบริษัทผู้ใช้บริการ รวมถึงการดูแลรักษาระบบงานให้สามารถตอบสนองความต้องการของบริษัทผู้ใช้บริการ เพื่อเพิ่มศักยภาพในการแข่งขันทางธุรกิจ</p>
                </ul>
                <br/>
                <br/>
                <ul>
                    <p>การบริการพัฒนาระบบงานของทางบริษัทที่พร้อมให้บริการกับทางบริษัทผู้ใช้บริการ จะสามารถอำนวยประโยชน์ได้ดังนี้</p>
                    <li>ระบบงานสามารถส่งมอบตรงตามเวลาตามที่ทางบริษัทผู้ใช้บริการ ต้องการด้วยทีมงานคุณภาพของบริษัทที่พร้อมทุ่มเทให้บริการ เพื่อพัฒนาระบบงานให้สอดคล้องกับความต้องการในเวลาที่กำหนด และงบประมาณที่เหมาะสม</li>
                    <li>ระบบงานทันสมัยที่สามารถเชื่อมต่อกับ เทคโนโลยีปัจจุบันของบริษัทและรองรับเทคโนโลยีในอนาคต  เนื่องจากบริษัทมีทีมงานที่มีความรู้ความชำนาญในระบบงาน พร้อมทั้งมีความเชี่ยวชาญด้านเทคโนโลยีระบบเปิด ที่มีความคล่องตัว หลากหลาย และครอบคลุมโครงสร้าง ของเทคโนโลยีปัจจุบันและอนาคตได้เป็นอย่างดี</li>
                </ul>
            </div>
            <div className='hrm-bottom'>
            
            </div>
        </div>
    );
}