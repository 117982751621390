import React,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import './BarAdmin.css'
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { urlApi } from '../../url_api/url_api';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

function BarAdmin() {
  const handleSelect = (eventKey) => alert(`selected ${eventKey}`);
  const [w_nav, setW_nav] = useState('');

  useEffect(() => {
    if (localStorage.getItem('w_nav')) {
      setW_nav(localStorage.getItem('w_nav'));
      console.log('w_nav : ' + w_nav);
    }
  },[localStorage.getItem('w_nav')])

  useEffect(() => {
    if (localStorage.getItem('w_nav')) {
      setW_nav(localStorage.getItem('w_nav'));
      console.log('w_nav : '+ w_nav);
    }
  },[])


  async function updateUserlogin(params) {
    var axios = require('axios');
    var data = JSON.stringify({
      "user_id": params,
      "status": "false"
    });
    
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: urlApi + '/login/update_status',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    await axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  function handleLogout() {
    const swalWithBootstrapButtons = Swal.mixin({
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
      .fire({
        title: "คุณต้องการออกจากระบบ ใช่ หรือ ไม่ ?",
        showCancelButton: true,
        icon: "question",
        confirmButtonText: "ออกจากระบบ",
        cancelButtonText: "ยกเลิก",
        reverseButtons: false,
        customClass: {
          icon: "no-before-icon",
          Button: "swal-btn",
          confirmButton: "confirmButton",
          cancelButton: "cancelButton",
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
            localStorage.setItem('userLogin', false);
            updateUserlogin(localStorage.userLogin_id)
            window.location.href = "/";
        } else if (
          /* Read more about handling dismissals below */
          
          result.dismiss === Swal.DismissReason.cancel
        ) {
          localStorage.setItem('userLogin', false);
        }
      });
}

  return (
    <>
      {w_nav ? 
      <>
      <div className="Nav2">
        <div className='Nav_user'><AccountCircleOutlinedIcon color='var(--color--blue)' fontSize='large' /></div>
        <div className='Nav_user_Name'><label style={{paddingLeft: '5%'}}>{localStorage.getItem('userName')}</label></div>
        <div className='Nav_logout'><Button style={{borderRadius: '40px'}} onClick={() => {handleLogout()}} className='btn-new-border' variant="contained">Logout</Button></div>
      </div>
      </> :
      <>
        <div className="Nav1">
          <div className='Nav_user'><AccountCircleOutlinedIcon color='var(--color--blue)' fontSize='large' /></div>
          <div className='Nav_user_Name'><label style={{paddingLeft: '5%'}}>{localStorage.getItem('userName')}</label></div>
          <div className='Nav_logout'><Button style={{borderRadius: '40px'}} onClick={() => {handleLogout()}} className='btn-new-border' variant="contained">Logout</Button></div>
        </div>
      </>
      }
    </>
  )
}

export default BarAdmin