import React, {useEffect, useState} from 'react'
import './xcom.css'
import { Link } from 'react-router-dom';
import ScrollToTop from '../../../../components/Scroll/ScrollToTop'

import img_xcom from './img_xcom/OHO-xcom.jpg'

export default function Xcom(){

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
        <div className='xcom'>
        <ScrollToTop/>
            <div className='xcom-header'>
                <ul className="vision--head box"></ul>
                <ul>OHO X-Commerce</ul>
            </div>
            <div className='xcom-body'>
                <ul>
                    <p>ซอฟต์แวร์ X-COMMERCE โปรแกรมบริหารจัดการ ซื้อ-ขายออนไลน์  การพาณิชย์อิเล็กทรอนิกส์ เป็นการทำธุรกรรมซื้อขาย หรือแลกเปลี่ยนสินค้าและบริการบนอินเทอร์เน็ต โดยใช้เว็บไซต์หรือแอปพลิเคชันเป็นสื่อกลางใน การซื้อ - ขายออนไลน์ผ่านหน้าเว็บไซต์ หรือในการนำเสนอสินค้าและบริการต่างๆ รวมถึงการติดต่อกันระหว่างผู้ซื้อและผู้ขาย ทำให้ผู้เข้าใช้บริการจากทุกที่ทุกประเทศ หรือทุกมุมโลกสามารถเข้าถึงร้านค้าได้ง่ายและตลอด 24 ชั่วโมง</p>
                    <br/>
                    <p>บริษัทให้บริการในการพัฒนาระบบงานด้วยทีมงานผู้มีประสบการณ์ ทั้งทางด้านเทคโนโลยีสารสนเทศ การจัดการและผู้เชี่ยวชาญ เพื่อแบ่งเบาภาระในการบริหารงาน การจัดการโครงงานและการพัฒนาระบบงานทางด้านเทคโนโลยีสารสนเทศของทางบริษัทผู้ใช้บริการ รวมถึงการดูแลรักษาระบบงานให้สามารถตอบสนองความต้องการของบริษัทผู้ใช้บริการ เพื่อเพิ่มศักยภาพในการแข่งขันทางธุรกิจ</p>
                    <br/>
                    <p>การบริการพัฒนาระบบงานของทางบริษัทที่พร้อมให้บริการกับทางบริษัทผู้ใช้บริการ จะสามารถอำนวยประโยชน์ได้ดังนี้</p>
                    <li>ระบบงานสามารถส่งมอบตรงตามเวลาตามที่ทางบริษัทผู้ใช้บริการ ต้องการด้วยทีมงานคุณภาพของบริษัทที่พร้อมทุ่มเทให้บริการ เพื่อพัฒนาระบบงานให้สอดคล้องกับความต้องการในเวลาที่กำหนด และงบประมาณที่เหมาะสม</li>
                    <li>ระบบงานทันสมัยที่สามารถเชื่อมต่อกับ เทคโนโลยีปัจจุบันของบริษัทและรองรับเทคโนโลยีในอนาคต  เนื่องจากบริษัทมีทีมงานที่มีความรู้ความชำนาญในระบบงาน พร้อมทั้งมีความเชี่ยวชาญด้านเทคโนโลยีระบบเปิด ที่มีความคล่องตัว หลากหลาย และครอบคลุมโครงสร้าง ของเทคโนโลยีปัจจุบันและอนาคตได้เป็นอย่างดี</li>
                </ul>
            </div>
            <div className='xcom-header'>
                <ul className="vision--head box"></ul>
                <ul>เหตุผลที่ธุรกิจควรใช้ OHO X-COMMERCE SYSTEM</ul>
            </div>
            <div className='xcom-body'>
                <ul>1. O2O Marketing <br/>
                    <p>ธุรกิจยุคดิจิทัลในรูปแบบ Online to Offline ซึ่งยังคงนำมาใช้ต่อไปได้อย่างมีประสิทธิภาพในปี 2022 O2O Marketing เป็นการเพิ่มโอกาสให้ธุรกิจมีช่องทางจำหน่ายสินค้าที่ครอบคลุมผู้บริโภคกลุ่มเป้าหมายได้มากขึ้น แบรนด์ที่มีร้านค้าออฟไลน์จะมีความได้เปรียบด้านความน่าเชื่อถือ ซึ่งส่งผลโดยตรงต่อการตัดสินใจซื้อสินค้าภายในร้านค้าออนไลน์ โดยที่สามารถสร้างยอดขายที่เพิ่มขึ้นได้มากกว่าเท่าตัวในการตลาดยุคใหม่</p>
                </ul>
                <ul>2. Direct to Consumer (D2C)
                    <p>ยังคงเป็นกระแสหลักของตลาด E-commerce ปี 2022 ผลจากการเติบโตของเทคโนโลยี ทำให้แบรนด์สามารถเข้าถึงผู้บริโภคได้ง่ายดายมากยิ่งขึ้น ตัดคนกลางออกไปทำให้ได้รับประโยชน์ที่เพิ่มขึ้นทั้งผู้บริโภคที่มีโอกาสได้ของดีราคาถูกลงและแบรนด์ที่มีโอกาสลดต้นทุนเพิ่มกำไรไปพัฒนาสินค้าให้ดียิ่งขึ้นได้</p>
                </ul>
                <ul>3. Crypto Currency / โลกเสมือน / Metaverse
                    <p>เป็นโอกาสที่ควรลงทุนเรียนรู้ให้เข้าใจในปี 2022 นี้ ไม่นานที่เรื่องราวเหล่านี้จะกลายเป็นกระแสหลัก โลกเสมือนของ Meta (Facebook เดิม) ก็มีโอกาสสูงมากที่จะประสบความสำเร็จเนื่องจากผู้ใช้งาน Facebook เดิมก็เกือบครึ่งนึงของประชากรโลก อีกทั้งยังมีความเก่งในการสร้าง Community และ Network ดังนั้นสิ่งสำคัญของ SME ในปี 2022 คือลงทุนกับความรู้โลกแห่งอนาคต</p>
                </ul>
            </div>
            <div className='xcom-bottom'>
                <img src={img_xcom} alt='xcpm'/>
                <Link to='/products/xcom/buy'>
                <button className="btn--package">คลิ๊ก! สั่งซื้อผลิตภัณฑ์</button>
                </Link>
            </div>
        </div>
    )
}