import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import './services.css';

import Button from '@mui/material/Button';


import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

import Swal from 'sweetalert2';
import ScrollToTop from '../../components/Scroll/ScrollToTop'


// import WebIcon from '@mui/icons-material/Web';
// import WarehouseIcon from '@mui/icons-material/Warehouse';


export default function Services () {
    const handleMessage = (texts) => {
        // Swal.fire(texts);
        Swal.fire({
          title: 'แจ้งเตือน',
          text: texts,
          icon: "warning",
          confirmButtonText: 'OK',
        })
      };

  const [slides, setSlides] = useState(3)
  const [sizeDisplay, setSizeDisplay] =  useState();


      //choose the screen size 
    const handleResize = () => {
      if (window.innerWidth < 960) {
          // setIsMobile(true)
          setSlides(1)
      } else {
          // setIsMobile(false)
          setSlides(3)
      }
    }
    
    // create an event listener
    useEffect(() => {
      window.addEventListener("resize", handleResize)
      setSizeDisplay(window.innerWidth)
      window.scrollTo(0, 0)
    })

    useEffect(() => {
      handleResize();
    }, [sizeDisplay])

    return (
        <div className="service">
        <ScrollToTop/>
          <div className="service__head">
            <text className="service--h1">Our Products</text>
            <br/><br/>
            <div className="text__p">
              <text className="text__p_p1">ซอฟต์แวร์ที่เติมเต็มและติดปีกธุรกิจของคุณด้วยสุดยอดเครื่องมือในการดำเนินธุรกิจ Business Solution Software 
              ผ่านการเชื่อมโยงประสบการณ์จริงและเทคโนโลยีอันทันสมัยเพื่อตอบสนองต่อความพึงพอใจสูงสุดของผู้ประกอบการด้วยโปรแกรมสำเร็จรูปภายใต้ชื่อแบรนด์ OHO
              </text>
            </div>
          </div>
          <div className="service__body">
            <div className="service--item">
              <Swiper
                spaceBetween={30}
                slidesPerView={slides}
                centeredSlides={false}
                autoplay={{
                  delay: 6000,
                  disableOnInteraction: false,
                }}
                  pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                <div className="card--item">
                  <i class="fa-solid fa-cash-register"></i>
                  <div className="text--item1"><text>OHO</text> POS</div>
                  <div className="text--item2">โปรแกรมการขายหน้าร้าน</div>
                  <Link to="/products/pos">
                  <Button style={{
                    width: '7rem',
                    height: '3rem',
                    fontSize: '0.8rem'
                  }} variant="contained" className="btn">Learn More</Button>
                  </Link>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card--item">
                    <i class="fa-solid fa-store"></i>
                    <div className="text--item1"><text>OHO</text> ERP</div>
                    <div className="text--item2">โปรแกรมการวางแผนการจัดการธุรกิจ</div>
                    <Link to="/products/erp">
                    <Button style={{
                      width: '7rem',
                      height: '3rem',
                      fontSize: '0.8rem'
                    }} variant="contained" className="btn">Learn More</Button>

                    </Link>
                    
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card--item">
                    {/* <WarehouseIcon fontSize="large"/> */}
                    <i class="fa-solid fa-city"></i>
                    <div className="text--item1"><text>OHO</text> MRP</div>
                    <div className="text--item2">โปรแกรมวางแผนการผลิต</div>
                    <Link to="/products/mrp">
                    <Button style={{
                      width: '7rem',
                      height: '3rem',
                      fontSize: '0.8rem'
                    }} variant="contained" className="btn">Learn More</Button>

                    </Link>
                    
                  </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                  <div className="card--item">
                    <i class="fa-solid fa-people-roof"></i>
                    <div className="text--item1"><text>OHO</text> HRM</div>
                    <div className="text--item2">โปรแกรมบริหารงานบุคคล</div>
                    <Link to="/products/hrm">
                    <Button style={{
                      width: '7rem',
                      height: '3rem',
                      fontSize: '0.8rem'
                      }} variant="contained" className="btn">Learn More</Button>

                    </Link>
                  </div>
                </SwiperSlide> */}
                
                <SwiperSlide>
                  <div className="card--item">
                    {/* <WebIcon className="fa-browser" fontSize="large" /> */}
                    <i class="fa-solid fa-desktop"></i>
                    <div className="text--item1"><text>OHO</text> PMS</div>
                    <div className="text--item2">โปรแกรมบริหารจัดการโรงแรมและรีสอร์ท</div>
                    {/* <Button variant="outlined" className="btn" onClick={() => handleMessage('ไม่พบข้อมูล')}>Learn More</Button> */}
                    <Link to="/products/pms">
                    <Button style={{
                      width: '7rem',
                      height: '3rem',
                      fontSize: '0.8rem'
                      }} variant="contained" className="btn">Learn More</Button>

                    </Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card--item">
                    <i class="fa-solid fa-laptop-code"></i>
                    <div className="text--item1"><text>OHO</text> X-COMMERCE</div>
                    <div className="text--item2">โปรแกรมบริหารจัดการ ซื้อ-ขายออนไลน์</div>
                    {/* <Button color="primary" variant="outlined" className="btn" onClick={() => handleMessage('ไม่พบข้อมูล')}>Learn More</Button> */}
                    <Link to="/products/xcom">
                    <Button style={{
                      width: '7rem',
                      height: '3rem',
                      fontSize: '0.8rem'
                    }} variant="contained" className="btn">Learn More</Button>
                    </Link>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
      </div>
    );
}