import React, {useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom';
import './SaleSetTing.css';
import TextField from '@mui/material/TextField';
import Moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js'

import {urlApi} from "../../../url_api/url_api";
import checkNumber from '../../../components/checkInput/checkNumber';
import CheckEmail from '../../../components/checkInput/checkEmail';
import  Button  from '../../../components/Button/Button';

import mss from '../../../components/message/messagebox'

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function SaleSetting() {
  let { packageID } = useParams();
  const [programData, setProgramData] = useState([]);
  const [packageHdData, setPackageHdData] = useState([]);
  const [packageTypeData, setPackageTypeData] = useState([]);
  const [packageDtData, setPackageDtData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const [programId, setProgramId] = useState(0);
  const [sale_packageID, setSale_packageID] = useState(0);
  const [hdID, setHdID] = useState(0);
  const [typeId, setTypeId] = useState(0);
  const [dtId, setDtId] = useState(0);
  const [statusID, setStatusID] = useState(0);
  
  const [docuno, setDocuno] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [companyCount, setCompanyCount] = useState("");
  const [cusName, setCusName] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [taxid, setTaxid] = useState("");
  const [remark, setRemark] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [countDate, setCountDate] = useState(0);

  const CHARACTER_LIMIT = 10;
  Moment.locale('th');

  //#region  useEffect
  useEffect(() => {
    getProgramData();
    getStatus();
    getPackageDocuno();
  }, [])

  useEffect(() => {
    if (startDate != null) {
      getEndDate();
    }
  }, [startDate, countDate])

  useEffect(() => {
    if (dtId != 0) {
      getPackageDT();
    }
  }, [dtId])
  
  useEffect(() => {
    setSale_packageID(packageID);
    if(packageID != 0 && sale_packageID != 0){
      
    }
  }, [packageID, sale_packageID])

  useEffect(() => {
    if (programData.length < 0) {
      getProgramData();
    }
  }, [programData])

  useEffect(() => {
    getPackageHDData();
    setPackageTypeData([]);
  }, [programId])

  useEffect(() => {
    getTypeData();
    setPackageDtData([]);
  }, [hdID])

  useEffect(() => {
    getDtData();
  }, [typeId])

  //#endregion


  //#region Function
  const handleChangeProgram = (event) => {
    setProgramId(event.target.value);
  }; 
  const handleChangeHD = (event) => {
    setHdID(event.target.value);
  }; 
  const handleChangeTYPE = (event) => {
    setTypeId(event.target.value);
  };  
  const handleChangeDt = (event) => {
    setDtId(event.target.value);
  };
  const handleChangeStatus = (event) => {
    setStatusID(event.target.value);
  };
  function clearData() {
    setProgramId(0);
    setSale_packageID(0);
    setHdID(0);
    setTypeId(0);
    setDtId(0);

    setCompanyName("");
    setCompanyType("");
    setCompanyCount("");
    setCusName("");
    setTel("");
    setEmail("");
    setAddress("");
    setTaxid("");
    setRemark("");
    setStartDate(null);
    setEndDate(null);
    setCountDate(0);

    setProgramData([]);
    setPackageHdData([]);
    setPackageTypeData([]);
    setPackageDtData([]);
  }
  function cancleClick() {
    window.location.href = '/homeadmin/packagereport';
  }

  function CheckData() {
    if (programId == 0) {
      mss("warning","กรุณาเลือก โปรแกรม")
      return false
    }else if (hdID == 0) {
      mss("warning","กรุณาเลือก แพ็คเกจ")
      return false

    }else if(typeId == 0){
      mss("warning","กรุณาเลือก ประเภท")
      return false

    }else if(dtId == 0){
      mss("warning","กรุณาเลือก ระยะเวลา")
      return false
    }else if(statusID == 0){
      mss("warning","กรุณาเลือก สถานะ")
      return false
    }else if(startDate == null){
      mss("warning","กรุณาเลือก วันที่เริ่ม")
      return false
    }else if(endDate == null){
      mss("warning","กรุณาเลือก วันที่สิ้นสุด")
      return false
    }else if(companyName == ""){
      mss("warning","กรุณากรอก ชื่อบริษัท/ร้านค้า")
      return false
    }else if(companyType == ""){
      mss("warning","กรุณากรอก ประเภทธุรกิจ")
      return false
    }else if(companyCount == ""){
      mss("warning","กรุณากรอก จำนวนสาขา")
      return false
    }else if(cusName == ""){
      mss("warning","กรุณากรอก ชื่อลูกค้า")
      return false
    }else if(tel == ""){
      mss("warning","กรุณากรอก เบอร์โทรศัพท์")
      return false
    }else if(email == ""){
      mss("warning","กรุณากรอก อีเมล")
      return false
    }else if(address == ""){
      mss("warning","กรุณากรอก ที่อยู่")
      return false
    }else if(taxid == ""){
      mss("warning","กรุณากรอก เลขที่ผู้เสียภาษี")
      return false
    }else {
      return true
    }
    
  }
  function handleSaveData() {

    if (CheckData()) {
      const swalWithBootstrapButtons = Swal.mixin({
      
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
      .fire({
      title: "คุณต้องการบันทึก ใช่ หรือ ไม่ ?",
      showCancelButton: true,
      icon: "question",
      confirmButtonText: "บันทึก",
      cancelButtonText: "ยกเลิก",
      reverseButtons: false,
      customClass: {
        icon: "no-before-icon",
        Button: "swal-btn",
        confirmButton: "confirmButton",
        cancelButton: "cancelButton",
      },
  })
  .then((result) => {
    if (result.isConfirmed) {
      const swalWithBootstrapButtons = Swal.mixin({
      
        buttonsStyling: false,
      });
      if(InsertData()){
          swalWithBootstrapButtons.fire(
            "SUCCESS",
            "บันทึกสำเร็จ!"
          );
        clearData()
      }else{
        swalWithBootstrapButtons.fire(
          "ERROR",
          "บันทึกไม่สำเร็จ! (ลองบันทึกอีกครั้ง)"
        );
      }
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) 
    {
    //   swalWithBootstrapButtons.fire(
    //     ""
    //   );
    }
  });
    }
    
  }
  async function InsertData() {
    if (sale_packageID == 0) {
      try {
        var axios = require('axios');
        const dateNow = Date.now();
        const data = {
           "contact_package_docuno": docuno,
           "contact_package_docudate": Moment(dateNow).format('YYYY-MM-DD'),
           "contact_package_name_res": companyName,
           "contact_package_branch_count": companyCount,
           "contact_package_type_res": companyType,
           "contact_package_name_cus": cusName,
           "contact_package_phone_cus": tel,
           "contact_package_email_cus": email,
           "master_program_id": programId,
           "master_package_hd_id": hdID,
           "master_package_type_id": typeId,
           "master_package_dt_id": dtId,
           "start_date_package": Moment(startDate).format('YYYY-MM-DD'),
           "end_date_package": Moment(endDate).format('YYYY-MM-DD'),
           "contact_package_status_id": statusID,
           "contact_package_remark": remark,
           "taxid": taxid,
        }
  
        var config = {
          method: 'post',
          url: urlApi + '/package/insert',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        await axios(config)
        .then(function (response) {
          if(response.data == 1){
            return true
          }else{
            return false
          }
        })
        .catch((err) => {
           // handle errors
           console.log(err);
        return false
        })
        
      } catch (error) {
        return false
      }
    }else{
      try {
        var axios2 = require('axios');
        const dateNow = Date.now();
        const data = {
           "contact_package_docuno": docuno,
           "contact_package_docudate": Moment(dateNow).format('YYYY-MM-DD'),
           "contact_package_name_res": companyName,
           "contact_package_branch_count": companyCount,
           "contact_package_type_res": companyType,
           "contact_package_name_cus": cusName,
           "contact_package_phone_cus": tel,
           "contact_package_email_cus": email,
           "master_program_id": programId,
           "master_package_hd_id": hdID,
           "master_package_type_id": typeId,
           "master_package_dt_id": dtId,
           "start_date_package": Moment(startDate).format('YYYY-MM-DD'),
           "end_date_package": Moment(endDate).format('YYYY-MM-DD'),
           "contact_package_status_id": statusID,
           "contact_package_remark": remark,
           "taxid": taxid,
           "contact_package_id": sale_packageID,
        }
  
        var config2 = {
          method: 'post',
          url: urlApi + '/package/update',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        await axios2(config2)
        .then(function (response) {
          if(response.data == 1){
            return true
          }else{
            return false
          }
        })
        .catch((err) => {
           // handle errors
           console.log(err);
        return false
        })
        
      } catch (error) {
        return false
      }
    }
  }
  //#endregion
  
//#region Get Data 

async function  getPackageDT() {
  if (dtId != 0) {
    try {
      var axios = require('axios');
      const data = {
        "_dt_id": dtId
      }
  
      let url = urlApi + "/packdt/id";
  
      await axios.post(url, data)
      .then((res) => {
        // handle success
        if (res.data.length > 0) {
          setCountDate(res.data[0].count_date);
        } else {
          setCountDate(0);
        }
      })
      .catch((err) => {
         // handle errors
         console.log(err);
      })
      
    } catch (error) {
      console.log(error);
      return;
    }
  }
}
async function getEndDate() {
  var axios = require('axios');
  var data = JSON.stringify({
    "startDate": startDate,
    "count": countDate
  });

  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: urlApi +'/contact/get_endDate',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data[0].end_date));
    setEndDate(response.data[0].end_date);
  })
  .catch(function (error) {
    console.log(error);
  });

}
async function getProgramData() {
  try {
    var axios = require('axios');
    var data = JSON.stringify({});

    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: urlApi +'/masterProgram/all_status',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

   await axios(config)
    .then(function (response) {
      setProgramData(response.data);
    })
    .catch(function (error) {
      console.log(error);
      return
    });
    
  } catch (error) {
    console.log(error);
    return
  }
}
async function getPackageHDData() {
 if (programId == 0) {
  return
 }
  var axios = require('axios');
  var data = JSON.stringify({
    "master_program_id": programId,
    "master_package_hd_id": "0"
  });

  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: urlApi + '/packhd/getData',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };    

  await axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
    setPackageHdData(response.data)
  })
  .catch(function (error) {
    console.log(error);
  });
}
async function getTypeData() {

  if (hdID == 0) {
    return
  }
  try {
    var axios = require('axios');
    var data = JSON.stringify({
      "master_program_id": "0",
      "master_package_hd_id": hdID,
      "master_package_type_id": "0",
      "master_package_status": "2"
    });

    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: urlApi + '/packtype/get_data_fn',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      setPackageTypeData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

  } catch (error) {
    console.log(error);
  }
}
async function getDtData() {
  if (typeId == 0) {
    return
  }
  try {
    var axios = require('axios');
    var data = JSON.stringify({
      "master_program_id": programId,
      "master_package_hd_id": hdID,
      "master_package_type_id": typeId,
      "master_package_dt_id": "0",
      "master_package_status": "0"
    });

    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: urlApi + '/packdt/get_dt_fn',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      setPackageDtData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

  } catch (error) {
    console.log(error);
  }
}
async function getStatus() {
  try {
    var axios = require('axios');
    var data = JSON.stringify({
      
    });

    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: urlApi + '/package/get_status_package',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      setStatusData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

  } catch (error) {
    console.log(error);
  }
}
const getPackageDocuno = async () => {
  var axios = require('axios');
  var data = '';

  var config = {
    method: 'post',
    url: urlApi + '/contact/get_package_docuno',
    headers: { },
    data : data
  };

  await axios(config)
  .then(function (response) {
    setDocuno(response.data[0].contact_package_docuno);
  }).catch(function (error) {
    console.log(error);
  });
}
//#endregion 

  return (
    <>
        <div className='salesetting'>
          <div className='sale_body'>
            <div className='sale_package'>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <label>ชื่อบริษัท/ร้านค้า : </label>
                  <TextField
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    displayEmpty
                    style={{width: '70%', height: '70%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}} 
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                  </TextField>
              </div>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <label>ประเภทธุรกิจ : </label>
                  <TextField
                    value={companyType}
                    onChange={(e) => setCompanyType(e.target.value)}
                    displayEmpty
                    style={{width: '70%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}} 
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                  </TextField>
              </div>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <label>จำนวนสาขา : </label>
                  <TextField
                    value={companyCount}
                    onChange={(e) => setCompanyCount(checkNumber(e.target.value))}
                    displayEmpty
                    style={{width: '70%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}} 
                    inputProps={{ 'aria-label': 'Without label',
                    maxlength: 2,
                    }}
                  >
                  </TextField>
              </div>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <label>ชื่อลูกค้า : </label>
                  <TextField
                    value={cusName}
                    onChange={(e) => setCusName(e.target.value)}
                    displayEmpty
                    style={{width: '70%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}} 
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                  </TextField>
              </div>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <label>เบอร์โทรศัพท์ : </label>
                  <TextField
                    value={tel}
                    onChange={(e) => setTel(checkNumber(e.target.value))}
                    displayEmpty
                    style={{width: '70%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}} 
                    inputProps={{ 'aria-label': 'Without label',
                    maxlength: CHARACTER_LIMIT,
                     }}
                  >
                  </TextField>
              </div>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <label>อีเมล : </label>
                  <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    displayEmpty
                    style={{width: '70%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}} 
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                  </TextField>
              </div>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <label>ที่อยู่ : </label>
                  <TextField
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    displayEmpty
                    style={{width: '70%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}} 
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                  </TextField>
              </div>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <label>เลขที่ผู้เสียภาษี : </label>
                  <TextField
                    value={taxid}
                    onChange={(e) => setTaxid(checkNumber(e.target.value))}
                    displayEmpty
                    style={{width: '70%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}} 
                    inputProps={{ 'aria-label': 'Without label',
                    maxlength: 13 }}
                  >
                  </TextField>
              </div>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <label>หมายเหตุ : </label>
                  <TextField
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                    displayEmpty
                    style={{width: '70%', fontFamily: "var(----Font-family)", fontSize: '1.3rem'}} 
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                  </TextField>
              </div>
            </div>
            <div className='sale_customer'>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <label>เลขที่เอกสาร : </label>
                  <TextField
                    value={docuno}
                    displayEmpty
                    disabled
                    style={{width: '80%', fontFamily: "var(--Font-family)", fontSize: '1.3rem'}} 
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                  </TextField>
              </div>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <label>โปรแกรม : </label>
                <Select
                  value={programId}
                  key={programId}
                  onChange={handleChangeProgram}
                  displayEmpty
                  style={{width: '80%', fontFamily: "var(--Font-family)", fontSize: '1.3rem'}} 
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                {programData.map(({master_program_id,master_program_code}) => {
                  return (<MenuItem style={{fontFamily: "var(--Font-family)", fontSize: '1.5rem'}} key={master_program_id} value={master_program_id}>{master_program_code}</MenuItem>)
                })}
                </Select>
              </div>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <label>แพ็คเกจ : </label>
                <Select
                  value={hdID}
                  key={hdID}
                  onChange={handleChangeHD}
                  displayEmpty
                  style={{width: '80%', fontFamily: "var(--Font-family)", fontSize: '1.3rem'}} 
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                {packageHdData.map(({master_package_hd_id,master_package_hd_code}) => {
                  return (<MenuItem style={{fontFamily: "var(--Font-family)", fontSize: '1.5rem'}} key={master_package_hd_id} value={master_package_hd_id}>{master_package_hd_code}</MenuItem>)
                })}
                </Select>
              </div>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <label>ประเภท : </label>
                <Select
                  value={typeId}
                  key={typeId}
                  onChange={handleChangeTYPE}
                  displayEmpty
                  style={{width: '80%', fontFamily: "var(--Font-family)", fontSize: '1.3rem'}} 
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                {packageTypeData.map(({master_package_type_id,master_package_type_name}) => {
                  return (<MenuItem style={{fontFamily: "var(----Font-family)", fontSize: '1.5rem'}} key={master_package_type_id} value={master_package_type_id}>{master_package_type_name}</MenuItem>)
                })}
                </Select>
              </div>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <label>ระยะเวลา : </label>
                <Select
                  value={dtId}
                  key={dtId}
                  onChange={handleChangeDt}
                  displayEmpty
                  style={{width: '80%', fontFamily: "var(--Font-family)", fontSize: '1.3rem'}} 
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                {packageDtData.map(({master_package_dt_id,master_package_dt_name}) => {
                  return (<MenuItem style={{fontFamily: "var(--Font-family)", fontSize: '1.5rem'}} key={master_package_dt_id} value={master_package_dt_id}>{master_package_dt_name}</MenuItem>)
                })}
                </Select>
              </div>
              <div style={{width: '90%', height: '5rem', marginLeft: "2%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <label>สถานะ : </label>
                <Select
                  value={statusID}
                  key={statusID}
                  onChange={handleChangeStatus}
                  displayEmpty
                  style={{width: '80%', fontFamily: "var(--Font-family)", fontSize: '1.3rem'}} 
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                {statusData.map(({contact_package_status_id,contact_package_status_name}) => {
                  return (<MenuItem style={{fontFamily: "var(--Font-family)", fontSize: '1.5rem'}} key={contact_package_status_id} value={contact_package_status_id}>{contact_package_status_name}</MenuItem>)
                })}
                </Select>
              </div>
              <div style={{width: '100%', height: '5rem', marginLeft: "1%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}> 
            <label>วันที่เริ่ม : </label>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
              <DatePicker style={{marginTop: '10%'}} className='HomeManage_body_grad_body_input'
                openTo="day"
                views={['year', 'month', 'day']}
                value={startDate}
                onChange={(newValue) => {
                  // setStartDate(newValue.$y +'-'+ newValue.$M + '-' + newValue.$D);
                  setStartDate(Moment(newValue).format('YYYY-MM-DD'));
                  console.log(startDate);
                }}
                renderInput={(params) => 
                  <TextField {...params} />
                }
                inputFormat="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </div>
          <div style={{width: '100%', height: '5rem', marginLeft: "1%", display: 'flex', justifyContent: 'start', alignItems: 'center' }}> 
            <label>วันที่สิ้นสุด : </label>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DatePicker style={{marginTop: '10%'}} className='HomeManage_body_grad_body_input'
                openTo="day"
                views={['year', 'month', 'day']}
                value={endDate}
                onChange={(newValue) => {
                  // setEndDate(newValue.$y +'-'+ newValue.$M + '-' + newValue.$D);
                  setEndDate(Moment(newValue).format('YYYY-MM-DD'));
                }}
                // disabled
                renderInput={(params) => 
                  <TextField {...params} />
                }
                inputFormat="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </div>
              <div style={{
                width: '74%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                marginTop: '1rem',
                marginLeft: '20%'
              }}>
                <Button style={{backgroundColor: '', borderRadius: '40px', height: '3.5rem'}} name="new" onClick={() => clearData()}/>
                <Button style={{backgroundColor: '', borderRadius: '40px', height: '3.5rem'}} name="save" onClick={() => handleSaveData()}/>
                <Button style={{backgroundColor: '', borderRadius: '40px', height: '3.5rem'}} name="cancel" onClick={() => {cancleClick()}} />
              </div>
            </div>
          </div>
        </div>
    </>
    

  )
}

export default SaleSetting