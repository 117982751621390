import React,{useEffect} from "react";
import './about.css';
import about_img from "../home/image/About01.jpg";

import Resume from "./resume";
import Organi from "./organization_manage";
import Symbol from "./symbol";
import Vision from "./vision";

import ScrollToTop from '../../components/Scroll/ScrollToTop'


function About() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    return (
      <div className="about">
      <ScrollToTop />
        <div className="about-header">
          <div className="about-header--image">
            <div className="about-header--image__image">
              <img src={about_img} alt="about--image"/>
            </div>
          </div>
          <div className="about--text">
            <div className="about--text--h2">
            About Us
            </div>
            <div className="about--text--p2">
              <p>บริษัท เทค แคร์ โซลูชั่น จำกัด ดำเนินธุรกิจด้านซอฟต์แวร์สำเร็จรูปและสารสนเทศ
                ตั้งแต่ปี 2565 ในชื่อผลิตภัณฑ์ OHO โดยเริ่มจากการพัฒนาซอฟต์แวร์ POS, ERP, MRP, HRM, Web Design 
                สำหรับทุกธุรกิจ ตั้งแต่ปี 2564 เราได้พัฒนาซอฟต์แวร์ให้ตอบโจทย์การใช้งาน 
                และ เหมาะสมกับธุรกิจ ผ่านการเชื่อมโยงประสบการณ์จริงและเทคโนโลยีอันทันสมัย 
                จนนำมาซึ่งความพึงพอใจของผู้ใช้งานและผู้บริหาร
              </p>
            </div>
          </div>
        </div>
          <div className="about--body">
            <div className="about--resume">
              <Resume/>
            </div>
            <div className="about--vision">
              <Vision/>
            </div>
            <div className="about--symbol">
              <Symbol/>
            </div>
            <div className="about--organi">
              <Organi/>
            </div>
          </div>
        </div>
    );
  }
  export default About;