import React, {useEffect, useState} from "react";
import * as loadingData from "../../components/Loading/loading.json";
import * as successData from "../../components/Loading/success.json";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import './news.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { News_img } from "./news_image";
import ScrollToTop from '../../components/Scroll/ScrollToTop'

// import Loading from "../../components/Loading/Loading";



function News() {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: successData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  const [images, setImages] = useState([]);

  // const [loading, setLoading] = useState(false);

  
  useEffect(() => {
     setImages(News_img)
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setTimeout(() => {
          fetch("https://jsonplaceholder.typicode.com/posts")
            .then(response => response.json())
            .then(json => {
              setLoading(true);
              setTimeout(() => {
                setSuccess(true);
              }, 1500);
            });
        }, 3000);
      }, [loading]);
  

    return (
      <div className="loading-header">
      <ScrollToTop/>
      {!success ? (
        <FadeIn>
          <div style={{ display: "flex", flexDirection: 'column' }} className="loading-dody">
            {/* <text>Loading...</text> */}
            {!loading ? (
              <Lottie options={defaultOptions} height={1000} width={1000} />
            ) : (
              <Lottie options={defaultOptions2} height={300} width={300} />
            )}
          </div>
        </FadeIn>
      ) : 
      (
        <div className="news">
        {/* {!loading && <Loading />} */}
          <div className="news_header">
            <ul>News & Activity</ul>
          </div>
          <div className="news_body">
            {images.map((image) => {
              return (
                <div className="item_img">
                  <LazyLoadImage
                    effect="blur"
                    src={image.url}
                    alt={image.alt}
                    key={image.id}
                    height="auto"
                    width="auto"
                  />
                </div>
              )
            })}
          </div>
          <div className="news_footer">

          </div>
        </div>
      )}
      </div>
    );
  }
  export default News;