import React, {useState, useEffect} from 'react'
import './xcom_buy.css'
import { urlApi } from "../../../../url_api/url_api";

import MessageBox from '../../../../components/message/messagebox';
import checkNumber from '../../../../components/checkInput/checkNumber';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Iframe from 'react-iframe';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';


import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'


export default function Pms_Buy() {
  const CHARACTER_LIMIT = 10;
  const [fullName, setFullName] = useState('');
  const [tel, setTel] = useState('');
  const [storetype, setStoretype] = useState('');
  const [eMail, setEmail] = useState('');
  const [remark, setRemark] = useState('');

  const [saveStatus, setSaveStatus] = useState(false);

  const [textcheckfullName , setTextcheckfullName] = useState(false);
  const [textcheckTel , setTextcheckTel] = useState(false);
  const [textcheckEmail , setTextcheckEmail] = useState(false);
  const [textcheckType , setTextcheckType] = useState(false);

  const handleChangeStoreType = (event) => {
    setStoretype(event.target.value);
  };

  function handleClearData(){
    setFullName('');
    setTel('');
    setStoretype('');
    setEmail('');
    setRemark('');

    setSaveStatus(false);
  }

  function handleCheckData(){
    if(fullName === ''){
      MessageBox('warning','กรุณากรอก ชื่อ - นามสกุล');
      setTextcheckfullName(true);
      return;
    }else{
      setTextcheckfullName(false);
    }
    if(tel === '')
    {
      MessageBox('warning','กรุณากรอก เบอร์โทรศัพท์');
      setTextcheckTel(true);
      return;
    }else{
      setTextcheckTel(false);
    }
    if(storetype === '')
    {
      MessageBox('warning','กรุณาเลือก ประเภทร้าน');
      setTextcheckType(true);
      return;
    }else{
      setTextcheckType(false);
    }
    if(eMail === '')
    {
      MessageBox('warning','กรุณากรอก อีเมล');
      setTextcheckEmail(true);
      return;
    }else{
      setTextcheckEmail(false);
    }
    return true;
  }

  function handleSubmit(){
    const swalWithBootstrapButtons = Swal.mixin({
      
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
      .fire({
        title: "คุณต้องการบันทึก ใช่ หรือ ไม่ ?",
        showCancelButton: true,
        icon: "question",
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
        reverseButtons: false,
        customClass: {
          icon: "no-before-icon",
          Button: "swal-btn",
          confirmButton: "confirmButton",
          cancelButton: "cancelButton",
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
            
          if (handleCheckData()) {
            handleInsert();
            if(saveStatus === true){
              swalWithBootstrapButtons.fire(
                "บันทึกสำเร็จ!",
                "SUCCESS"
              );
              handleClearData();
            }else{
              swalWithBootstrapButtons.fire(
                "บันทึกไม่สำเร็จ!",
                "ERROR"
              );
            }
          }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) 
        {
        //   swalWithBootstrapButtons.fire(
        //     ""
        //   );
        }
      });
  }

  function handleInsert() {
    var axios = require('axios');
    var data = JSON.stringify({
      "res_name": "",
      "res_type": storetype,
      "res_count": "0",
      "cus_name": fullName,
      "cus_phone": tel,
      "cus_email": eMail,
      "master_package_hd_id": "0",
      "master_package_type_id": "0",
      "master_package_dt_id": "0",
      "master_program_id": "5"
    });
    
    var config = {
      method: 'post',
      url: urlApi + '/contact',
      // url:'http://localhost:5000/erp_buy',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      if(response.data == 1){
        setSaveStatus(true);
    }else {
      setSaveStatus(false);
    }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className='xcom_buy'>
            <div className='xcom_buy_header'>
                
            </div>
            <div className='xcom_buy_body'>
                <div className='xcom_buy_body__left'>
                    <div className='textHeader'>
                        ข้อมูลผู้ติดต่อ
                    </div>
                <div className='text-input'>
                    <TextField 
                        style={{marginTop: '5%'}} 
                        className='TextField' 
                        id="outlined-basic" 
                        label="ชื่อ - นามสกุล" 
                        placeholder='Full Name' 
                        variant="outlined" 
                        inputMode='text'
                        value={fullName}
                        onChange={(e) => {setFullName(e.target.value);}}
                    />
                    <ul className={textcheckfullName ? 'textCheck active' : 'textCheck'}>
                        * กรุณากรอกชื่อ - นามสกุล
                      </ul>
                    <TextField 
                        style={{marginTop: '5%'}} 
                        className='TextField' 
                        id="outlined-basic" 
                        label="เบอร์โทรศัพท์" 
                        placeholder='Telephone' 
                        variant="outlined" 
                        inputProps={{
                            maxlength: CHARACTER_LIMIT,
                        }}
                        inputMode= "numeric"
                        type={'tel'}
                        value={tel}
                        onChange={(e) => {setTel(checkNumber(e.target.value));}}
                    />
                    <ul className={textcheckTel ? 'textCheck active' : 'textCheck'}>
                        * กรุณากรอกเบอร์โทรศัพท์
                      </ul>
                    <FormControl fullWidth style={{marginTop: '5%'}}>
                      <InputLabel id="demo-simple-select-label">ประเภทร้าน</InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={storetype}
                          label="ประเภทร้าน"
                          onChange={handleChangeStoreType}
                      >
                        <MenuItem id='MenuItem' value={'ร้านอาหาร'}>ร้านอาหาร</MenuItem>
                        <MenuItem id='MenuItem' value={'ร้านกาแฟ'}>ร้านกาแฟ</MenuItem>
                        <MenuItem id='MenuItem'value={'ร้านคาเฟ่'}>ร้านคาเฟ่</MenuItem>
                        <MenuItem id='MenuItem' value={'ร้านบุฟเฟ่ต์'}>ร้านบุฟเฟ่ต์</MenuItem>
                        <MenuItem id='MenuItem' value={'อื่นๆ'}>อื่นๆ</MenuItem>
                      </Select>
                    </FormControl>
                    <ul className={textcheckType ? 'textCheck active' : 'textCheck'}>
                        * กรุณาเลือกประเภทร้าน
                      </ul>
                    <TextField 
                        style={{marginTop: '5%'}} 
                        className='TextField' 
                        id="outlined-basic" 
                        label="อีเมล" 
                        placeholder='E-Mail' 
                        variant="outlined" 
                        inputMode= "email"
                        value={eMail}
                        onChange={(e) => {setEmail(e.target.value);}}
                    />
                    <ul className={textcheckEmail ? 'textCheck active' : 'textCheck'}>
                        * กรุณากรอกอีเมล
                      </ul>
                    <TextField
                        style={{marginTop: '5%'}}
                        className='TextField'
                        id="outlined-textarea"
                        label="หมายเหตุ"
                        placeholder="Massage"
                        multiline
                        value={remark}
                        onChange={(e) => {setRemark(e.target.value);}}
                    />
                </div>
                <div>
                    <Button id='Button' onClick={() => {
                        handleSubmit()
                    }}
                        
                        variant="contained"
                    >
                        ลงทะเบียน
                    </Button>
                </div>
                </div>
                <div className='xcom_buy_body__right'>
                    <div className='textHeader'>
                        ที่อยู่สำนักงาน
                    </div>
                    <text className='xcom_textAddress'>
                        ที่อยู่: 88/88 หมู่ 20 ต.บ้านเป็ด อ.เมืองขอนแก่น จ.ขอนแก่น 40000
                        <br/>
                        <br/>
                        จันทร์-ศุกร์: 9.00 - 17.00 น.
                    </text>
                    <div className='googlemap'>
                        <Iframe  className='Iframe'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d239.1546776180886!2d102.78492304269855!3d16.451476788961795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x312261dbab44e41f%3A0x1f75c7ad939c22a8!2sTCS%20Tech%20care%20solution%20Co.%2CLtd!5e0!3m2!1sth!2sth!4v1667197494637!5m2!1sth!2sth" 
                             width='100%'
                             height='100%'
                            style="border:0;" 
                            allowfullscreen="" 
                            loading="lazy" 
                            referrerpolicy="no-referrer-when-downgrade">
                        </Iframe>
                    </div>
                    <div className='textHeader' style={{marginTop: '5%'}}>
                        ติดต่อฝ่ายขาย
                    </div>
                    <text className='xcom_textPhone'>
                        โทรศัพท์: 06-5464-5952
                    </text>
                </div>
            </div>
            <div className='xcom_buy_bottom'>
            
            </div>
        </div>
    )
}