import React, {useEffect, useState} from "react";
import './resume.css'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";


import img001 from './img/img001.jpg';
import img002 from './img/img002.jpg';
import img003 from './img/img003.jpg';
import img004 from './img/img004.jpg';

import ScrollToTop from '../../components/Scroll/ScrollToTop'



function Resume() {
  const [slides, setSlides] = useState(3)
  const [sizeDisplay, setSizeDisplay] =  useState();

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    setSizeDisplay(window.innerWidth)
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    handleResize();
  }, [sizeDisplay])


  const handleResize = () => {
    if (window.innerWidth < 960) {
        // setIsMobile(true)
        setSlides(1)
    } else {
        // setIsMobile(false)
        setSlides(3)
    }
  }

    return (
      <div className="resume">
      <ScrollToTop/>
        <div className="resume--item">
          <Swiper
            spaceBetween={30}
            slidesPerView={slides}
            centeredSlides={false}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
              pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper--resume"
          >
            <SwiperSlide>
            <div className="card--resume">
              <img src={img001} alt='img001'/>
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="card--resume">
              <img src={img002} alt='img002' />
            </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card--resume">
                <img src={img003} alt='img003'/>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card--resume">
                <img src={img004} alt='img004'/>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <br/>
        <br/>
        <div className="resume_data">
          <div className="resume_data_head">
            <ul className="vision--head box"></ul>
            <ul className="text_hd">ประวัติความเป็นมา</ul>
          </div>
          <div className="resume_data_body">
          <ul className="text_data --p1">บริษัท เทค แคร์ โซลูชั่น  จำกัด ดำเนินธุรกิจด้านซอฟต์แวร์สำเร็จรูป และสารสนเทศตั้งแต่ปี 2565 ในชื่อ
            ผลิตภัณฑ์ OHO โดยเริ่มจากการพัฒนาซอฟต์แวร์ POS, ERP, MRP, HRM, Web Design สำหรับทุกธุรกิจในเครือ
            ของ บริษัท ตำกระเทย แบรนด์ดิ้ง กรุ๊ป ทั้งหมด ตั้งแต่ปี 2564 เราได้พัฒนาซอฟต์แวร์ให้ตอบโจทย์การใช้งาน 
            และเหมาะสมกับธุรกิจ ผ่านการเชื่อมโยงประสบการณ์จริงและเทคโนโลยีอันทันสมัย จนนำมาซึ่งความพึงพอใจ
            ของผู้ใช้งานและผู้บริหาร
          </ul>
          <ul className="text_data --p2">โอกาสทางธุรกิจ ทีมพัฒนาได้เล็งเห็นโอกาส และอยากส่งต่อซอฟต์แวร์ที่ตอบโจทย์ผู้ใช้งาน และผู้ประกอบการ
            เพื่อเป็น Business Solution Software ที่ใครๆก็เข้าถึงได้ จึงได้ตัดสินใจก่อตั้ง บริษัท เทค แคร์ โซลูชั่น จำกัด
            เพื่อเป็นทางเลือกให้กับผู้ประกอบการเริ่มต้น หรือกิจการขยาย ที่มีงบประมาณจำกัด ราคาเข้าถึงง่าย มีหลากหลาย
            เงื่อนไขทั้งระบบและราคา อีกทั้งมีร้านค้าที่เป็น Distribution Channel ให้ผู้ที่สนใจผลิตภัณฑ์ OHO ได้ ไปเยี่ยมชม
            และสังเกตการณ์ก่อนตัดสินใจ ปัจจุบันบริษัทฯ หน่วยงาน องค์กร สถานประกอบการ ร้านค้า ที่ให้ความสนใจ และ
            ตัดสินใจเป็นลูกค้าของบริษัทฯ และเลือกใช้ผลิตภัณฑ์ OHO มากกว่า 1 ผลิตภัณฑ์
            บริษัท เทคแคร์ โซลูชั่น จำกัด เรามีบุคลากรที่มีความรู้ ความชำนาญและมีประสบการณ์ในการพัฒนาซอฟต์แวร์
            เป็นบุคคลกรในพื้นที่ โอกาสในการเทิร์นโอเวอร์ต่ำ สามารถพัฒนาโปรแกรมได้มาตรฐาน สร้างโมดูลที่ตอบโจทย์
            ผู้ใช้งาน ด้วยประสบการณ์ของทีมงาน ผู้บริหาร ที่ปรึกษา ทำให้ผู้ใช้งานใช้ได้ง่าย เข้าใจง่าย มีประสิทธิภาพ ตอบ
            โจทย์การทำงาน
          </ul>
          <ul className="text_data --p3">
            ผลิตภัณฑ์ OHO ใช้ Cloud Server ในการจัดเก็บข้อมูล ทำให้ไม่ต้องจ้างไอทีซัพพอร์ต และประหยัดไฟเซิฟเวอร์
            ค่าใช้จ่ายแฝงอื่นๆอีกมากมาย เราใช้ฐานข้อมูลที่เป็น Open Source ทำให้ลูกค้าประหยัดค่าLicense เรายังมีการ
            อบรมเชิงลึก OJT สอนการใช้งานโปรแกรมให้กับทีมงานของผู้ประกอบการทั้งรูปแบบออนไลน์ออฟไลน์ มีบริการ
            หลังการขายโดยใช้หลัก CRM
          </ul>
          <br/>
          <ul className="text_data --p4">
            เราขอขอบคุณที่ไว้วางใจ ให้เราได้เติมเต็มและติดปีกธุรกิจของคุณ
          </ul>
          </div>
        </div>
      </div>
    );
  }
  export default Resume;