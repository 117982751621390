import React, {useEffect} from 'react';
import './pos_package_compare.css'
import ScrollToTop from '../../../../components/Scroll/ScrollToTop'
import img_package from '../../service_img/softwarepackage2.jpg';

export default function PackageCompare(){

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
        <div className='pos_package'>
        <ScrollToTop/>
            <div className='pos_package_body'>
                <img src={img_package}/>
            </div>
        </div>
    );
}