import React, {useState, useEffect} from 'react'
import './Setting.css'

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Backdrop from '@mui/material/Backdrop';

import AddProgram from './AddProgramModel';
import AddPackageHD from './AddPackageHDModel';
import AddPackageType from './AddPackageTypeModel';
import AddPackageDT from './AddPackageDTModel';


function Setting() {
    const [openProgram, setOpenProgram] = useState(false);
    const [openPackageHD, setOpenPackageHD] = useState(false);
    const [openPackageType, setOpenPackageType] = useState(false);
    const [openPackageDT, setOpenPackageDT] = useState(false);
    
    const handleToggle = (ID) => {
      if (ID == 1) {
        setOpenProgram(!openProgram)
      }
      if (ID == 2) {
        setOpenPackageHD(!openPackageHD)
      }
      if (ID == 3) {
        setOpenPackageType(!openPackageType)
      }
      if (ID == 4) {
        setOpenPackageDT(!openPackageDT)
      }
    };
  return (
    <div ClassNames="Setting_page">
        <div className='Setting_body'>
        {/* <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            '& > :not(style)': {
            m: 1,
            width: 128,
            height: 128,
            },
            mt: 5,
            ml: 15
        }}
        >
          <Paper className='Paper' onClick={() => {handleToggle(1)}} >ตั้งค่าโปรแกรม</Paper>
          <Paper className='Paper' onClick={() => {handleToggle(2)}} >ตั้งค่าแพ็คเกจ</Paper>
          <Paper className='Paper' onClick={() => {handleToggle(3)}} >ตั้งค่าประเภท</Paper>
          <Paper className='Paper' onClick={() => {handleToggle(4)}} >ตั้งค่าระยะเวลา</Paper>
        </Box>
        <AddProgram openProgram={openProgram} setOpenProgram={setOpenProgram} />
        <AddPackageHD openPackageHD={openPackageHD} setOpenPackageHD={setOpenPackageHD}/>
        <AddPackageType openPackageType={openPackageType} setOpenPackageType={setOpenPackageType}/>
        <AddPackageDT openPackageDT={openPackageDT} setOpenPackageDT={setOpenPackageDT} /> */}
        </div>
    </div>
  )
}

export default Setting