import React, {useState} from "react";
import { Link } from "react-router-dom";
import { MenuItemProduct } from "./MenuItemProduct";
import './DropdownProduct.css';



function DropdownProduct(){
const [click, setClick] = useState(false)

const handkeclick = () => setClick(!click)


    return(
        <>
            <ul onClick={handkeclick} className={click ? 'dropdown-menu-product clicked' : 'dropdown-menu-product'}>
                {MenuItemProduct.map((item, index) => {
                    return (
                        <li key={index}>
                            <Link className={item.cName} to={item.path} onClick={()=> setClick(false)}>
                                {item.title}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}

export default DropdownProduct;