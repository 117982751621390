import React, {useEffect} from "react";
import './career.css';
import ScrollToTop from '../../components/Scroll/ScrollToTop'

function Career() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    return (
      <div className="career">
      <ScrollToTop />
        <div className="career-header">
        <h1>ร่วมเป็นส่วนหนึ่งของทีม หากคุณมองหางานที่มีความสุข สนุก ท้าทายกับงานที่ทำ!</h1>
        </div>
        <div className="career-body">
          <text>บริษัท เทค แคร์ โซลูชั่น จำกัด กับ 7 ผลิตภัณฑ์ ซอฟต์แวร์ ที่เติมเต็มและติดปีกธุรกิจ ด้วยสุดยอดเครื่องมือในการดำเนินธุรกิจ Business Solution Software  ผ่านการเชื่อมโยงประสบการณ์จริงและเทคโนโลยีอันทันสมัย เพื่อตอบสนองต่อความพึงพอใจสูงสุดของผู้ประกอบการ </text>
          <text>บริษัทให้บริการในการพัฒนาระบบงานด้วยทีมงานผู้มีประสบการณ์ ทั้งทางด้านเทคโนโลยีสารสนเทศ การจัดการและผู้เชี่ยวชาญ เพื่อแบ่งเบาภาระในการบริหารงาน การจัดการโครงงานและการพัฒนาระบบงานทางด้านเทคโนโลยีสารสนเทศของทางบริษัทผู้ใช้บริการ รวมถึงการดูแลรักษาระบบงานให้สามารถตอบสนองความต้องการของบริษัทผู้ใช้บริการ เพื่อเพิ่มศักยภาพในการแข่งขันทางธุรกิจ</text>

        </div>
        <div className="career-bottom"> 
          <h1>ตำแหน่งงานที่กำลังเปิดรับ</h1>
        </div>
        <div className="career-card">
          <ul className="career-card--item">
            <text>Null</text>
          </ul>
          <ul className="career-card--item">
            <text>Null</text>
          </ul>
          <ul className="career-card--item">
            <text>Null</text>
          </ul>
          <ul className="career-card--item">
            <text>Null</text>
          </ul>
        </div>
        
      </div>
    );
  }
  export default Career;