import React,{useEffect, useState} from 'react'
import './HomeManage.css'
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import  Buttons from '../../components/Button/Button';
import {urlApi} from "../../url_api/url_api";
import Moment from 'moment';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import mss from '../../components/message/messagebox'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import checkNumber from '../../components/checkInput/checkNumber';


function HomeManage() {
  let { cusID } = useParams();
  const [contactData, setContactData] = useState([]);

  const [statusData, setStatusData] = useState([]);
  const [package_Docuno, setPackage_Docuno] = useState('');
  const [statusID, setStatusID] = useState(0);
  const [remark, setRemark] = useState();

  const [newDocument, setNewDocument] = useState(true);

  const [handleDate, setHandleDate] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [countDate, setCountDate] = useState(0);

  const [posStatus, setPosStatus] = useState(false);

  const [packageHD, setPackageHD] = useState([]);
  const [packageType, setPackageType] = useState([]);
  const [packageDT, setPackageDT] = useState([]);

  const [program_ID, setProgram_ID] = useState(0);
  const [packageHD_ID, setPackageHD_ID] = useState(0);
  const [packageType_ID, setPackageType_ID] = useState(0);
  const [packageDT_ID, setPackageDT_ID] = useState(0);

  const [titleStatusName, setTitleStatusName] = useState("HomeManage_body_grad_body__title");

  const [saveStatus, setSaveStatus] = useState();
  const [userID, setUserID] = useState(localStorage.getItem('userLogin_id'))

  const [resName, setResName] = useState('');
  const [resType, setResType] = useState('');
  const [resCount, setResCount] = useState('');
  const [cusName, setCusName] = useState('');
  const [cusPhone, setCusPhone] = useState('');
  const [cusEmail, setCusEmail] = useState('');

  const CHARACTER_LIMIT = 10;

  Moment.locale('th');


//#region  useEffect
useEffect(() => {

  window.scrollTo(0, 0)
  setUserID(localStorage.getItem('userLogin_id'));
  if (userID) {
    console.log("userID : " + userID);
  }
  getStatus();

  if(cusID != 0 ){
    getDataContact();
  }
  if (program_ID != 0 ) {
    getDataPackageHD();
  }
  if(packageHD_ID != 0 ){
    getDataPackageTYPE();
  }
  if (packageType_ID != 0 ) {
    getDataPackageDT();
  }
}, [])

useEffect(() => {
  console.log("countDate : " + countDate);
  console.log("handleDate : " + handleDate);
}, [contactData, statusID, titleStatusName,countDate, handleDate])

useEffect(() => {
    if (statusID == 7 || statusID == 8) {
      setHandleDate(true);
      if (newDocument) {
        setStartDate(null);
        setEndDate(null);
      }
    }
}, [statusID])


useEffect(() => {
  if(saveStatus){
    const swalWithBootstrapButtons = Swal.mixin({
      
      buttonsStyling: false,
    });
    if(saveStatus == true){
      swalWithBootstrapButtons.fire(
        "SUCCESS",
        "บันทึกสำเร็จ!"
      );
    handleClick()
  }else{
    swalWithBootstrapButtons.fire(
      "ERROR",
      "บันทึกไม่สำเร็จ! (ลองบันทึกอีกครั้ง)"
    );
  }}
}, [saveStatus])

useEffect(() => {
  getDataPackageHD();
}, [program_ID])

useEffect(() => {
  getDataPackageTYPE();
}, [packageHD_ID])

useEffect(() => {
  getDataPackageDT();
}, [packageType_ID])

useEffect(() => {
  if (startDate != 'null') {
    getEndDate();
  }
}, [startDate, countDate])

useEffect(() => {
  if (packageDT_ID != 0) {
    getPackageDT();
  }
}, [packageDT_ID])
//#endregion

//#region Get Data

async function getEndDate() {
  var axios = require('axios');
  var data = JSON.stringify({
    "startDate": startDate,
    "count": countDate
  });

  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: urlApi +'/contact/get_endDate',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data[0].end_date));
    setEndDate(response.data[0].end_date);
  })
  .catch(function (error) {
    console.log(error);
  });

}

const getDataContact = async () => {
  if(cusID != 0){
    var axios = require('axios');
    var data = JSON.stringify({
      "program_id": "0",
      "_customer_id": cusID,
      "_status_id":"0"
    });
    
    var config = {
      method: 'post',
      url: urlApi + '/contact/search_cus',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    await axios(config)
    .then(function (response) {
      if (response.data.length > 0) {
        setContactData(response.data[0]);
        setResName(response.data[0].res_name);
        setResType(response.data[0].res_type);
        setResCount(response.data[0].res_count);
        setCusName(response.data[0].cus_name);
        setCusPhone(response.data[0].cus_phone);
        setCusEmail(response.data[0].cus_email);
        
        if (response.data[0].master_program_id == 1) {
          setPosStatus(true)
          setProgram_ID(response.data[0].master_program_id);
          setPackageHD_ID(response.data[0].master_package_hd_id);
          setPackageType_ID(response.data[0].master_package_type_id);
          setPackageDT_ID(response.data[0].master_package_dt_id);

          if (response.data[0].master_contact_status_remark != null) {
            setRemark(response.data[0].master_contact_status_remark);
          }
          
          if (response.data[0].master_contact_status_id != null) {
            setStatusID(response.data[0].master_contact_status_id);
            setNewDocument(false)
          }
          // if (response.data[0].update_user_id != null) {
          //   setSaveStatus(true);
          // }else{
          //   setSaveStatus(false);
          // }
        } else {
          setPosStatus(false)
        }
      }else{
        setContactData([]);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

}

const getStatus = async () => {
  var axios = require('axios');
  var data = '';

  var config = {
    method: 'post',
    url: urlApi + '/contact/get_cus_status',
    headers: { },
    data : data
  };

  await axios(config)
  .then(function (res) {
    if(res.data.length > 0)
      setStatusData(res.data);
  })
  .catch(function (error) {
    console.log(error);
  });
}

const getPackageDocuno = async () => {
  var axios = require('axios');
  var data = '';

  var config = {
    method: 'post',
    url: urlApi + '/contact/get_package_docuno',
    headers: { },
    data : data
  };

  await axios(config)
  .then(function (response) {
    console.log('contact_package_docuno : '+response.data[0].contact_package_docuno);
    if(InsertContactPackage(response.data[0].contact_package_docuno))
    {
      return true
    }else{
      return false
    }
  })
  .catch(function (error) {
    console.log(error);
    return false
  });
}

async function  getDataPackageHD() {
  if (program_ID != 0) {
    try {
      const data = {
        "master_program_id": program_ID
      }
  
      let url = urlApi + "/packhd";
  
      await axios.post(url, data)
      .then((res) => {
        if (res.data.length > 0) {
          setPackageHD(res.data);
        } else {
          setPackageHD([]);
        }
      })
      .catch((err) => {
         // handle errors
         console.log(err);
      })
      
    } catch (error) {
      console.log(error);
      return;
    }
  }
}

async function  getDataPackageTYPE() {
  if (packageHD_ID != 0) {
    try {
      const data = {
        "master_package_hd_id": packageHD_ID
      }
  
      let url = urlApi + "/packtype";
  
      await axios.post(url, data)
      .then((res) => {
        // handle success
        if (res.data.length > 0) {
          setPackageType(res.data);
        } else {
          setPackageType([]);
        }
      })
      .catch((err) => {
         // handle errors
         console.log(err);
      })
      
    } catch (error) {
      console.log(error);
      return;
    }
  }
  
}

async function  getDataPackageDT() {
  if (packageType_ID != 0) {
    try {
      const data = {
        "master_package_type_id": packageType_ID
      }
  
      let url = urlApi + "/packdt";
  
      await axios.post(url, data)
      .then((res) => {
        // handle success
        if (res.data.length > 0) {
          setPackageDT(res.data);
        } else {
          setPackageDT([]);
        }
      })
      .catch((err) => {
         // handle errors
         console.log(err);
      })
      
    } catch (error) {
      console.log(error);
      return;
    }
  }
}

async function  getPackageDT() {
  if (packageType_ID != 0) {
    try {
      const data = {
        "_dt_id": packageDT_ID
      }
  
      let url = urlApi + "/packdt/id";
  
      await axios.post(url, data)
      .then((res) => {
        // handle success
        if (res.data.length > 0) {
          setCountDate(res.data[0].count_date);
        } else {
          setCountDate(0);
        }
      })
      .catch((err) => {
         // handle errors
         console.log(err);
      })
      
    } catch (error) {
      console.log(error);
      return;
    }
  }
}
//#endregion

//#region  Function
function handleClick() {
  window.location.href = '/homeadmin';
}

function handleStartDate(params) {
  setStartDate(params.$y + '-' + params.$m + '-' + params.$d)
  console.log(params);
  console.log(startDate);
}

function handleEndDate(params) {
  if(params < startDate){
    setEndDate(params);
  }
}

function checkInsertStatus() {
  console.log('checkInsertStatus');
  if (statusID == 0) {
    mss("warning","กรุณาเลือก สถานะ")
    setTitleStatusName("HomeManage_body_grad_body__titles");
    console.log(statusID);
    return false
  }else{
    setTitleStatusName("HomeManage_body_grad_body__title");
    console.log(statusID);
    return true
  }
  
}

function checkInsertDate() {
  console.log('checkInsertDate');
  if (handleDate == true) {
    if (startDate == 'null') {
      mss("warning","กรุณาเลือก วันที่เริ่ม")
      return false
    }else if(endDate == 'null') {
      mss("warning","กรุณาเลือก วันที่สิ้นสุด")
      return false
    }else{
      console.log('checkInsertDate : true');
      return true
    }
  }else{
    console.log('checkInsertDate : true');
    return true
  }
  
}

function handleSubmit(){
  const swalWithBootstrapButtons = Swal.mixin({
      
    buttonsStyling: false,
  });
  swalWithBootstrapButtons
  .fire({
    title: "คุณต้องการบันทึก ใช่ หรือ ไม่ ?",
    showCancelButton: true,
    icon: "question",
    confirmButtonText: "บันทึก",
    cancelButtonText: "ยกเลิก",
    reverseButtons: false,
    customClass: {
      icon: "no-before-icon",
      Button: "swal-btn",
      confirmButton: "confirmButton",
      cancelButton: "cancelButton",
    },
  })
  .then((result) => {
    if (result.isConfirmed) {
      if(checkInsertStatus() == true && checkInsertDate() == true){
        console.log('Check Data Pass');
        handleInsert();
      }
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) 
    {
    //   swalWithBootstrapButtons.fire(
    //     ""
    //   );
    }
  });
}

const handleChangeStatus = (event) => {
  console.log(event)
  setStatusID(event.target.value);
  setTitleStatusName("HomeManage_body_grad_body__title");
  
  if (event.target.value == 7 || event.target.value == 8) {
    setHandleDate(true);
  }else{
    setHandleDate(false);
    setStartDate(null);
    setEndDate(null);
  }
};

const handleChangePackateHD = (event) => {
  setPackageHD_ID(event.target.value);
  if (packageHD_ID == 0) {
    setPackageType_ID(0);
    setPackageDT_ID(0);
    setPackageType([]);
    setPackageDT([]);
  }
  
};

const handleChangePackateType = (event) => {
  setPackageType_ID(event.target.value);
  setPackageDT_ID(0);
};

const handleChangePackateDT = (event) => {
  setPackageDT_ID(event.target.value);
  console.log(event);
};

function clearData() {
  setContactData([]);
  setStatusData([]);
  setPackage_Docuno('');
  setStatusID(0);
  setRemark('');

  setHandleDate(false);
  setStartDate(null);
  setEndDate(null);

  setPosStatus(false);

  setPackageHD([]);
  setPackageType([]);
  setPackageDT([]);

  setProgram_ID(0);
  setPackageHD_ID(0);
  setPackageType_ID(0);
  setPackageDT_ID(0);

  setTitleStatusName("HomeManage_body_grad_body__title");
}

async function InsertContactPackage(docuno) {
   console.log(2);
    Moment.locale('en-US');
    const dateNow = Date.now();
    var axios = require('axios');
    var data = JSON.stringify({
      "docuno": docuno,
      "docudate": Moment(dateNow).format('YYYY-MM-DD'),
      "resName": resName,
      "resCount": resCount,
      "resType": resType,
      "cusName": cusName,
      "cusPhone": cusPhone,
      "cusEmail": cusEmail,
      "master_package_hd_id": packageHD_ID,
      "master_package_type_id": packageType_ID,
      "master_package_dt_id": packageDT_ID,
      "master_program_id": program_ID,
      "start_date": Moment(startDate).format('YYYY-MM-DD'),
      "end_date": Moment(endDate).format('YYYY-MM-DD'),
      "contact_package_status_id": statusID,
      "contact_package_remark": remark
    });

    var config = {
      method: 'post',
      url: urlApi + '/contact/insertContactPackage',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    await axios(config)
    .then(function (response) {
      if(response.data == 1){
        return true
      }else{
        return false
      }
    })
    .catch(function (error) {
      console.log(error);
      return false
    });
  
}

async function handleInsert() {
  console.log('handleInsert');
  var axios = require('axios');
  var data = JSON.stringify({
    "_status_id": statusID,
    "_remark": remark,
    "_customer_id": cusID,
    "_user_id": userID,
    "_packageHDID": program_ID == 1 ? packageHD_ID : "0",
    "_packageTYPEID": program_ID == 1 ? packageType_ID : "0",
    "_packageDTID": program_ID == 1 ? packageDT_ID : "0",
    "program_id": program_ID,
    "resName": resName,
    "resCount": resCount,
    "resType": resType,
    "cusName": cusName,
    "cusPhone": cusPhone,
    "cusEmail": cusEmail
  });

  var config = {
    method: 'post',
    url: urlApi +'/contact/updateContactCustomer',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  await axios(config)
  .then(function (response) {
    if(response.data == 1){
      console.log('Update Success');
      if(statusID == 7 || statusID == 8){
        if(!getPackageDocuno()){
          setSaveStatus(false);
          return
        }
      }
      setSaveStatus(true);
      return
    }else {
      setSaveStatus(false);
      return
    }
  })
  .catch(function (error) {
    console.log(error);
    setSaveStatus(false);
    return
  });
}
//#endregion

  return (
    <div className='HomeManage'>
      <div className='HomeManage_body_left'>
        <dev className="HomeManage_header">ข้อมูลลูกค้า</dev>
        <div className='HomeManage_body_grad'>
          <div className='HomeManage_body_grad_body'>
                <div className='HomeManage_body_grad_body__title'>ชื่อร้าน : </div>
                <TextField
                    className='HomeManage_body_grad_body_input' 
                    id="outlined-basic" 
                    variant="standard" 
                    inputMode='none'
                    value={resName}
                    onChange={(e) => {
                      setResName(e.target.value)
                      console.log(e.target.value)
                    }}
                    // disabled
                  />
              </div>
              <div className='HomeManage_body_grad_body'>
              <div className='HomeManage_body_grad_body__title'>ประเภท : </div>
                <TextField
                    className='HomeManage_body_grad_body_input' 
                    id="outlined-basic" 
                    variant="standard" 
                    inputMode='none'
                    value={resType}
                    onChange={(e) => setResType(e.target.value)}
                    // disabled
                  />
              </div>
              <div className='HomeManage_body_grad_body'>
              <div className='HomeManage_body_grad_body__title'>จำนวนสาขา : </div>
                <TextField
                    className='HomeManage_body_grad_body_input' 
                    id="outlined-basic" 
                    variant="standard" 
                    inputMode='none'
                    value={resCount}
                    onChange={(e) => setResCount(checkNumber(e.target.value))}
                    // disabled
                  />
              </div>
              <div className='HomeManage_body_grad_body'>
              <div className='HomeManage_body_grad_body__title'>ชื่อลูกค้า : </div>
                <TextField
                    className='HomeManage_body_grad_body_input' 
                    id="outlined-basic" 
                    variant="standard" 
                    inputMode='none'
                    value={cusName}
                    onChange={(e) => setCusName(e.target.value)}
                    // disabled
                  />
              </div>
              <div className='HomeManage_body_grad_body'>
              <div className='HomeManage_body_grad_body__title'>เบอร์โทรศัพท์ : </div>
                <TextField
                    className='HomeManage_body_grad_body_input' 
                    id="outlined-basic" 
                    variant="standard" 
                    inputMode='none'
                    value={cusPhone}
                    onChange={(e) => setCusPhone(checkNumber(e.target.value))}
                    inputProps={{
                      maxlength: CHARACTER_LIMIT,
                    }}
                    // disabled
                  />
              </div>
              <div className='HomeManage_body_grad_body'>
              <div className='HomeManage_body_grad_body__title'>อีเมล : </div>
                <TextField
                    className='HomeManage_body_grad_body_input' 
                    id="outlined-basic" 
                    variant="standard" 
                    inputMode='none'
                    value={cusEmail}
                    onChange={(e) => setCusEmail(e.target.value)}
                    // disabled
                  />
              </div>
              <div className='HomeManage_body_grad_body'>
              <div className='HomeManage_body_grad_body__title'>โปรแกรม : </div>
                <TextField
                    className='HomeManage_body_grad_body_input' 
                    id="outlined-basic" 
                    variant="standard" 
                    inputMode='none'
                    value={contactData.master_program_code}
                    disabled
                  />
              </div>
              <div className='HomeManage_body_grad_body'>
              <div className='HomeManage_body_grad_body__title'>ชื่อแพ็คเกจ : </div>
                <TextField
                    className='HomeManage_body_grad_body_input' 
                    id="outlined-basic" 
                    variant="standard" 
                    inputMode='none'
                    value={contactData.master_package_hd_code}
                    disabled
                  />
              </div>
              <div className='HomeManage_body_grad_body'>
              <div className='HomeManage_body_grad_body__title'>ประเภทแพ็คเกจ : </div>
                <TextField
                    className='HomeManage_body_grad_body_input' 
                    id="outlined-basic" 
                    variant="standard" 
                    inputMode='none'
                    value={contactData.master_package_type_name}
                    disabled
                  />
              </div>
              <div className='HomeManage_body_grad_body'>
              <div className='HomeManage_body_grad_body__title'>ระยะเวลาแพ็คเกจ : </div>
                <TextField
                    className='HomeManage_body_grad_body_input' 
                    id="outlined-basic" 
                    variant="standard" 
                    inputMode='none'
                    value={contactData.master_package_dt_name}
                    disabled
                  />
              </div>
              <div className='HomeManage_body_grad_body'>
              <div className='HomeManage_body_grad_body__title'>วันที่บันทึก : </div>
                <TextField
                    className='HomeManage_body_grad_body_input' 
                    id="outlined-basic" 
                    variant="standard" 
                    inputMode='none'
                    value={ Moment(contactData.savetime).format('d/MM/') + (parseInt(Moment(contactData.savetime).format('yyyy'))+543)}
                    disabled
                  />
              </div>
              <div className='HomeManage_body_grad_body'>
              <div className='HomeManage_body_grad_body__title'>หมายเหตุ : </div>
                <TextField
                    className='HomeManage_body_grad_body_input' 
                    id="outlined-basic" 
                    variant="standard" 
                    inputMode='none'
                    value={contactData.remark ? contactData.remark :  ""}
                    disabled
                  />
              </div>
              <div className='HomeManage_body_grad_body'>
              <div className='HomeManage_body_grad_body__title'>สถานะ : </div>
                <TextField
                    className='HomeManage_body_grad_body_input' 
                    id="outlined-basic" 
                    variant="standard" 
                    inputMode='none'
                    value= {contactData.master_contact_status_name ? contactData.master_contact_status_name :  "ปกติ"}
                    disabled
                  />
              </div>
        </div>
      </div>
      <div className='HomeManage_body_right'>
        <dev className="HomeManage_header">จัดการ</dev>
        <div className='HomeManage_body_grad_right'>
        {posStatus ? <>
        <div className='HomeManage_body_grad_body'> 
          <div className='HomeManage_body_grad_body__title'>แพ็คเกจ : </div>
          <FormControl fullWidth style={{marginTop: '5%', marginLeft: '7%'}}>
            <NativeSelect
            defaultValue={0}
            value={packageHD_ID}
            onChange={handleChangePackateHD}
            onClick={handleChangePackateHD}
            id="HomeManage_body_grad_body_select"
            className='HomeManage_body_grad_body_select'
            >
            <option  id='body_select_option' value="0">
              เลือกแพ็คเกจ
            </option>
            {packageHD.length > 0 ? packageHD.map((
            {master_package_hd_id, master_package_hd_name}
            ) => {
            return(
            <option  id='body_select_option'
              key={master_package_hd_id} 
              value={master_package_hd_id}>
              {master_package_hd_name}
            </option>
            );
            }
            ) : <option  id='body_select_option' value="">
                  ไม่มีข้อมูล
                </option>
            }
            </NativeSelect>
          </FormControl>
        </div>
        <div className='HomeManage_body_grad_body'> 
        <div className='HomeManage_body_grad_body__title'>ประเภท : </div>
          <FormControl fullWidth style={{marginTop: '5%', marginLeft: '7%'}}>
              {/* <InputLabel id="demo-simple-select-label">ชื่อแพ็คเกจ</InputLabel> */}
              <NativeSelect
              defaultValue={0}
              value={packageType_ID}
              onChange={handleChangePackateType}
              onClick={handleChangePackateType}
              id="HomeManage_body_grad_body_select"
              className='HomeManage_body_grad_body_select'
              >
              {packageType.length > 0 ? packageType.map((
              {master_package_type_id, master_package_type_name}
              ) => {
              return(
              <option  id='body_select_option'
                key={master_package_type_id} 
                value={master_package_type_id}>
                {master_package_type_name}
              </option>
              );
              }
              ) : <option  id='body_select_option' value="">
                    ไม่มีข้อมูล
                  </option>
              }
              </NativeSelect>
          </FormControl>
        </div>
        <div className='HomeManage_body_grad_body'> 
        <div className='HomeManage_body_grad_body__title'>ระยะเวลา : </div>
          <FormControl fullWidth style={{marginTop: '5%', marginLeft: '7%'}}>
            <NativeSelect
            defaultValue={packageDT_ID}
            value={packageDT_ID}
            onChange={handleChangePackateDT}
            onClick={handleChangePackateDT}
            id="HomeManage_body_grad_body_select"
            className='HomeManage_body_grad_body_select'
            key={packageDT_ID}
            >
            {packageDT.length > 0 ? packageDT.map((
            {master_package_dt_id, master_package_dt_name}
            ) => {
            return(
            <option key={master_package_dt_id}  id='body_select_option' 
              value={master_package_dt_id}
              >
              {master_package_dt_name}
            </option>
            );
            }
            ) : <option  id='body_select_option' value="">
                  ไม่มีข้อมูล
                </option>
            }
            </NativeSelect>
          </FormControl>
        </div>
        </>
        :
        <>
        </>
        }
          <div className='HomeManage_body_grad_body'>
            <div className={titleStatusName}>สถานะ : </div>
            <FormControl fullWidth style={{marginTop: '5%', marginLeft: '7%'}}>
              <NativeSelect
              defaultValue={statusID}
              Value={statusID}
              onChange={handleChangeStatus}
              onClick={handleChangeStatus}
              id="HomeManage_body_grad_body_select"
              className='HomeManage_body_grad_body_select'
              key={statusID}
              >
              <option  id='body_select_option' value="0">
              เลือกสถานะ
              </option>
              {statusData.length > 0 ? statusData.map((
                {master_contact_status_id, master_contact_status_name}
              ) => {
                return(
                  <option key={master_contact_status_id}  id='body_select_option' 
                    value={master_contact_status_id}>
                    {master_contact_status_name}
                  </option>
                );
              }
              ) : <option  id='body_select_option' value="">
                    ไม่มีข้อมูล
                  </option>
            }
            </NativeSelect>
            </FormControl>
          </div>
          <div className='HomeManage_body_grad_body'>
            <div className='HomeManage_body_grad_body__title'>
            หมายเหตุ : 
            </div>
              <TextField
                className='HomeManage_body_grad_body_input' 
                id="outlined-basic" 
                variant="standard" 
                inputMode='none'
                value={remark}
                onChange={(e) => {setRemark(e.target.value)}}
              />
          </div>
          {!handleDate ? <> </> : 
          <>
          <br/>
          <div className='HomeManage_body_grad_body'> 
            <div className='HomeManage_body_grad_body__title'>วันที่เริ่ม : </div>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
              <DatePicker style={{marginTop: '10%'}} className='HomeManage_body_grad_body_input'
                openTo="day"
                views={['year', 'month', 'day']}
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue.$y +'-'+ newValue.$M + '-' + newValue.$D);
                }}
                renderInput={(params) => 
                  <TextField {...params} />
                }
                inputFormat="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </div>
          <div className='HomeManage_body_grad_body'> 
            <div className='HomeManage_body_grad_body__title'>วันที่สิ้นสุด : </div>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DatePicker style={{marginTop: '10%'}} className='HomeManage_body_grad_body_input'
                openTo="day"
                views={['year', 'month', 'day']}
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue.$y +'-'+ newValue.$M + '-' + newValue.$D);
                }}
                // disabled
                renderInput={(params) => 
                  <TextField {...params} />
                }
                inputFormat="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </div>
          </>
          }
          <div className='HomeManage_footer'>
          <Buttons name='บันทึก'  onClick={ () => {handleSubmit()}} />
          <Buttons name='ยกเลิก'  onClick={ () => {handleClick()}} />
        </div>
        </div>
      </div>
    </div>
  )
}

export default HomeManage