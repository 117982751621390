import React, {useState, useEffect} from 'react'
import './login.css'

import TextField from '@mui/material/TextField';
import Button from '../../components/Button/Button';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import logo from '../../components/Navbar/logoTechCare.png'

import MessageBox from '../../components/message/messagebox'
import Tooltip from '@mui/material/Tooltip';
import { urlApi } from '../../url_api/url_api';



function Login() {

  const [userName, setUserName] = useState('');
  const [passWord, setPassWord] = useState('');
  const [dataLogin, setDataLogin] = useState([]);
  const [showPassword, setShowPassword] = useState(true);


  useEffect(() => {
    window.scrollTo(0, 0)
    localStorage.setItem("userLogin", false);

    
    if(localStorage.getItem('userLogin') == true){
      window.location.href = "/homeadmin";
    }
  }, [])

  async function updateUserlogin(params) {
    var axios = require('axios');
    var data = JSON.stringify({
      "user_id": params,
      "status": "true"
    });
    
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: urlApi + '/login/update_status',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    await axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  async function handleSubmit(){
    var axios = require('axios');
    if (userName.toString().trim() === '') {
      MessageBox('warning','กรุณากรอก Username');
      return
    }else if(passWord.toString().trim() === ''){
      MessageBox('warning','กรุณากรอก Password');
      return
    }

    const data = {
      user: userName, 
      password: passWord
    }

    let url = urlApi + "/login";
    let user = '';
    let pass = '';
    await axios.post(url, data).then((res) => {
      if(res.data.length !== 0){
        setDataLogin(res.data)
        if(dataLogin){
          user = res.data[0].user_login_name;
          pass = res.data[0].user_login_password;
        }
        if (userName.toUpperCase() === user.toUpperCase() && passWord == pass) {
          if(res.data[0].user_login_status == true){
            MessageBox('warning','User Login Active');
            return
          }
          localStorage.setItem("userLogin", true);
          localStorage.setItem("userLogin_id", res.data[0].user_login_id);
          localStorage.setItem("userName", res.data[0].user_login_fullname);
          updateUserlogin(res.data[0].user_login_id);
          window.location.href = "/homeadmin";
        }else{
          MessageBox('warning','Password ไม่ถูกต้อง');
        }

      }else{
        MessageBox('warning','User ไม่ถูกต้อง');
      }

    }).catch((err) => {
      console.log(err);
    })
  }

  const handleClickShowPassword = () => {
    if(!showPassword){
      setShowPassword(true)
    }else{
      setShowPassword(false)
    }
    
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className='login'>
      <div className='login_body'>
        <div className='login_logo'>
          <Tooltip title="Facebook">
            <a href='https://www.facebook.com/techcaresolutionofficial' >
              <img src={logo} alt="logo login" />
            </a>
          </Tooltip>
        </div>
        <div className='login_body__input'>
        <text>User Name</text>
        <TextField 
          style={{marginTop: '5%'}} 
          className='login_text' 
          id="outlined-basic" 
          // label="User Name" 
          placeholder='User Name' 
          variant="outlined" 
          inputMode='text'
          value={userName}
          onChange={(e) => {
            setUserName(e.target.value);
            }
          }
          
        />
        <text style={{marginTop: '5%'}}>Password</text>
        <OutlinedInput
            id="outlined-basic"
            type={!showPassword ? 'text' : 'password'}
            value={passWord}
            label="Password"
            onChange={(e) => {setPassWord(e.target.value);}}
            endAdornment={
              <InputAdornment position="end" label="Password">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            style={{marginTop: '5%'}} 
          />
        </div>
        <div className='login_body_bottom'>
          {<Button name='login'  onClick={() => {handleSubmit()}}/>}
          {<Button name='cancel'  onClick={() => {
            localStorage.removeItem('userLogin');
            window.location.href = "/";
          }}/>}
        </div>
      </div>
    </div>
  )
}

export default Login
