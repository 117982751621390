import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


import img_pos from './img_promotion/S__934015.jpg'



const PromotionModal = ({OpenModal, setOpenModal}) =>{
    
    return (
        <div>
            {OpenModal && 
                <Overlay>
                    <ContenedorModal>
                        <SectionModal>
                            <Link to='/products/pos/buy/1'>
                                <u><h3>คลิ๊กเพื่อรับสิทธิโปรโมชั่น</h3></u>
                            </Link>
                        </SectionModal>
                        <ButtonExit onClick={() => setOpenModal(false)}>
                            <i class="fa-solid fa-circle-xmark"></i>
                        </ButtonExit>
                        <Link to='/products/pos/buy/1'>
                            <img style={{
                                width: '100%', 
                                maxHeightheight: '80%'
                            }} src={img_pos} alt='pos-promotion'/>
                        </Link>
                        
                        <BottomModal onClick={() => setOpenModal(false)}>
                            <h3>Close</h3>
                        </BottomModal>
                        
                    </ContenedorModal>
                </Overlay>
            }
        </div>
    )
}

export default PromotionModal;

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.699);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
`;

const ContenedorModal = styled.div`
    width: 56.25rem;
    max-height: 50rem;
    background: #fff;
    position: relative;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 1.25rem;
    img{
        width: 100%;
        max-height: 80%;
    }
`;

const SectionModal = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.625rem;
    padding-bottom: 0.625rem;
    border-bottom: 1px solid #E8E8E8;
    h3 {
        font-weight: 500;
        font-size: 2rem;
        color: #1766DC;
    }
    u{
        color: #1766DC;
    }

`;
const BottomModal = styled.div`
    position: relative;
    cursor: pointer;
    border-top: 1px solid #E8E8E8;

    top: .5vw;
    text-align: center;
    justify-content: center;

    h3 {
        font-weight: 500;
        font-size: 2rem;
        color: #1766DC;
    }
    h3:hover {
        font-weight: 500;
        font-size: 2rem;
        color: var(--text-color);

    }
`;


const ButtonExit = styled.div`
    position: absolute;
    top: 1%;
    right: calc(2% + 0.3vw);
    text-align: center;
    justify-content: center;


    width: 3.125rem;
    height: 3.125rem;
    border: none;
    background: none;
    cursor: pointer;
    transition: .3s ease all;
    border-radius: 50%;
    color: #1766DC;


    i {
        font-size: 1.875rem;
        margin-top: 20%;
    }
    i:hover{
        color: #1766DC;
    }
`;



