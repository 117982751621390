import React, {useState, useEffect} from 'react'
import './package_report.css'

import Moment from 'moment';
import ExportExcel from '../../../components/ExportExcel/exportExcel';
import ExportCSV from '../../../components/ExportExcel/ExportCSV';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper } from '@material-ui/core';
import SearchBar from "material-ui-search-bar";
// import Button from '@mui/material/Button';
import Button  from '../../../components/Button/Button';

import { NavLink } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';

import {urlApi} from "../../../url_api/url_api";

import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles({
  table: {
    minWidth: 650,
    height: 700
  },
});



function createData(contact_package_id, contact_package_docuno, contact_package_docudate, 
  contact_package_name_res, contact_package_branch_count, contact_package_type_res, 
  contact_package_name_cus, contact_package_phone_cus, contact_package_email_cus, 
  master_program_id, master_program_code,master_package_hd_id, 
  master_package_hd_code, master_package_type_id, master_package_type_name,
  master_package_dt_id, master_package_dt_name,master_package_dt_price,
  start_date_package, end_date_package, contact_package_status_id, 
  contact_package_status_name,contact_package_remark ) {
  return {contact_package_id, contact_package_docuno, contact_package_docudate, 
    contact_package_name_res, contact_package_branch_count, contact_package_type_res, 
    contact_package_name_cus, contact_package_phone_cus, contact_package_email_cus, 
    master_program_id,master_program_code,master_package_hd_id, 
    master_package_hd_code,master_package_type_id, master_package_type_name,
    master_package_dt_id, master_package_dt_name, master_package_dt_price,
    start_date_package, end_date_package, contact_package_status_id, 
    contact_package_status_name, contact_package_remark };
}

const columnExport = [
 { "header": "ชื่อร้าน", "selector": "contact_package_name_res" },
 { "header": "ประเทภร้าน", "selector": "contact_package_type_res" },
 { "header": "จำนวนสาขา", "selector": "contact_package_branch_count" },
 { "header": "ชื่อลูกค้า", "selector": "contact_package_name_cus" },
 { "header": "เบอร์โทรศัพท์", "selector": "contact_package_phone_cus" },
 { "header": "อีเมล", "selector": "contact_package_email_cus" },
 { "header": "โปรแกรม", "selector": "master_program_code" },
 { "header": "ชื่อแพ็คเกจ", "selector": "master_package_hd_code" },
 { "header": "ประเภทแพ็คเกจ", "selector": "master_package_type_name" },
 { "header": "ระยะเวลาแพ็คเกจ", "selector": "master_package_dt_name" },
 { "header": "ราคา", "selector": "master_package_dt_price" },
 { "header": "วันเริ่มแพ็คเกจ", "selector": "start_date_package"},
 { "header": "วันหมดแพ็คเกจ", "selector": "end_date_package" },
 { "header": "สถานะ", "selector": "contact_package_status_name" },
 { "header": "หมายเหตุ", "selector": "contact_package_remark" }
]

function Package_report() {
  const [page, setPage] = useState(0);
  const [contactData, setContactData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchVal, setSearchVal] = useState("");

  const classes = useStyles({
    root: {
      width: '100%',
      height: '90%'
    }
  });
  
  
  useEffect(() => {
    localStorage.getItem('userLogin')
    get_Contact_Package();
  }, [])

  useEffect(() => {
    if(searchVal != ""){
      OnchangeSearch(searchVal)
    }
  }, [searchVal])

  useEffect(() => {
   
  }, [contactData])

  const OnchangeSearch = (e) => {
    
    if (e) {
      
        let filterText = (e).trim()
        const filteredItems = dataSearch.filter((item) =>
            JSON.stringify(item).indexOf(filterText) !== -1)
        if (filteredItems.length == 0) {
          setContactData([])
        } else {
            // filteredItems.map((item, idx) => { item.row_num = idx + 1 })
            setContactData(filteredItems)
        }
    } else {
      setSearchVal("")
    }
}

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleRowclick = (even, packageID) => {
    if (even === 'จัดการ') {
      //MessageBox("error", "dataText")
      console.log(even);
      console.log(packageID);

      // window.location.href = '/homeadmin/sale/' + packageID;
    }
  }

  const cancelSearch = () => {
    setSearchVal("");
  };


async function get_Contact_Package() {
  var axios = require('axios');
  Moment.locale('th');
var data = JSON.stringify({
  "program_id": "0",
  "status_id": "0"
});

var config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: urlApi + '/package/get_reportpackage',
  headers: { 
    'Content-Type': 'application/json'
  },
  data : data
};

 await axios(config)
.then(function (response) {
  if(response.data.length > 0){

    response.data.map((item) => {
      if (item.start_date_package != null) {
        item.start_date_package = Moment(item.start_date_package).format('DD/MM/').toString() + (parseInt(Moment(item.start_date_package).format('YYYY'))+543).toString();
      }
      if (item.end_date_package != null) {
        item.end_date_package = Moment(item.end_date_package).format('DD/MM/').toString() + (parseInt(Moment(item.end_date_package).format('YYYY'))+543).toString();
      }
    })

    setContactData(response.data)
    setDataSearch(response.data)
  }else{
    setContactData([])
  }
})
.catch(function (error) {
  console.log(error);
});
}

  return (
    <div className='package'>
      <div className='package_header'>
        <div className='btn-export'>
          <div>
            <ExportCSV 
            column={columnExport} 
            csvData={contactData} 
            fileName="Report Sale" />
          </div>
          <div style={{display: 'flex', width: "100%", alignItems: 'center', justifyContent: 'end', marginRight: '2%'}}>
          <NavLink to="/homeadmin/sale">
            <Button 
              style={{
                width: '200px', 
                height: '50px', 
                borderRadius: '40px',
                color: '#fff',
              }} 
            variant="contained" 
            name='เพิ่ม'
            />
          </NavLink>
          </div>
        </div>
        <div className='text-search' >
            <SearchBar style={{width: '100%', borderRadius: '15px',backgroundColor: 'rgba(194, 194, 194, 0.5)'}}
              value={searchVal}
              onChange={(e) => setSearchVal(e)}
              onCancelSearch={() => cancelSearch()}
              enterButton
            />
          </div>
      </div>
      <div className='package_body'>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className='tHeader' style={{height: '3rem'}}>
                <TableRow className='tHeader_row' style={{whiteSpace: 'nowrap'}}>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="center">ชื่อร้าน</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="center">ประเทภร้าน</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="right">จำนวนสาขา</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="right">ชื่อลูกค้า</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="right">เบอร์โทรศัพท์</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="center">อีเมล</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="right">โปรแกรม</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="right">ชื่อแพ็คเกจ</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="right">ประเภทแพ็คเกจ</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="right">ระยะเวลา</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="right">ราคา</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="right">วันเริ่มแพ็คเกจ</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="right">วันหมดแพ็คเกจ</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="right">สถานะ</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="right">หมายเหตุ</TableCell>
                  <TableCell className='tHeader_row_cell' style={{ color: 'antiquewhite'}} align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='tBody' style={{height: '5%'}}>
                {contactData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((contactData) => (
                  <TableRow style={{height: '1rem', whiteSpace: 'nowrap'}} className='tBody_row' key={contactData.master_contact_customer_id} 
                  onClick={(e) => {handleRowclick(e.target.innerText, contactData.master_contact_customer_id)}}>
                    <TableCell component="th" scope="row">{contactData.contact_package_name_res}</TableCell>
                    <TableCell align="center">{contactData.contact_package_type_res}</TableCell>
                    <TableCell align="right">{contactData.contact_package_branch_count}</TableCell>
                    <TableCell align="center">{contactData.contact_package_name_cus}</TableCell>
                    <TableCell align="center">{contactData.contact_package_phone_cus}</TableCell>
                    <TableCell align="center">{contactData.contact_package_email_cus}</TableCell>
                    <TableCell align="center">{contactData.master_program_code}</TableCell>
                    <TableCell align="center">{contactData.master_package_hd_code}</TableCell>
                    <TableCell align="center">{contactData.master_package_type_name}</TableCell>
                    <TableCell align="center">{contactData.master_package_dt_name}</TableCell>
                    <TableCell align="center">{contactData.master_package_dt_price}</TableCell>
                    <TableCell align="center">{contactData.start_date_package}</TableCell>
                    <TableCell align="center">{contactData.end_date_package}</TableCell>
                    <TableCell align="center">{contactData.contact_package_status_name}</TableCell>
                    <TableCell align="center">{contactData.contact_package_remark}</TableCell>
                    {/* <TableCell align="center">{ Moment(contactData.savetime).format('d/MM/') + (parseInt(Moment(contactData.savetime).format('yyyy'))+543)}</TableCell> */}
                    <TableCell align="center" style={{color: 'antiquewhite'}}>
                      <Button name='จัดการ' style={{width: "100px", height: "45px",fontWeight: "600", color: "white", fontSize: '1.3rem', borderRadius: '45px'}} variant="contained" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      </div>
      <div className='package_bottom'>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={contactData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className='package_bottom_page'
        />
      
      </div>
    </div>
  )
}

export default Package_report