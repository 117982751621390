import React, {useEffect} from "react";
import './Symbol.css';

import logo from '../../components/Navbar/logoTechCare.png'
import product_logo from './img/1Oho-App-logo.png'
import tcs_element from './img/tcs_elements.jpg'

import ScrollToTop from '../../components/Scroll/ScrollToTop'


function Symbol() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    return (
      <div className="Symbol">
      <ScrollToTop />
        <div className="Symbol__header">
          <ul className="vision--head box"></ul>
          <ul>ความหมายของสัญลักษณ์องค์กร</ul>
        </div>
        <div className="Symbol__body">
          <ul className="Symbol__body__h1">
            <u>โลโก้บริษัท</u>
          </ul>
          <ul className="Symbol__body__logo">
            <img src={logo}/>
          </ul>
          <ul className="Symbol__body__text">
            <p>ตราสัญญาลักษณ์ “บริษัท เทคแคร์ โซลูชั่น จำกัด”</p><br/>
            <p>TECH มาจากคำว่า เทคโนโลยี อีกนัยยะที่แฝงไว้คือเราต้องใจให้พ้องเสียงกับคำว่า TAKE เพื่อให้ต่อเนื่องกับคำที่สอง คือ CARE เป็น TAKE CARE คือการดูแล ซึ่งเรามีความตั้งใจที่จะดูแล ช่วยเหลือธุรกิจของผู้ประกอบการด้วยเครื่องมือที่เราพัฒนาขึ้นมาด้วยความตั้งใจ ความหมายอีกมุมคือ การนำเอาเทคโนโลยีมาใช้สร้างนวัตกรรมใหม่ เพื่อเป็นผลิตภัณฑ์ เป็นบริการ ให้ผู้ประกอบการใช้กับธุรกิจ เห็นภาพรวมธุรกิจ ข้อมูลที่ได้จากเครื่องมือจะช่วยสนับสนุนการตัดสินใจ เป็นแนวทางในการประกอบธุรกิจใหม่ ๆ ทำให้การจัดการ และการเข้าถึงข้อมูลเป็นไปได้ง่ายขึ้น ด้วยเทคโนโลยี</p>
            <br/>
            <p>CARE มาจากการแปลตรงตัว คือการเอาใจใส่ ดูแล  เรามีความภูมิใจที่จะนำเสนอผลิตภัณฑ์ทางเทคโนโลยี่ ภายใต้ชื่อแบรนด์ “OHO”  เราใส่ใจ ให้กับลูกค้าของเรา เรารู้สึกเป็นเกียรติที่ได้ให้บริการลูกค้า และเราเชื่อว่าการบรรลุความคาดหวังสูงสุดของลูกค้าเป็นสิ่งสำคัญ</p>
            <br/>
            <p>เรามีทีมงานที่มีความมุ่งมั่น ที่คำนึงถึงประโยชน์ของลูกค้าและยึดเป็นหัวใจหลักของการตัดสินใจในทุก ๆวัน
                หลักปฎิบัติในการดำเนินธุรกิจของเราสะท้อนถึงความเชื่อที่ว่า ลูกค้า คือ เหตุผลที่เรายังคงอยู่ทุกวันนี้ และเราต้องแน่ใจว่าความมุ่งมั่นของเรานั้นจดจ่ออยู่ที่ความสำเร็จทางธุรกิจของลูกค้า</p>
            <br/>
            <p>SOLUTION มาจากการแปลตรงตัว คือการแก้ไขปัญหา  ซึ่งการทำธุรกิจที่ใช้เทคโนโลยีที่มีความสามารถชาญฉลาดเป็นตัวขับเคลื่อน จะช่วยขยายขีดความสามารถให้ดำเนินธุรกิจได้อย่างคล่องตัว พร้อมปรับเปลี่ยนรับทุกความท้าทาย สามารถสร้างกำไรในรูปใหม่ๆ และเติบโตได้อย่างยั่งยืน และในวันนี้ มีเทคโนโลยีที่มีความชาญฉลาดหลากหลายรูปแบบที่จะสามารถช่วยองค์กรพัฒนานวัตกรรมและเปลี่ยนรูปแบบการทำธุรกิจได้</p>
            <br/>
            <p>สัญลักษณ์ของ TECH CARE SOLUTION  เราตั้งใจที่จะสื่อสารว่าเราเป็น “ผู้ดูแลงานด้านเทคโนโลยี นวัตกรรมซอฟต์แวร์สำหรับผู้ประกอบการ” เราคือ “Business Solution Software”   ที่สนับสนุนธุรกิจ ผลิตภัณฑ์ของเราจึงมุ่งเน้นให้ผู้ประกอบการ เห็นข้อมูลต่างๆ ขององค์กร เช่น ข้อมูลด้านบุคลากร ข้อมูลด้านสินทรัพย์ แบบเรียลไทม์ เป็นเทคโนโลยีนวัตกรรมที่สามารถใช้ในการประเมินผลและวางแผนการดำเนินงาน และที่สำคัญต้องมีความสามารถแบ่งเบาภาระงานที่ต้องใช้แรงงานคนในการปฏิบัติการ เพื่อให้พนักงานไปโฟกัสกับงานด้านอื่นๆ ที่มีคุณค่ามากกว่า เมื่อผู้ประกอบการมีเทคโนโลยีเหล่านี้อยู่ในมือ แน่นอนว่าการใช้งานโซลูชั่นดิจิทัลจะช่วยให้เราสามารถสร้างความแตกต่างเหนือคู่แข่งทางธุรกิจได้</p>
            <br/>
            <br/>
            <ul className="Symbol__body__h1">
              <u>โลโก้ผลิตภัณฑ์</u>
            </ul>
            <ul className="Symbol__body__logo">
              <img src={product_logo}/>
              <p className="">ตราสัญญาลักษณ์ผลิตภัณฑ์ “OHO” “โอ้โฮ”</p><br/>
            </ul>
            <p>ไม่มีความหมายแยกตัวอักษร แต่เป็นคำที่เปล่งออกมาแสดงความตกใจประหลาดใจ เมื่อได้พบ ถูกใจสิ่งใดสิ่งหนึ่งสิ่งใดที่ต้องการหรือมองหามานาน เราต้องการสื่อสะท้อน ความพึงใจของผู้ที่ได้เห็น ใช้งาน ผลิตภัณฑ์ของเรา และเกิดความพึงใจ ถูกใจ จนอุทานด้วยคำว่า “โอ้โฮ”</p>
            <br/>
            <p className="text_p">อีกหนึ่งความตั้งใจที่เราต้องการสื่อสาร คือทุกส่วนประกอบ ล้วนแล้วเป็นความตั้งใจของเราที่จะส่งมอบให้กับลูกค้าของเรา เราเรียกว่า “TECH CARE ELEMENTS” เมื่อประกอบกันทั้งหมดจะเป็นที่มาของคำว่า OHO</p>
          </ul>
        </div> 
        <div className="Symbol__bottom">
          <ul className="Symbol__body__logo">
            <img src={tcs_element}/>
          </ul>
          
        </div>
      </div>
    );
  }
  export default Symbol;