import React, {useState, useEffect, useContext } from "react";
import Navbar from "../components/Navbar/Navbar";
import NavbarAdmin from "../components/Navbar/navbarAdmin"
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import DataContext from "../../src/dataContext/DataContext";
import BarAdmin from '../components/Navbar/barAdmin'


// import './main.css';


function Main() {
  const [loginStatus , setLoginStatus] = useState(false);
  
  useEffect(() => {
    window.scrollTo(0, 0)
    console.log('1userLogin : ' + localStorage.getItem('userLogin'));
    if(localStorage.getItem('userLogin') === null && loginStatus == false)
    {
      localStorage.setItem('userLogin', false);
      console.log('2userLogin : ' + loginStatus);
    }else if(localStorage.getItem('userLogin') == true || localStorage.getItem('userLogin') == 'true'){
      setLoginStatus(true)
      console.log('2userLogin : ' + loginStatus);
    }
  }, [])


  return (
    <DataContext.Provider value={loginStatus}>
    <div className="main">
    {!loginStatus  ?
      <>
        <Navbar /> 
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Outlet />
          <Footer />
        </div>
      </> :
      <>
        <BarAdmin />
        <div style={{display: 'flex'}}>
        <NavbarAdmin />
        <Outlet />
        </div>
      </>
    }
    </div>
    </DataContext.Provider>
  );
}
  
export default Main;